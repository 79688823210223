import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { loadStreetJobOptions } from 'src/customers/ducks/streets';
import { loadVendor } from 'src/vendors/ducks';
import { ModalProps } from 'src/core/components/Modal';
import { PageLoadingOverlay } from 'src/common/components/styled';
import { StreetJobs } from 'src/customers/interfaces/Streets';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import AddStreetJobModal from './AddStreetJobModal';

export interface Props extends ModalProps {
  date?: Date | string;
  editJobData?: StreetJobs;
  isSourceRouteDetails?: boolean;
  onSavedSuccess?: (id?: number) => void;
  routeId?: number;
  routeTemplateId?: number;
  vehicleTypeId?: number;
}

const AddStreetJobModalResolver: FC<Props> = ({
  date,
  editJobData,
  isSourceRouteDetails,
  onClose,
  routeId,
  routeTemplateId,
  vehicleTypeId,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = async () => {
    const dependencies: Promise<any>[] = [loadVendor(vendorId)(dispatch), loadStreetJobOptions(vendorId)(dispatch)];
    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      successComponent={AddStreetJobModal}
      successProps={{
        date,
        editJobData,
        isSourceRouteDetails,
        onClose,
        routeId,
        routeTemplateId,
        vehicleTypeId,
      }}
      loadingComponent={PageLoadingOverlay}
      onError={onClose}
      resolve={loadDependencies}
    />
  );
};

export default AddStreetJobModalResolver;
