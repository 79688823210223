import { change, getFormValues } from 'redux-form';
import { debounce } from 'lodash-es';
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw';
import { useDispatch } from 'react-redux';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ACTIVE_ID, ASSIGNED_ID, INACTIVE_ID, UNASSIGNED_ID } from 'src/customers/constants';
import { Address } from 'src/common/interfaces/Facility';
import { Button, IconButtonIcon } from 'src/core/components/styled';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import {
  clearSelectedStreetJobs,
  deleteStreetJob,
  deleteStreetJobs,
  loadStreetJob,
  loadStreetJobs,
  selectStreetJobs,
  transferStreetJobs,
} from 'src/customers/ducks';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { DrawingInstructions } from 'src/routes/components/pages/routeTemplateBuilder/routeTemplateBuilderMap/DrawingInstructions';
import { getMapBounds } from 'src/common/components/map/util';
import { getStreetJobsGeoJSON } from '../utils/streetJobs';
import { MapGL } from 'src/common/components/map/MapGL';
import { PageFooter } from 'src/common/components/styled';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { STREET_JOBS_ADDRESS_FORM } from 'src/customers/components/forms/StreetJobsAddressForm';
import { STREET_JOBS_SEARCH_FORM } from 'src/customers/components/forms/StreetJobsSearchForm';
import { STREET_JOBS_SOURCE } from 'src/routes/components/pages/routes/routePageSections/routeMap/constants';
import { StreetJobs, StreetJobsSearchFormData } from 'src/customers/interfaces/Streets';
import { StreetJobsAddressForm } from '../../../forms';
import { StreetJobsAddressWrapper, StyledPopUpWrapper } from 'src/customers/components/styled';
import { StreetJobsTransferFormValues } from 'src/customers/components/forms/StreetJobsTransferForm';
import { TODAY_FORMATTED, SEVEN_DAYS_AFTER_TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import MapGLWrapper from 'src/common/components/map/MapGLWrapper';
import StreetJobsTransferModal from 'src/customers/components/modals/streets/StreetJobsTransferModal';
import StreetsPageJobGLPopup from './StreetsPageJobGLPopup';
import StreetsPageMapGLSource from './StreetsPageMapGLSource';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import translate from 'src/core/services/translate';

interface Props {
  openEditJobModal: (jobs: StreetJobs) => void;
  streetJobs: StreetJobs[];
  vendorId: number;
}

const StreetsPageMapGL: React.FC<Props> = ({ openEditJobModal, streetJobs, vendorId }) => {
  const editorRef = useRef<Editor>(null);
  const dispatch = useDispatch();

  const [map, setMap] = useState<mapboxgl.Map | undefined>();
  const [editPolygon, setEditPolygon] = useState(true);
  const [drawMode, setDrawMode] = useState<DrawPolygonMode | EditingMode>();
  const [mapDrawingEnabled, setMapDrawingEnabled] = useState(false);
  const [polygon, setPolygon] = useState<any>();
  const [bounds, setMapBounds] = useState<any>();
  const [isStreetJobsTransferModalOpen, setIsStreetJobsTransferModalOpen] = useState<any>();

  const { isLoadingStreetJob, isLoadingStreetJobs, selectedStreetJobs } = useSelector(state => state.customers.streets);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const formValues = useSelector(state => getFormValues(STREET_JOBS_SEARCH_FORM)(state)) as StreetJobsSearchFormData;
  const isAssigned = formValues?.assigned === ASSIGNED_ID;
  const isUnassigned = formValues?.assigned === UNASSIGNED_ID;
  const isActive = formValues?.active === ACTIVE_ID;
  const isInactive = formValues?.active === INACTIVE_ID;
  const streetJobsSearchFormData = {
    startDate: formValues?.dateRange?.from || TODAY_FORMATTED,
    endDate: formValues?.dateRange?.to || SEVEN_DAYS_AFTER_TODAY,
    assigned: isAssigned ? true : isUnassigned ? false : undefined,
    active: isActive ? true : isInactive ? false : undefined,
  };

  const isStreetJobAssigned = !!selectedStreetJobs?.length ? selectedStreetJobs[0].assigned : false;

  const geoJSON = useMemo(() => getStreetJobsGeoJSON(streetJobs), [streetJobs]);

  const setSelectedJobs = useCallback(
    (updatedSelectedJobs: StreetJobs[]) => {
      dispatch(selectStreetJobs(updatedSelectedJobs));
    },
    [dispatch],
  );

  const getInitialMapBounds = () => {
    const points = streetJobs.map(({ latitude, longitude }) => ({ latitude, longitude }));
    const bounds = getMapBounds(points);
    setMapBounds(bounds);
  };

  const clearMap = () => {
    exitDrawMode();
    setPolygon(undefined);
    dispatch(clearSelectedStreetJobs());
    dispatch(clearRouteMapSelectedFeature());
  };

  useEffect(() => {
    getInitialMapBounds();
  }, [streetJobs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoadingStreetJobs) {
      clearMap();
    }
  }, [isLoadingStreetJobs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => () => {
      clearMap();
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    map?.once('load', () => {
      map.on('click', event => {
        const [feature] = map
          .queryRenderedFeatures(event.point)
          .filter(feature => feature.source === STREET_JOBS_SOURCE);

        if (feature) {
          loadStreetJob(vendorId, Number(feature.id))(dispatch);
          dispatch(setRouteMapSelectedFeature(RouteMapFeature.streetJob, Number(feature.id)));
        }
      });

      map.on('mousemove', event => {
        const features = map.queryRenderedFeatures(event.point).filter(
          feature =>
            /**
             * If there is any clickable feature or a cluster on hover,
             * set the pointer cursor.
             */
            feature.properties?.clickable === true,
        );

        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
      });

      map.on('mouseleave', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }, [map, dispatch, vendorId]);

  useEffect(() => {
    if (mapDrawingEnabled) {
      if (!drawMode) {
        setDrawMode(new DrawPolygonMode());
      }
    } else {
      if (polygon && editorRef.current) {
        editorRef.current.deleteFeatures(0);
        setSelectedJobs([]);
        setPolygon(undefined);
      }

      if (!!drawMode) {
        setDrawMode(undefined);
      }
    }
  }, [mapDrawingEnabled, drawMode, polygon, setSelectedJobs]);

  const selectedJob = useMemo(
    () => selectedFeature && streetJobs.find(job => job.id === selectedFeature.id),
    [streetJobs, selectedFeature],
  );

  const selectPointsInPolygon = useCallback(
    (points: StreetJobs[], newPolygon: any) => {
      const includedPoints = points.filter(p => booleanPointInPolygon([p.longitude, p.latitude], newPolygon));

      setSelectedJobs(includedPoints);
      setPolygon(newPolygon);
    },
    [setSelectedJobs],
  );

  const selectPointsInPolygonDebounced = useMemo(() => debounce(selectPointsInPolygon, 200), [selectPointsInPolygon]);

  const checkLocationsInPolygon = (event: any) => {
    const newPolygon = event.data[0];

    setPolygon(newPolygon);

    if (event.editType === 'addFeature') {
      selectPointsInPolygon(streetJobs, newPolygon);
      setDrawMode(new EditingMode());
    } else if (event.editType === 'movePosition') {
      selectPointsInPolygonDebounced(streetJobs, newPolygon);
    }
  };

  const exitDrawMode = () => {
    setEditPolygon(false);
    setDrawMode(undefined);
    setMapDrawingEnabled(false);

    dispatch(selectStreetJobs([]));
  };

  const clearAndLoadStreetJobs = () => {
    dispatch(clearRouteMapSelectedFeature());
    exitDrawMode();

    loadStreetJobs(vendorId, streetJobsSearchFormData)(dispatch);
    dispatch(change(STREET_JOBS_ADDRESS_FORM, 'address', undefined));
  };

  const onEditStreetJob = () => {
    dispatch(clearRouteMapSelectedFeature());
    exitDrawMode();

    if (selectedJob) {
      openEditJobModal(selectedJob);
    }
  };

  const onDeleteStreetJob = async (streetJobId: number) => {
    if (!(await confirm(translate('customers.streets.alertMessages.confirmDeleteStreetJob')))) return;

    deleteStreetJob(
      vendorId,
      streetJobId,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('customers.streets.alertMessages.streetJobDeletedSuccess'));
        clearAndLoadStreetJobs();
      })
      .catch(() => {
        createErrorNotification(translate('customers.streets.alertMessages.streetJobDeletedError'));
      });
  };

  const onTransferStreetJobs = (formData: StreetJobsTransferFormValues) => {
    const onTransferSucces = () => {
      createSuccessNotification(
        translate(
          isStreetJobAssigned
            ? 'customers.streets.alertMessages.streetJobsTransferedSuccess'
            : 'customers.streets.alertMessages.streetJobsAddedSuccess',
        ),
      );
      clearAndLoadStreetJobs();
      setIsStreetJobsTransferModalOpen(false);
    };

    const onTransferFail = () => {
      createErrorNotification(
        translate(
          isStreetJobAssigned
            ? 'customers.streets.alertMessages.streetJobTransferedError'
            : 'customers.streets.alertMessages.streetJobAddedError',
        ),
      );
    };

    const getSelectedStreetJobsIds = (vehicleTypeId: number) => {
      const selectedStreetJobIds = selectedStreetJobs
        .filter(selectedStreetJob => selectedStreetJob.vehicleTypeId === vehicleTypeId)
        ?.map(selectedStreetJob => selectedStreetJob.id);

      return selectedStreetJobIds;
    };

    // transfer snow plow street jobs
    if (formData.snowPlowTargetRouteId) {
      const routeCompositedId = formData.snowPlowTargetRouteId;
      const selectedSnowPlowStreetJobIds = getSelectedStreetJobsIds(SNOW_PLOW_ID);

      transferStreetJobs(
        vendorId,
        selectedSnowPlowStreetJobIds,
        routeCompositedId,
      )(dispatch)
        .then(() => {
          onTransferSucces();
        })
        .catch(() => {
          onTransferFail();
        });
    }

    // transfer street sweeper street jobs
    if (formData.stretSweeperTargetRouteId) {
      const routeCompositedId = formData.stretSweeperTargetRouteId;
      const selectedStreetSweeperStreetJobIds = getSelectedStreetJobsIds(STREET_SWEEPER_ID);

      transferStreetJobs(
        vendorId,
        selectedStreetSweeperStreetJobIds,
        routeCompositedId,
      )(dispatch)
        .then(() => {
          !formData.snowPlowTargetRouteId && onTransferSucces();
        })
        .catch(() => {
          onTransferFail();
        });
    }
  };

  const onDeleteStreetJobs = async () => {
    if (
      !(await confirm(
        translate(
          selectedStreetJobs?.length === 1
            ? 'customers.streets.alertMessages.confirmDeleteStreetJob'
            : 'customers.streets.alertMessages.confirmDeleteStreetJobs',
        ),
      ))
    )
      return;

    const streetJobIds = selectedStreetJobs.map((selectedStreetJob: StreetJobs) => selectedStreetJob.id);

    deleteStreetJobs(
      vendorId,
      streetJobIds,
    )(dispatch)
      .then(() => {
        createSuccessNotification(translate('customers.streets.alertMessages.streetJobDeletedSuccess'));
        clearAndLoadStreetJobs();
      })
      .catch(() => {
        createErrorNotification(translate('customers.streets.alertMessages.streetJobsDeletedError'));
      });
  };

  const onAddressChange = (address: Address) => {
    if (address) {
      const bounds = getMapBounds([{ latitude: address.latitude, longitude: address.longitude }]);
      setMapBounds(bounds);
    } else {
      getInitialMapBounds();
    }
  };

  const transferStreetJobsModalTitle = `${translate(
    isStreetJobAssigned ? 'customers.streets.transferToRoute' : 'customers.streets.addToRoute',
  )}`;

  const hasSnowPlowSelectedStreetJobs = !!selectedStreetJobs?.filter(
    (selectedStreetJob: StreetJobs) => selectedStreetJob.vehicleTypeId === SNOW_PLOW_ID,
  )?.length;
  const hasStreetSweeperSelectedStreetJobs = !!selectedStreetJobs?.filter(
    (selectedStreetJob: StreetJobs) => selectedStreetJob.vehicleTypeId === STREET_SWEEPER_ID,
  )?.length;

  return (
    <>
      <StreetJobsAddressWrapper>
        <StreetJobsAddressForm onAddressChange={onAddressChange} />
      </StreetJobsAddressWrapper>

      <MapGLWrapper drawingEnabled={!!drawMode && editPolygon} isDrawing={!!drawMode && !polygon}>
        <MapGL
          bounds={bounds}
          disableDefaultSatelliteView
          enableNewSatelliteView
          disableDefaultNavigationControl
          enableNewNavigationControl
          onMapRefLoaded={setMap}
        >
          {!!drawMode && (
            <Editor
              ref={editorRef}
              clickRadius={12}
              mode={drawMode}
              onUpdate={checkLocationsInPolygon}
              features={polygon ? [polygon] : []}
            />
          )}

          {!!map && (
            <StreetsPageMapGLSource
              geoJSON={geoJSON}
              map={map}
              selectedJobIds={selectedStreetJobs?.map(({ id }) => id) || []}
            />
          )}

          <StyledPopUpWrapper isLoadingSegmentDetails={isLoadingStreetJob}>
            <StreetsPageJobGLPopup
              selectedJob={selectedJob}
              editStreetJob={onEditStreetJob}
              deleteStreetJob={onDeleteStreetJob}
            />
          </StyledPopUpWrapper>
        </MapGL>

        <ComplexMapControl vertical position="top-left">
          <TooltipIconButton
            tooltipAsString
            tooltip={translate(drawMode ? 'routeTemplateBuilder.deletePolygon' : 'routeTemplateBuilder.drawPolygon')}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            margin="no"
            color={drawMode ? 'warning' : 'secondary'}
            onClick={() => {
              setEditPolygon(true);
              setMapDrawingEnabled(!mapDrawingEnabled);
            }}
          >
            <IconButtonIcon margin="no" icon={drawMode ? 'delete' : 'highlight'} />
          </TooltipIconButton>

          {polygon && (
            <TooltipIconButton
              tooltipAsString
              tooltip={translate(
                editPolygon ? 'routeTemplateBuilder.disableEditPolygon' : 'routeTemplateBuilder.editPolygon',
              )}
              tooltipPosition="right"
              tooltipColor="grayDarker"
              margin="small no no"
              color={editPolygon ? 'primary' : 'secondary'}
              onClick={() => {
                setEditPolygon(!editPolygon);
              }}
            >
              <IconButtonIcon margin="no" icon="edit" />
            </TooltipIconButton>
          )}
        </ComplexMapControl>

        {drawMode && <DrawingInstructions />}
      </MapGLWrapper>

      {!!selectedStreetJobs?.length && (
        <PageFooter>
          <Button color="primary" margin="no xSmall no no" onClick={() => setIsStreetJobsTransferModalOpen(true)}>
            {`${transferStreetJobsModalTitle} (${selectedStreetJobs.length})`}
          </Button>

          <Button color="primary" line onClick={onDeleteStreetJobs} margin="no xSmall no Xsmall">
            {`${translate('customers.streets.deleteJobs')} (${selectedStreetJobs.length})`}
          </Button>
        </PageFooter>
      )}

      {isStreetJobsTransferModalOpen && (
        <StreetJobsTransferModal
          closeModal={() => setIsStreetJobsTransferModalOpen(false)}
          hasSnowPlowSelectedStreetJobs={hasSnowPlowSelectedStreetJobs}
          hasStreetSweeperSelectedStreetJobs={hasStreetSweeperSelectedStreetJobs}
          isStreetJobAssigned={isStreetJobAssigned}
          modalTitle={transferStreetJobsModalTitle}
          transferStreetJobs={onTransferStreetJobs}
        />
      )}
    </>
  );
};

export default StreetsPageMapGL;
