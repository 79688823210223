import { Redirect, Route, Switch } from 'react-router';

import {
  CustomerDetailsPageResolver,
  CustomersPageResolver,
  ServiceDetailsEditorPageResolver,
  ServiceHistoryPageResolver,
  ServiceNetworkPageResolver,
  ServicesPageResolver,
  StreetsPageResolver,
  SuspendedLocationsPageResolver,
  UpcomingServicesPageResolver,
} from './pages';
import { CUSTOMERS_CREATE } from '../../account/constants';
// import {  CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE } from '../../account/constants';
import { RestrictedRoute } from '../../account/components';

import AdhocServiceHistoryPageResolver from './pages/Open311/AdhocServiceHistoryPageResolver';
import CustomerCommunicationsPageResolver from './pages/communications/CustomerCommunicationsPageResolver';
import RateBuilderPageResolver from './pages/RateBuilderPageResolver';
// import RouteRecommendationsPageResolver from './pages/routeRecommendations/RouteRecommendationsPageResolver';

const Customers = () => (
  <Switch>
    <Route exact path="/customers/customers" component={CustomersPageResolver} />
    <RestrictedRoute
      permission={CUSTOMERS_CREATE}
      exact
      path="/customers/customers/create"
      component={CustomerDetailsPageResolver}
    />
    <Route exact path="/customers/rate-builder" component={RateBuilderPageResolver} />

    <Route exact path="/customers/customers/:customerId/edit" component={CustomerDetailsPageResolver} />
    <Route exact path="/customers/customers/:customerId/serviceHistory" component={ServiceHistoryPageResolver} />
    <Route
      exact
      path="/customers/customers/:customerId/serviceHistory/upcomingServices"
      component={UpcomingServicesPageResolver}
    />
    <Route exact path="/customers/adhoc-service-history" component={AdhocServiceHistoryPageResolver} />
    <Route
      exact
      path="/customers/customers/:customerId/serviceHistory/communication/:routeLocationId?"
      component={CustomerCommunicationsPageResolver}
    />
    <Route exact path="/customers/customers/:customerId/services" component={ServicesPageResolver} />
    {/* <RestrictedRoute
      permission={CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE}
      exact
      path="/customers/route-recommendations"
      component={RouteRecommendationsPageResolver}
    /> */}
    <Route exact path="/customers/suspended-locations" component={SuspendedLocationsPageResolver} />
    <Route exact path="/customers/service-network" component={ServiceNetworkPageResolver} />
    <Route exact path="/customers/streets" component={StreetsPageResolver} />
    <Route
      exact
      path="/customers/customers/:customerId/location/:locationId/service/:serviceId"
      component={ServiceDetailsEditorPageResolver}
    />

    <Redirect to="/customers/customers" />
  </Switch>
);

export default Customers;
