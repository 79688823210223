import React, { useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';

import { handleStreetJobsFeatureState } from '../utils/streetJobs';
import { STREET_JOBS_SOURCE } from 'src/routes/components/pages/routes/routePageSections/routeMap/constants';

interface Props<P extends object = {}> {
  map: mapboxgl.Map;
  geoJSON: GeoJSON.FeatureCollection<GeoJSON.Point, P>;
  selectedJobIds: number[];
}

const StreetsPageMapGLSource = React.memo<Props>(({ map, geoJSON, selectedJobIds }) => {
  useEffect(() => {
    handleStreetJobsFeatureState(map);
    map.once('styledata', () => handleStreetJobsFeatureState(map));
  }, [map, geoJSON]);

  return (
    <Source type="geojson" id={STREET_JOBS_SOURCE} data={geoJSON} tolerance={0.0001}>
      <Layer
        id="allStreetJobsPoints"
        source={STREET_JOBS_SOURCE}
        type="circle"
        filter={['!', ['in', ['get', 'id'], ['literal', selectedJobIds]]]}
        paint={{
          'circle-color': ['feature-state', 'color'],
          'circle-radius': 6,
        }}
      />

      <Layer
        id="selectedStreetJobs"
        source={STREET_JOBS_SOURCE}
        type="circle"
        filter={['in', ['get', 'id'], ['literal', selectedJobIds]]}
        paint={{
          'circle-color': '#009688',
          'circle-radius': 6,
        }}
      />
    </Source>
  );
});

export default StreetsPageMapGLSource;
