import { camelCase } from 'lodash-es';
import { change, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AddJobSaveButtonWrapper, Button, Grid, GridColumn, ModalSection } from 'src/core/components/styled';
import { createErrorNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DatePicker, Dropdown, LocationPicker, RadioGroup, Switch, TypedField } from 'src/core/components';
import { DropdownOption } from 'src/core/components/Dropdown';
import { isBeforeStartDate } from 'src/utils/services/validator';
import { isRequired } from 'src/utils/services/validator';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { StreetJobOptions } from 'src/customers/interfaces/Streets';
import { TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface PropsWithoutReduxForm {
  isEditMode?: boolean;
  isFetchingAddress: boolean;
  isSourceRouteDetails?: boolean;
  loadAvailableRoutes: (
    vendorId: number,
    vehicleTypeId: number,
    startDate: Date | string,
    endDate?: Date | string,
    intervalType?: number,
    routeId?: number,
    routeTemplateId?: number,
  ) => void;
  onLocationPickerChanged: (
    pinnedAddress?: ChangeEvent | LocationData,
    locationId?: number,
    isPinOnMapVisible?: boolean,
  ) => void;
  routeId?: number;
  routesOptions?: DropdownOption[];
  routeTemplateId?: number;
  streetJobOptions: StreetJobOptions;
}

export interface AddStreetJobFormValues {
  id?: number | null;
  pinnedAddress?: {
    latitude: number;
    longitude: number;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    country?: string | null;
    state?: string | null;
    street?: string | null;
    streetNumber?: string | null;
    zip?: string | null;
    formattedAddress?: string | null;
  };
  vehicleTypeId?: number | null;
  jobPriorityId?: number | null;
  streetJobTypeId?: number | null;
  intervalType?: string | number | null;
  routeCompositedId?: string | null;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  active: boolean;
  workOrderNumber?: string | null;
}

export const ADD_STREET_JOB_FORM = 'AddStreetJobForm';

const formSelector = formValueSelector(ADD_STREET_JOB_FORM);

type Props = PropsWithoutReduxForm & InjectedFormProps<AddStreetJobFormValues, PropsWithoutReduxForm>;

const AddStreetJobForm: React.FC<Props> = ({
  handleSubmit,
  isEditMode,
  isFetchingAddress,
  isSourceRouteDetails,
  loadAvailableRoutes,
  onLocationPickerChanged,
  routeId,
  routesOptions,
  routeTemplateId,
  streetJobOptions,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const startDate = useSelector(state => formSelector(state, 'startDate'));
  const vehicleTypeId = useSelector(state => formSelector(state, 'vehicleTypeId'));
  const intervalType = useSelector(state => formSelector(state, 'intervalType'));
  const pinnedAddress = useSelector(state => formSelector(state, 'pinnedAddress'));
  const endDate = useSelector(state => formSelector(state, 'endDate'));

  const dateRangeOptions = streetJobOptions.dateRangeOptions?.filter(
    dateRange => dateRange.jobIntervalTypeId === parseInt(intervalType),
  )[0];

  useEffect(() => {
    const fetchAvailableRoutes = async () => {
      try {
        if (vendorId && vehicleTypeId && (startDate || endDate)) {
          loadAvailableRoutes(
            vendorId,
            vehicleTypeId,
            startDate,
            endDate,
            parseInt(intervalType),
            routeId,
            routeTemplateId,
          );
        }
      } catch (error) {
        createErrorNotification(translate('customers.streets.alertMessages.errorLoadingRoutes'));
      }
    };

    fetchAvailableRoutes();
  }, [vendorId, vehicleTypeId, startDate, endDate, intervalType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEditMode) {
      onLocationPickerChanged(pinnedAddress);
    }
  }, [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onVehicleTypeChanged = () => {
    dispatch(change(ADD_STREET_JOB_FORM, 'streetJobTypeId', undefined));
    dispatch(change(ADD_STREET_JOB_FORM, 'routeCompositedId', undefined));
  };

  const vehicleTypesOptions =
    streetJobOptions?.vehicleTypes?.map(vehicleType => {
      return {
        value: vehicleType.id,
        label: translate(`dashboard.${camelCase(vehicleType.technicalName)}`),
      };
    }) || [];

  const jobPriorityTypesOptions =
    streetJobOptions?.jobPriorityTypes?.map(jobPriorityType => {
      return {
        value: jobPriorityType.id,
        label: translate(`customers.streets.jobPriorityType.${camelCase(jobPriorityType.technicalName)}`),
      };
    }) || [];

  return (
    <form onSubmit={handleSubmit}>
      <ModalSection padding="small xSmall no" overflow="visible" position="relative">
        <Grid multiLine margin="negLarge no no no">
          <GridColumn size="2/12" margin="no" verticalAlign="center">
            <TypedField
              name="active"
              component={Switch}
              props={{
                label: translate('common.active'),
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid multiLine margin="sMedium no no no">
          <GridColumn size="3/12">
            <TypedField
              name="vehicleTypeId"
              component={Dropdown}
              props={{
                options: vehicleTypesOptions,
                label: translate('common.vehicleTypes'),
                margin: 'no',
                disabled: !!isSourceRouteDetails,
              }}
              validate={streetJobOptions.requiredVehicleType ? [isRequired] : []}
              onChange={onVehicleTypeChanged}
            />
          </GridColumn>
          <GridColumn size="3/12">
            <TypedField
              name="streetJobTypeId"
              component={Dropdown}
              props={{
                options: vehicleTypeId
                  ? streetJobOptions.streetJobTypeIds?.filter(x => x.vehicleId === vehicleTypeId)
                  : [],
                label: translate('customers.streets.jobName'),
                margin: 'no',
              }}
              validate={streetJobOptions.streetJobTypeIdRequired ? [isRequired] : []}
            />
          </GridColumn>
          <GridColumn size="4/12">
            <TypedField
              name="pinnedAddress"
              props={{
                isLoading: isFetchingAddress,
                label: translate('tooltips.searchAddressPinLocationOnMap'),
                margin: 'no',
              }}
              component={LocationPicker}
              validate={[isRequired]}
              onChange={(pinnedAddress: ChangeEvent | LocationData) => onLocationPickerChanged(pinnedAddress)}
            />
          </GridColumn>

          <GridColumn size="2/12">
            <TypedField
              name="jobPriorityId"
              component={Dropdown}
              props={{
                options: jobPriorityTypesOptions,
                label: translate('common.jobPriority'),
                margin: 'no',
              }}
            />
          </GridColumn>
        </Grid>

        <Grid multiLine margin="sMedium no no no">
          <GridColumn size="3/12">
            <TypedField
              component={RadioGroup}
              name="intervalType"
              props={{
                color: 'primary',
                items: (streetJobOptions?.jobIntervalTypes ?? []).map(type => ({
                  value: type.value.toString(),
                  label: translate(`customers.streets.jobIntervalType.${camelCase(type.technicalName)}`),
                  margin: 'no no small',
                })),
              }}
            />
          </GridColumn>

          <GridColumn size="3/12">
            <TypedField
              name="startDate"
              component={DatePicker}
              validate={dateRangeOptions?.startDateRequired ? [isRequired] : []}
              props={{
                label: translate('common.startDate'),
                isClearable: true,
                disabledDays: [{ before: TODAY }],
                margin: 'no',
                disabled: !!isSourceRouteDetails,
              }}
            />
          </GridColumn>
          {dateRangeOptions?.endDateVisible && (
            <GridColumn size="3/12">
              <TypedField
                name="endDate"
                component={DatePicker}
                props={{
                  label: translate('common.endDate'),
                  isClearable: true,
                  disabledDays: [{ before: TODAY }],
                  margin: 'no',
                }}
                validate={[
                  ...(dateRangeOptions?.endDateRequired ? [isRequired] : []),
                  ...(startDate && endDate ? [isBeforeStartDate] : []),
                ]}
              />
            </GridColumn>
          )}
          <GridColumn size="3/12">
            <TypedField
              name="routeCompositedId"
              component={Dropdown}
              props={{
                options: routesOptions || [],
                label: translate('common.routes'),
                margin: 'no',
                disabled: !!isSourceRouteDetails,
                isClearable: true,
              }}
              validate={[isRequired]}
            />
          </GridColumn>
        </Grid>
        <AddJobSaveButtonWrapper>
          <Button type="submit" color="primary">
            {isEditMode ? translate('common.update') : translate('common.save')}
          </Button>
        </AddJobSaveButtonWrapper>
      </ModalSection>
    </form>
  );
};

export default reduxForm<AddStreetJobFormValues, PropsWithoutReduxForm>({
  form: ADD_STREET_JOB_FORM,
  onSubmitFail: focusFirstInvalidField,
  enableReinitialize: true,
})(AddStreetJobForm);
