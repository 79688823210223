import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, filter, find, get, map, size, some, uniq, uniqBy } from 'lodash-es';
import { formValueSelector, submit } from 'redux-form';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Button,
  Grid,
  GridColumn,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Popover,
  Text,
} from 'src/core/components/styled';
import { checkGeoFenceDuplicateName, checkRouteHasScheduledDailyRoutes } from 'src/routes/services/routeTemplate';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { deleteGeoFences } from 'src/routes/ducks/geoFences';
import { loadRouteGeoFence } from 'src/routes/services/routeGeoFence';
import {
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  loadSnowPlowSegmentList,
  resetRouteTemplate,
  saveRouteTemplate,
  setSearchTerm,
  transferRouteTemplateLocations,
} from 'src/routes/ducks';
import {
  MapDragHandle,
  PopoverWrapper,
  Table,
  UnconnectedCheckbox,
  UpdateTrackerRouteSwitchWrapper,
} from 'src/core/components';
import { multiWordAndSearch } from 'src/core/services/search';
import {
  PageActions,
  PageBackButtonAction,
  PageBackButtonIcon,
  PageContent,
  PageDetails,
  PageFooter,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { removeLastLocation } from 'src/core/ducks';
import { resetFilters } from 'src/dashboard/ducks';
import {
  resetSnowOrSweeperStreetNetwork,
  setIsDrawSelectionModeOn,
  setIsEditSegmentsModeOn,
} from 'src/customers/ducks';
import { saveRouteGeoFence } from 'src/routes/ducks/routeGeoFence';
import { setIsDrawingMode } from 'src/routes/ducks/mapControls';
import { SortOrder, getOrderedRows } from 'src/core/components/Table';
import { STREET_SEGMENTS_MAP_HEIGHT } from 'src/routes/constants/streetSegmentsMap';
import { StreetNetwork } from 'src/customers/interfaces/StreetNetwork';
import { StreetNetworkMapWrapper } from 'src/customers/components/styled';
import { SUNDAY_SHORTCODE } from 'src/core/constants/weekdays';
import { TABLE_ROW_HEIGHT_LARGE } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import AddStreetJobModalResolver from 'src/customers/components/modals/addStreetJobModal/AddStreetJobModalResolver';
import confirm from 'src/core/services/confirm';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import RouteStopsSearchForm from '../../forms/RouteStopsSearchForm';
import SnowOrSweeperRouteTemplateEditorForm, {
  SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME,
} from '../../forms/SnowOrSweeperRouteTemplateEditorForm';
import SnowOrSweeperRouteTemplateTableRow from './SnowOrSweeperRouteTemplateTableRow';
import StreetNetworkMapGL from 'src/customers/components/pages/streetNetwork/StreetNetworkMapGL';
import TransferSnowOrSweeperSegmentsModalResolver from './routeTemplatePageSections/TransferSnowOrSweeperSegmentsModalResolver';
import translate from 'src/core/services/translate';

export const filterSegmentsPredicate = (searchTerm?: string) => (routeSegment: StreetNetwork) =>
  !searchTerm ||
  multiWordAndSearch(routeSegment.name, searchTerm) ||
  multiWordAndSearch(routeSegment.streetId, searchTerm);

interface Props {
  routeTemplateId?: string;
}

const SnowOrSweeperRouteTemplateEditorPage = ({ routeTemplateId }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = history.location;
  const isSnowPlowRoute = pathname.includes('snow-plow');

  const { lastLocations } = useSelector(state => state.core);
  const segmentsList = useSelector(state => state.customers.streetNetwork.streetNetwork);
  const routeTemplate = useSelector(state => state.routes.routeTemplate.routeTemplate);
  const isLoadingTemplate = useSelector(state => state.routes.routeTemplate.isLoading);
  const vendorId = useSelector(currentVendorId);
  const searchTerm = useSelector(state => state.routes.filters.searchTerm);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { isEditSegmentsModeOn } = useSelector(state => state.customers.streetNetwork);
  const isSnowPlowFeatureEnabled = useSelector(state => checkIfSnowPlowIsEnabled(state));
  const isStreetSweeperFeatureEnabled = useSelector(state => checkIfStreetSweepingIsEnabled(state));
  const { travelPathStatusDetails } = useSelector(state => state.routes.travelPath);

  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [routeSegments, setRouteSegments] = useState<any[]>([]);
  const [selectedSegments, setSelectedSegments] = useState<number[]>([]);
  const [deletedSegmentsIds, setDeletedSegmentsIds] = useState<number[]>([]);
  const [addedSegmentsIds, setAddedSegmentsIds] = useState<number[]>([]);
  const [segmentsToTransfer, setSegmentsToTransfer] = useState<number[]>([]);
  const [hasDailyRoutes, setHasDailyRoutes] = useState(false);
  const [mapHeight, setMapHeight] = useState(STREET_SEGMENTS_MAP_HEIGHT);
  const [rowScrollTopPosition, setRowScrollTopPosition] = useState(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [sortBy, setSortBy] = useState<string>('');
  const [hasTransferredSegments, setHasTransferredSegments] = useState(false);
  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState(false);

  // reset route template on unmount
  useEffect(
    () => () => {
      dispatch(resetRouteTemplate());
      dispatch(resetFilters());
      dispatch(setIsEditSegmentsModeOn(false));
      dispatch(resetSnowOrSweeperStreetNetwork());
      dispatch(setSearchTerm(''));
    },
    [dispatch],
  );

  // on route template, edit and create, edit segments mode is on by default
  useEffect(() => {
    if (!isEditSegmentsModeOn) {
      dispatch(setIsEditSegmentsModeOn(true));
    }
  }, [dispatch, isEditSegmentsModeOn]);

  useEffect(() => {
    if (routeTemplateId) {
      checkRouteHasScheduledDailyRoutes(Number(routeTemplateId), vendorId).then(response => {
        setHasDailyRoutes(response);
      });
    }
  }, [routeTemplateId, vendorId]);

  // route segments already existing in the route template
  useEffect(() => {
    setRouteSegments(
      map(routeTemplate?.routeLocations, l => {
        const segmentData = find(segmentsList, { id: l.id }) || {};
        return {
          ...l.location.streetSegment,
          ...segmentData,
          isSelected: false,
          name: l.location.name,
          orderNo: l.orderNumber,
        };
      }),
    );
  }, [routeTemplate, segmentsList]);

  useEffect(() => {
    addedSegmentsIds.length && setAddedSegmentsIds(uniq([...addedSegmentsIds]));
    deletedSegmentsIds.length && setDeletedSegmentsIds([...deletedSegmentsIds]);
    routeSegments.length && setRouteSegments([...routeSegments]);
  }, [segmentsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const routeSegmentIds = useMemo(() => routeSegments.map(segment => segment.id), [routeSegments]);

  // selected segments on map
  const segmentsToShow = useMemo(() => {
    const segments = map(routeSegments, s => {
      return { ...s, isSelected: selectedSegments.includes(s.id) };
    });
    return segments;
  }, [routeSegments, selectedSegments]);

  // delete
  useEffect(() => {
    if (deletedSegmentsIds.length > 0 && some(routeSegments, r => deletedSegmentsIds.includes(r.id))) {
      setRouteSegments(r => r.filter(segment => !deletedSegmentsIds.includes(segment.id)));
      setSelectedSegments([]);
    }
  }, [deletedSegmentsIds, routeSegments]);

  const filteredSegments = useMemo(() => {
    return getOrderedRows(segmentsToShow.filter(filterSegmentsPredicate(searchTerm)), sortBy, sortOrder);
  }, [segmentsToShow, searchTerm, sortBy, sortOrder]);

  useEffect(() => {
    if (!selectedFeature?.noTableScroll) {
      const segmentIndex = filteredSegments.findIndex((segment: StreetNetwork) => segment.id === selectedFeature?.id);
      let rowScrollTopPosition = segmentIndex * TABLE_ROW_HEIGHT_LARGE + 1;
      if (filteredSegments.length > 8) {
        if (segmentIndex > filteredSegments.length - 7) {
          rowScrollTopPosition = (filteredSegments.length - 7) * TABLE_ROW_HEIGHT_LARGE + 1;
        }
        setRowScrollTopPosition(rowScrollTopPosition);
      }
    }
  }, [filteredSegments, selectedFeature?.id, selectedFeature?.noTableScroll]);

  const handleSelectSegment = (segmentId: number) => {
    if (selectedSegments.includes(segmentId)) {
      setSelectedSegments(selectedSegments.filter(id => id !== segmentId));
    } else {
      setSelectedSegments([...selectedSegments, segmentId]);
    }
  };

  const handleCheckAllSegments = () => {
    if (selectedSegments.length === filteredSegments.length) {
      setSelectedSegments([]);
    } else {
      setSelectedSegments(filteredSegments.map(segment => segment.id));
    }
  };

  const handleGoBack = async () => {
    const isEditCreate = get(
      lastLocations,
      `/routes/route-templates/${isSnowPlowRoute ? 'snow-plow' : 'street-sweeper'}/${routeTemplateId}/edit`,
    );
    const isRedirect = get(lastLocations, `/routes/route-templates/${routeTemplateId}/edit`);
    if (isEditCreate) {
      dispatch(removeLastLocation(isEditCreate));
    }
    if (isRedirect) {
      dispatch(removeLastLocation(isRedirect));
    }

    return isRedirect && isEditCreate && history.length > 1
      ? history.go(-2)
      : history.length > 0
      ? history.goBack()
      : dispatch(
          push(`/routes/route-templates/${isSnowPlowRoute ? 'snow-plow' : 'street-sweeper'}/${routeTemplateId}`),
        );
  };

  const handleClearSelection = () => {
    setSelectedSegments([]);
    dispatch(setIsDrawSelectionModeOn(false));
  };

  const handleRemoveSegments = () => {
    const segments = selectedSegments.filter(segmentId => routeSegmentIds.includes(segmentId));
    setDeletedSegmentsIds(uniq([...deletedSegmentsIds, ...segments]));
    createSuccessNotification(
      translate('routes.alertMessages.segmentsTemplateRemoved', { numberOfSegments: size(segments) }),
    );
    dispatch(setIsDrawingMode(false));
    dispatch(setIsDrawSelectionModeOn(false));
  };

  const handleAddSegments = async () => {
    const segments = selectedSegments.filter(segmentId => !routeSegmentIds.includes(segmentId));
    const addedSegmentsIdsToFetch = uniq([...addedSegmentsIds, ...segments]);
    if (addedSegmentsIdsToFetch.length > 0) {
      await loadSnowPlowSegmentList(
        vendorId,
        addedSegmentsIdsToFetch,
      )(dispatch).then(res => {
        setAddedSegmentsIds(uniq([...addedSegmentsIds, ...segments]));
        setDeletedSegmentsIds(deletedSegmentsIds.filter(id => !segments.includes(id)));
        setRouteSegments(uniqBy([...routeSegments, ...(res || [])], s => s.id));
        setSelectedSegments([]);
        createSuccessNotification(
          translate('routes.alertMessages.segmentsAddedToTemplate', { numberOfSegments: size(segments) }),
        );
      });

      dispatch(setIsDrawSelectionModeOn(false));
    }
  };

  const handleTransferSegments = () => {
    setIsTransferModalOpen(true);
    const segments = selectedSegments.filter(
      segmentId => routeSegmentIds.includes(segmentId) && !addedSegmentsIds.includes(segmentId),
    );
    setSegmentsToTransfer(uniq([...segmentsToTransfer, ...segments]));
  };

  const formValues = useSelector(state =>
    formValueSelector('updateTrackerRouteSwitchWrapper')(state, 'shouldRecreateRoutes'),
  );

  const transferSegments = (v: { targetTemplateId: any }) => {
    transferRouteTemplateLocations({
      routeEntityLocationsToTransfer: filter(routeTemplate?.routeLocations, l =>
        segmentsToTransfer.includes(l.location.streetSegment!.id),
      ),
      routeTemplateId: routeTemplateId,
      targetRouteTemplateId: v.targetTemplateId,
      shouldRecreateRoutes: formValues?.shouldRecreateRoutes,
    })(dispatch).then(async ({ totalStops, transferredStops }) => {
      if (transferredStops === 0) {
        createErrorNotification(translate('routes.alertMessages.segmentsAlreadyExist'));
      } else {
        setIsTransferModalOpen(false);
        dispatch(setIsDrawSelectionModeOn(false));
        setSelectedSegments([]);

        if (routeTemplateId) {
          const { id } = await loadRouteTemplateOptimized(routeTemplateId)(dispatch);
          id && loadRouteTemplateLocationsBatched('snowPlowBatchId', id)(dispatch);
        }

        setSegmentsToTransfer([]);
        setHasTransferredSegments(true);

        createSuccessNotification(
          translate('routes.alertMessages.segmentsTransferred', {
            totalSegments: totalStops,
            transferredSegments: transferredStops,
          }),
        );
      }
    });
  };

  const onSearchTermChange = debounce(searchTerm => {
    dispatch(setSearchTerm(searchTerm));
  }, 200);

  const handleCreateEditRouteTemplate = async (formValues: any) => {
    try {
      let confirmedDuplicate = true;
      const geoFence = formValues.geoFence;
      let preventSaveGeoFence = false;

      if (geoFence.geoFenceCoordinates.length) {
        const response = await checkGeoFenceDuplicateName(
          formValues.routeTemplateName || formValues.geoFence.geoFenceName || routeTemplate?.routeTemplateName || '',
          vendorId,
          routeTemplate?.id,
        );

        if (!response.isValidGeoFenceName) {
          confirmedDuplicate = await confirm(
            translate('routes.geoFences.alertMessages.geoFenceDuplicateNameTitle'),
            translate('routes.geoFences.alertMessages.geoFenceDuplicateNameSubtitle'),
            translate('common.cancel'),
            translate('common.ok'),
          );
        }

        preventSaveGeoFence = !response.isValidGeoFenceName;
      } else if (geoFence.id) {
        // delete the empty geoFence
        await deleteGeoFences(vendorId, geoFence.id.toString())(dispatch);
      }

      if (confirmedDuplicate) {
        const { id } = await saveRouteTemplate(
          {
            ...routeTemplate,
            ...formValues,
            vendorId,
            groups: map(formValues.groupIds, id => ({ id })),
            locations: map(
              filter(routeSegments, segment => addedSegmentsIds.includes(segment.id)),
              (s, i) => ({ ...s, orderNumber: i + 1, service: { id: s.serviceContractId } }),
            ),
            scheduledDay: routeTemplate?.scheduledDay || SUNDAY_SHORTCODE,
            deletedServiceContractRouteTemplateIds: routeTemplate?.routeLocations
              .filter(l => l.location.streetSegment && deletedSegmentsIds.includes(l.location.streetSegment.id))
              .map(l => l.serviceContractRouteTemplateId),
          },
          false,
          formValues.shouldRecreateRoutes,
        )(dispatch);
        if (!preventSaveGeoFence && geoFence.geoFenceCoordinates.length) {
          if (routeTemplate?.id)
            await saveRouteGeoFence(
              id,
              {
                ...geoFence,
                vendorId,
                geoFenceName:
                  formValues.routeTemplateName || formValues.geoFence.geoFenceName || routeTemplate?.routeTemplateName,
                routeName: routeTemplate?.routeName,
                startingLocationId: routeTemplate?.startingLocationId,
                endingLocationId: routeTemplate?.endingLocationId,
              },
              true,
            )(dispatch);
          else {
            const isTemplate = true;
            const newGeoFence = await loadRouteGeoFence(id, isTemplate);

            await saveRouteGeoFence(
              id,
              {
                ...newGeoFence,
                geoFenceName:
                  formValues.routeTemplateName || formValues.geoFence.geoFenceName || routeTemplate?.routeTemplateName,
                geoFenceCoordinates: geoFence.geoFenceCoordinates,
              },
              true,
            )(dispatch);
          }
        }

        createSuccessNotification(translate('routes.alertMessages.routeTemplateSaved'));
        dispatch(push(`/routes/route-templates/${isSnowPlowRoute ? 'snow-plow' : 'street-sweeper'}/${id}`));
      } else {
        return;
      }
    } catch (error: any) {
      const ROUTE_EXISTS_ON_DATE_CODE = 'RouteExistsOnDate';
      const errorResponseMessageDate =
        error.response && error.response.data.message && error.response.data.code === ROUTE_EXISTS_ON_DATE_CODE
          ? error.response.data.message.split(' ').pop()
          : undefined;

      if (error.response && error.response.data.code) {
        if (error.response.data.code === ROUTE_EXISTS_ON_DATE_CODE && errorResponseMessageDate) {
          createErrorNotification(
            translate('routes.alertMessages.routeExistsOnDate', { routeDate: errorResponseMessageDate }),
          );
        } else {
          createErrorNotification(
            `${translate(createTranslationKey(error.response.data.code, 'routes.alertMessages'))}`,
          );
        }
      } else {
        createErrorNotification(translate('routes.alertMessages.routeSaveError'));
      }
    }
  };

  const handleOnSortChanged = (sortBy: string, sortOrder: SortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  const virtualizedProps = {
    height: Math.min(filteredSegments?.length * TABLE_ROW_HEIGHT_LARGE, TABLE_ROW_HEIGHT_LARGE * 8) || 1,
    itemSize: TABLE_ROW_HEIGHT_LARGE,
  };

  const checkCell =
    !checkIfViewOnly() && !checkIfSupport()
      ? {
          name: 'selectAll',
          component: UnconnectedCheckbox,
          componentProps: {
            onChange: handleCheckAllSegments,
            checked: selectedSegments.length === filteredSegments.length,
            partial: 0 < selectedSegments.length && selectedSegments.length < filteredSegments.length,
          },
          width: '3%',
          padding: 'defaultCellVertical xSmall',
          noPaddingRight: true,
          onClick: (e: ChangeEvent<HTMLInputElement>) => e.stopPropagation(),
        }
      : {
          name: 'selectAll',
          width: '3%',
          padding: 'defaultCellVertical ',
          noPaddingRight: true,
        };

  const snowPlowRouteStopsTableCells = [
    checkCell,
    { name: 'name', label: translate('common.locationName'), width: '25%', sortable: true },
    { name: 'isActive', label: translate('routes.snowPlow.status'), width: '19%', sortable: true },
    { name: 'passes', label: translate('routes.snowPlow.passes'), width: '24%', sortable: true },
    {
      name: 'isOneWay',
      label: translate('routes.snowPlow.direction'),
      width: '19%',
      sortable: true,
    },
    { name: 'actions', label: translate('common.actions'), width: '10%', sortable: false, align: 'center' },
  ];

  const handleResizeMap = (_: any, __: any, map: HTMLElement) => {
    setMapHeight(map.offsetHeight);
  };

  const openAddJobModal = () => {
    setIsAddJobModalOpen(true);
  };

  const closeAddJobModal = () => {
    setIsAddJobModalOpen(false);
  };

  const isSaveButtonDisabled = isSnowPlowRoute
    ? !isSnowPlowFeatureEnabled
    : !isStreetSweeperFeatureEnabled || (!!travelPathStatusDetails && travelPathStatusDetails.inProgress);

  return (
    <PageContent>
      <PageHeader>
        <PageDetails withBackButton>
          <PageTitleContainer>
            <PageBackButtonAction onClick={handleGoBack} id="back-button">
              <PageBackButtonIcon />
            </PageBackButtonAction>
            <PageTitle margin="small">
              {routeTemplateId ? translate('routes.editRouteTemplate') : translate('routes.createRouteTemplate')}
            </PageTitle>
          </PageTitleContainer>
        </PageDetails>
        <PageActions align="right">
          <Button margin="xSmall" type="button" color="primary" line onClick={() => openAddJobModal()}>
            {translate('customers.streets.createStreetJob')}
          </Button>

          <PopoverWrapper
            triggerButton={
              <Button
                color="primary"
                id="route-save-button"
                onClick={() => dispatch(submit(SNOW_OR_SWEEPER_ROUTE_TEMPLATE_EDITOR_FORM_NAME))}
                disabled={isSaveButtonDisabled}
              >
                {translate('common.save')}
              </Button>
            }
            popoverContent={
              (isSnowPlowRoute ? !isSnowPlowFeatureEnabled : !isStreetSweeperFeatureEnabled) ? (
                <Popover>{translate('routes.alertMessages.featureNotEnabled')}</Popover>
              ) : travelPathStatusDetails?.inProgress ? (
                <Popover>{translate('routes.travelPath.alertMessages.cantMakeChangesRouteTemplate')}</Popover>
              ) : null
            }
            size="large"
          />
          {routeTemplateId && (
            <UpdateTrackerRouteSwitchWrapper hasScheduledDailyRoutes={!!routeTemplateId && hasDailyRoutes} />
          )}
        </PageActions>
      </PageHeader>
      <Panel margin="no no xLarge">
        <PanelSectionGroup>
          <SnowOrSweeperRouteTemplateEditorForm
            disabledFields={{
              scheduledDay: (size(segmentsList) > 0 && routeTemplate?.scheduledDay) || routeTemplateId,
              vehicleTypeId: (size(segmentsList) > 0 && routeTemplate?.vehicleTypeId) || routeTemplateId,
            }}
            isCreateMode={!routeTemplateId}
            routeTemplateSegments={routeSegments}
            onSubmit={(formValues: any) => {
              handleCreateEditRouteTemplate(formValues);
            }}
            isSnowPlowRoute={isSnowPlowRoute}
          />
          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }} onResize={handleResizeMap}>
            <StreetNetworkMapWrapper ref={wrapperRef} height={mapHeight}>
              <StreetNetworkMapGL
                setSelectedSegments={setSelectedSegments}
                selectedSegments={selectedSegments}
                routeSegments={filteredSegments}
                hasFilteredSegments={filteredSegments.length !== routeSegments.length}
                routeTemplateId={routeTemplateId ? Number(routeTemplateId) : undefined}
                isRouteTemplate
                isSnowPlowRoute={isSnowPlowRoute}
                isStreetSweeperRoute={!isSnowPlowRoute}
                isRouteTemplateEditor
                routeName={routeTemplate?.routeName}
              />
            </StreetNetworkMapWrapper>
          </Resizable>
          <PanelSection>
            <Grid>
              <GridColumn size="12/12">
                <RouteStopsSearchForm
                  onSearchTermChange={onSearchTermChange}
                  isSnowPlowRoute={isSnowPlowRoute}
                  isStreetSweeperRoute={!isSnowPlowRoute}
                  initialValues={{ routeStopsSearchTerm: searchTerm }}
                />
              </GridColumn>
            </Grid>
          </PanelSection>
          <PanelSection centered isLoading={isLoadingTemplate}>
            <Grid centered>
              {!!filteredSegments.length ? (
                <Table
                  cells={snowPlowRouteStopsTableCells}
                  rowComponent={SnowOrSweeperRouteTemplateTableRow}
                  rows={filteredSegments}
                  rowProps={{
                    totalRows: filteredSegments.length,
                    rowHeight: TABLE_ROW_HEIGHT_LARGE,
                    selectSegment: handleSelectSegment,
                    selectedSegments,
                    deleteSegment: (id: number) => {
                      createSuccessNotification(translate('routes.alertMessages.segmentTemplateRemoved'));
                      setDeletedSegmentsIds([...deletedSegmentsIds, id]);
                    },
                    isSnowPlowRoute,
                  }}
                  sort={handleOnSortChanged}
                  virtualized
                  virtualizedProps={virtualizedProps}
                  scrollMarker
                  noOverflow
                  withClickableRows
                  rowScrollTopPosition={rowScrollTopPosition}
                />
              ) : !filteredSegments.length && routeSegments.length ? (
                <Message padding="medium">{translate('routes.snowPlow.noSegmentsMatchCriteria')}</Message>
              ) : (
                <Message padding="medium">{translate('routes.noRouteStreetSegmentsAddedTemplate')}</Message>
              )}
            </Grid>
          </PanelSection>

          {!checkIfViewOnly() && !checkIfSupport() && !!selectedSegments.length && (
            <PanelSection centered padding="no">
              <PageFooter>
                <Button
                  id="clear-selection-button"
                  color="alert"
                  line
                  onClick={handleClearSelection}
                  margin="no small no no"
                >
                  {translate('common.clearSelection')}
                </Button>

                <Button
                  id="reschedule-route-button"
                  color="primary"
                  line
                  onClick={handleRemoveSegments}
                  disabled={!selectedSegments.filter(segmentId => routeSegmentIds.includes(segmentId)).length}
                  margin="no small no no"
                >
                  {`${translate('routes.removeFromRoute')} (${
                    selectedSegments.filter(segmentId => routeSegmentIds.includes(segmentId)).length
                  })`}
                </Button>

                <Button
                  id="add-to-route-button"
                  color="primary"
                  line
                  onClick={handleAddSegments}
                  disabled={!selectedSegments.filter(segmentId => !routeSegmentIds.includes(segmentId)).length}
                  margin="no small no no"
                >
                  {`${translate('routes.addToRoute')} (${
                    selectedSegments.filter(segmentId => !routeSegmentIds.includes(segmentId)).length
                  })`}
                </Button>
                <PopoverWrapper
                  triggerButton={
                    <Button
                      id="transfer-segments-button"
                      color="primary"
                      line
                      disabled={
                        !selectedSegments.filter(segmentId =>
                          addedSegmentsIds.length
                            ? !addedSegmentsIds.includes(segmentId)
                            : routeSegmentIds.includes(segmentId),
                        ).length ||
                        !routeTemplateId ||
                        !!addedSegmentsIds.length ||
                        !!deletedSegmentsIds.length ||
                        hasTransferredSegments
                      }
                      onClick={handleTransferSegments}
                      margin="no small no no"
                    >
                      {`${translate('routes.transferSegments')} (${
                        selectedSegments.filter(
                          segmentId => routeSegmentIds.includes(segmentId) && !addedSegmentsIds.includes(segmentId),
                        ).length
                      })`}
                    </Button>
                  }
                  popoverContent={
                    (!!addedSegmentsIds.length ||
                      !!deletedSegmentsIds.length ||
                      routeTemplate?.routeLocations?.length !== routeSegments.length ||
                      hasTransferredSegments) && (
                      <Popover>
                        <Text block weight="medium" margin="xxSmall no xxSmall">
                          {translate('routes.alertMessages.routeSegmentsDirtyForm')}
                        </Text>
                      </Popover>
                    )
                  }
                />
              </PageFooter>
            </PanelSection>
          )}
        </PanelSectionGroup>
      </Panel>

      {isTransferModalOpen && routeTemplate?.id && (
        <TransferSnowOrSweeperSegmentsModalResolver
          closeModal={() => setIsTransferModalOpen(false)}
          isSnowPlowRoute={isSnowPlowRoute}
          onSubmit={transferSegments}
          sourceRouteTemplateId={routeTemplate?.id}
        />
      )}

      {isAddJobModalOpen && (
        <AddStreetJobModalResolver
          date={routeTemplate?.startDate}
          isSourceRouteDetails
          onClose={closeAddJobModal}
          routeTemplateId={Number(routeTemplateId)}
          vehicleTypeId={routeTemplate?.vehicleTypeId}
        />
      )}
    </PageContent>
  );
};

export default SnowOrSweeperRouteTemplateEditorPage;
