import styled, { css } from 'styled-components';

interface ColorPickerProps {
  dropPickerIconColor?: string;
  isDisabled?: boolean;
  isRightPosition?: boolean;
  selectedColor?: string;
}

export const ColorPickerContainerWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export const ColorPickerUpperContainerWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const ColorPickerSelectButton = styled.input<ColorPickerProps>`
  text-align: center;
  padding: 6px 0px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: ${props => (props.isDisabled ? 'darkgray' : props.selectedColor)};
  border: 1px solid ${props => (props.isDisabled ? 'darkgray' : 'black')};
`;

export const ColorPickerSelectButtonWrapper = styled.div<ColorPickerProps>`
  display: flex;
  cursor: ${props => (props.isDisabled ? 'no-drop' : 'pointer')};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
  }
`;

export const OutsideContainer = styled.div<ColorPickerProps>`
  position: absolute;
  z-index: 2;
  top: 28px;
  right: 130px;

  ${props =>
    props.isRightPosition &&
    css`
      left: 90px;
      top: 45px;
    `};
`;

export const ColorPickerSliderButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: {rgba(255, 255, 255, 0.2)};
  border: 3px solid white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
`;

export const ColorPickerWrapper = styled.div`
  background-color: white;
  box-shadow: 0px 8px 10px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  width: 300px;
  position: relative;
`;

export const ColorPickerAlphaWrapper = styled.div`
  flex-basis: 10%;
  height: 150px;
  position: relative;
  border: 1px solid #b4b4c4;
  border-radius: 15px;
  div:nth-of-type(-n + 2) {
    border-radius: 15px;
  }

  div:nth-of-type(3) {
    margin: 0 !important;
    div:first-of-type {
      margin-left: 1px;
    }
  }
`;

export const ColorPickerHueWrapper = styled.div`
  flex-basis: 10%;
  height: 150px;
  position: relative;
  margin: 0 10px;
  border: 1px solid #b4b4c4;
  border-radius: 15px;
  .hue-vertical {
    border-radius: 15px;
    div:first-of-type {
      left: initial !important;
    }
  }
`;

export const ColorPickerSaturationWrapper = styled.div`
  flex-basis: 80%;
  height: 150px;
  position: relative;
  border: 1px solid #b4b4c4;
  border-radius: 5px;
  .saturation-white,
  .saturation-black,
  & > div:first-child {
    border-radius: 5px;
  }
`;
