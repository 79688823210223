import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { filter, find, get, kebabCase, startCase } from 'lodash-es';
import moment from 'moment';

import { ActionButtonTooltip, PopoverWrapper, UnconnectedCheckbox } from '../../../../core/components';
import {
  BREAKTIME,
  DRIVING_TO_DUMP_DEBRIS,
  DRIVING_TO_FROM_FACILITY,
  OFF_ROUTE,
} from 'src/common/constants/serviceConfiguration';
import { checkIfSupport, checkIfViewOnly } from '../../../../account/utils/permissions';
import { COMPLETED, SCHEDULED } from '../../../../common/constants/pickupStatuses';
import { date, decimal, duration, humanizeDate, time, dateAndTimeStamp } from '../../../../utils/services/formatter';
import { DISPATCH_BOARD_VEHICLE_TYPE_IDS } from '../../../constants/dispatchBoard';
import {
  DispatchBoardJobEditorModalResolver,
  RouteLocationImagesModalResolver,
  RouteTrackerCityAlertsModalResolver,
  RouteTrackerIssuesModalResolver,
} from '../../modals';
import { FilterSetting } from 'src/vendors/interfaces/Filters';
import {
  FuelTicketsModalResolver,
  LoadsDumpedModalResolver,
  MaterialTicketsModalResolver,
  WaterFillModalResolver,
  WeightTicketsModalResolver,
} from './routePageSections';
import { getIsResetRouteEnabled } from 'src/routes/utils/routeDetails';
import { hasPermissionBasedOnProperties } from 'src/common/utils/filters';
import { isFuelTicketFeatureEnabled } from 'src/vendors/ducks/features';
import {
  IN_PROGRESS,
  LABEL_COLORS,
  ROUTE_SEQUENCE_STATUS_ICONS,
  ROUTE_SEQUENCE_TOOLTIP_COLORS,
} from '../../../constants';
import {
  Label,
  Popover,
  Separator,
  TableActionButton,
  TableCell,
  TableRow,
  Text,
} from '../../../../core/components/styled';
import { OverrideDate } from '../..';
import { PermissionGuard } from '../../../../account/components';
import { Route, RouteSequenceStatus } from '../../../interfaces/Route';
import { RouteProgressElement, RouteProgressElementWrapper, RouteProgressWrapper } from '../../styled';
import { ROUTES_TRACKER_COPY, ROUTES_TRACKER_DELETE } from '../../../../account/constants';
import { ServiceConfiguration } from 'src/vendors/interfaces/SnowPlowSettings';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID, WASTE_AUDIT_ID } from '../../../../fleet/constants';
import { TABLE_ROW_HEIGHT_LARGE } from '../../../../core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import AddStreetJobModalResolver from 'src/customers/components/modals/addStreetJobModal/AddStreetJobModalResolver';
import routeAssistance from '../.././../../common/assets/img/insights/routeAssistance.png';
import translate from '../../../../core/services/translate';

interface Props extends Route {
  checkRoute: (routeId: number, e: ChangeEvent<HTMLInputElement>) => void;
  cloneRoute: (routeId: number, event: MouseEvent<HTMLButtonElement>) => void;
  deleteRoute: (routeId: number, event: MouseEvent<HTMLButtonElement>) => void;
  filtersPreferences?: FilterSetting[];
  handleTableRowClick: (
    routeDetailId: number,
    routeId: number,
    routeSequenceStatus: RouteSequenceStatus,
    jobId: string,
    isYRoute?: boolean,
    isEdit?: boolean,
    isSnowPlowRoute?: boolean,
    isStreetSweeperRoute?: boolean,
  ) => void;
  hasRouteSequencePermission: boolean;
  isChecked: boolean;
  isSnowOrSweeperTrackerPage?: boolean;
  jobId: string;
  loadsDumped: boolean;
  materialPickUpTicket: boolean;
  numberOfAssistedStops?: number;
  resetSnowOrSweeperRoute: (
    routeId: number,
    routeName: string,
    isSnowPlowRoute: boolean,
    event: MouseEvent<HTMLButtonElement>,
  ) => void;
  routeSequenceStatus: RouteSequenceStatus;
  routeSequencingEnabled: boolean;
  waterFills: boolean;
}

const RouteTrackerTableRow = ({
  checkRoute,
  cityAlertCount,
  cloneRoute,
  date: snowOrSweeperDate,
  deleteRoute,
  disposalTrips,
  driverName,
  filtersPreferences,
  firstLogin,
  handleTableRowClick,
  hasRouteSequencePermission,
  isChecked,
  isMissingMaterialTickets,
  isMissingWeightTickets,
  isNoRoute,
  isSnowOrSweeperTrackerPage,
  isYRoute,
  jobId,
  lastLogout,
  lastServiced,
  loadsDumped,
  materialPickUpTicket,
  materialType,
  nowServicing,
  numberOfAssistedStops,
  numberOfAssistingVehicles,
  numberOfFuelTickets,
  numberOfImages,
  numberOfIssuesReported,
  numberOfSnowPlowMaterialPickUpTickets,
  numberOfStreetSweeperDisposalTickets,
  numberOfStreetSweeperWaterFillUpTickets,
  resetSnowOrSweeperRoute,
  routeAssistanceEnabled,
  routeDate,
  routeDetailId,
  routeId,
  routeName,
  routeProgress,
  routeRunDate,
  routeSequenceStatus,
  routeSequencingEnabled,
  routeStatusName,
  routeStatusType,
  routeStatusTypeId,
  routeTemplateId,
  segmentsCount,
  segmentsProgress,
  supervisorId,
  totalBreaktime,
  totalDrivingToDumpDebrisTime,
  totalFacilityTime,
  totalFacilityTransitTime,
  totalNotServicingTime,
  totalOffRouteTime,
  totalServiceTime,
  totalStops,
  tripEndTime,
  tripStartTime,
  tripTime,
  vehicleName,
  vehicleTypeId,
  vehicleTypeName,
  vendorServiceZoneId,
  waterFills,
}: Props) => {
  const [isFuelTicketsModalOpen, setIsFuelTicketsModalOpen] = useState(false);
  const [isJobEditorModalOpen, setIsJobEditorModalOpen] = useState(false);
  const [isRouteImagesModalOpen, setIsRouteImagesModalOpen] = useState(false);
  const [isRouteCityAlertsModalOpen, setIsRouteCityAlertsModalOpen] = useState(false);
  const [isRouteIssuesModalOpen, setIsRouteIssuesModalOpen] = useState(false);
  const [isRouteLoadsDumpedModalOpen, setIsRouteLoadsDumpedModalOpen] = useState(false);
  const [isRouteMaterialTicketsModalOpen, setIsRouteMaterialTicketsModalOpen] = useState(false);
  const [isRouteWaterFillModalOpen, setIsRouteWaterFillModalOpen] = useState(false);
  const [isRouteWeightTicketModalOpen, setIsRouteWeightTicketModalOpen] = useState(false);

  const { fuelingTicketsSettings } = useSelector(state => state.vendors.fuelingTicketsSettings);
  const { snowPlowSettings } = useSelector(state => state.vendors.snowPlowSettings);
  const { streetSweepingSettings } = useSelector(state => state.vendors.streetSweepingSettings);

  const isFuelTicketEnabled = useSelector(isFuelTicketFeatureEnabled);
  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState(false);

  const displayFuelingTicketsSettings = useMemo(() => {
    return isFuelTicketEnabled && fuelingTicketsSettings && !!find(fuelingTicketsSettings, { vehicleTypeId })?.isActive;
  }, [fuelingTicketsSettings, isFuelTicketEnabled, vehicleTypeId]);

  const openAddJobModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsAddJobModalOpen(true);
  };

  const closeAddJobModal = (event: MouseEvent<HTMLButtonElement>) => {
    typeof event !== 'boolean' && event?.stopPropagation();
    setIsAddJobModalOpen(false);
  };

  const openFuelTicketsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsFuelTicketsModalOpen(true);
  };

  const closeFuelTicketsModal = () => {
    setIsFuelTicketsModalOpen(false);
  };

  const openRouteIssuesModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteIssuesModalOpen(true);
  };

  const closeRouteIssuesModal = () => {
    setIsRouteIssuesModalOpen(false);
  };

  const openRouteImagesModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteImagesModalOpen(true);
  };

  const closeRouteImagesModal = () => {
    setIsRouteImagesModalOpen(false);
  };

  const openWeightTicketsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteWeightTicketModalOpen(true);
  };

  const closeWeightTicketsModal = () => {
    setIsRouteWeightTicketModalOpen(false);
  };

  const openMaterialTicketsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteMaterialTicketsModalOpen(true);
  };

  const openLoadsDumpedModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteLoadsDumpedModalOpen(true);
  };

  const openWaterFillModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteWaterFillModalOpen(true);
  };

  const closeMaterialTicketsModal = () => {
    setIsRouteMaterialTicketsModalOpen(false);
  };

  const closeLoadsDumpedModal = () => {
    setIsRouteLoadsDumpedModalOpen(false);
  };

  const closeWaterFillModal = () => {
    setIsRouteWaterFillModalOpen(false);
  };

  const openJobEditor = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsJobEditorModalOpen(true);
  };

  const closeJobEditor = () => {
    setIsJobEditorModalOpen(false);
  };

  const openRouteCityAlertsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsRouteCityAlertsModalOpen(true);
  };

  const closeRouteCityAlertsModal = () => {
    setIsRouteCityAlertsModalOpen(false);
  };

  const vehicleTypeSupportsJobAdd = vehicleTypeId && DISPATCH_BOARD_VEHICLE_TYPE_IDS.indexOf(vehicleTypeId) !== -1;
  const vehicleTypeUnsupportedDisposals = vehicleTypeId && [WASTE_AUDIT_ID].indexOf(vehicleTypeId) !== -1;
  const acceptedPickupStatuses = [COMPLETED, SCHEDULED, IN_PROGRESS];

  const isSnowPlowRoute = vehicleTypeId === SNOW_PLOW_ID;
  const isStreetSweeperRoute = vehicleTypeId === STREET_SWEEPER_ID;
  const isSnowOrSweeperRoute = isSnowPlowRoute || isStreetSweeperRoute;

  const altFleetSettings = useMemo(() => {
    if (!isSnowOrSweeperTrackerPage) {
      return undefined;
    }
    const settings = isSnowPlowRoute
      ? snowPlowSettings.serviceConfiguration?.filter(
          (serviceConfiguration: ServiceConfiguration) => serviceConfiguration.isNonServiceActivity,
        )
      : streetSweepingSettings.serviceConfiguration?.filter(
          (serviceConfiguration: ServiceConfiguration) => serviceConfiguration.isNonServiceActivity,
        );

    return settings;
  }, [
    isSnowOrSweeperTrackerPage,
    isSnowPlowRoute,
    snowPlowSettings.serviceConfiguration,
    streetSweepingSettings.serviceConfiguration,
  ]);

  const routeProgressFormatted = isSnowOrSweeperRoute
    ? filter(segmentsProgress, progress => acceptedPickupStatuses.indexOf(progress.statusType.id) !== -1).map(
        progress => ({
          value: progress.segmentsCount,
          label: progress.label,
          technicalName: progress.statusType.technicalName,
          id: progress.statusType.id,
        }),
      )
    : routeProgress;
  const routeProgressServiced = routeProgressFormatted?.find(progress => progress.id === COMPLETED);

  const hasPermissionsBasedOnGlobalFilters = hasPermissionBasedOnProperties(filtersPreferences, {
    vehicleTypeId,
    serviceZoneId: vendorServiceZoneId,
    supervisorId,
  });

  const totalStopsDisplayed = isSnowOrSweeperRoute ? segmentsCount : totalStops;

  const hastLAlternativeFleetSettingsActive = !!altFleetSettings?.find(
    (altFleetSetting: ServiceConfiguration) => altFleetSetting.isActive,
  );

  const isBreakTimeConfigActive = !!altFleetSettings?.find(
    (altFleetSetting: ServiceConfiguration) => altFleetSetting.alternativeFleetServiceType.technicalName === BREAKTIME,
  )?.isActive;

  const isOffRouteConfigActive = !!altFleetSettings?.find(
    (altFleetSetting: ServiceConfiguration) => altFleetSetting.alternativeFleetServiceType.technicalName === OFF_ROUTE,
  )?.isActive;

  const isDrivingToAndFromFacilityConfigActive = !!altFleetSettings?.find(
    (altFleetSetting: ServiceConfiguration) =>
      altFleetSetting.alternativeFleetServiceType.technicalName === DRIVING_TO_FROM_FACILITY,
  )?.isActive;

  const isDrivingToDumpDebrisConfigActive = !!altFleetSettings?.find(
    (altFleetSetting: ServiceConfiguration) =>
      altFleetSetting.alternativeFleetServiceType.technicalName === DRIVING_TO_DUMP_DEBRIS,
  )?.isActive;

  const isResetButtonVisible = getIsResetRouteEnabled(isSnowPlowRoute, isStreetSweeperRoute, !!routeTemplateId);

  return (
    <>
      <TableRow
        id={`route-${routeId}-button`}
        height={TABLE_ROW_HEIGHT_LARGE}
        onClick={() =>
          handleTableRowClick(
            routeDetailId,
            routeId,
            routeSequenceStatus,
            jobId,
            isYRoute,
            undefined,
            isSnowPlowRoute,
            isStreetSweeperRoute,
          )
        }
      >
        <TableCell width="3%" onClick={e => e.stopPropagation()} padding="defaultCellVertical xSmall">
          {!checkIfViewOnly() && !checkIfSupport() && (
            <UnconnectedCheckbox
              block
              checked={isChecked}
              onChange={e => checkRoute(routeId, e)}
              onClick={e => e.stopPropagation()}
              id={`route-${routeId}-checkbox`}
            />
          )}
        </TableCell>

        <TableCell id={`route-${routeId}-date-cell`} width="9%" whiteSpace padding="defaultCellVertical xSmall">
          {humanizeDate(isSnowOrSweeperRoute ? snowOrSweeperDate : routeDate)}
          {routeRunDate && !isSnowOrSweeperRoute && <OverrideDate routeRunDate={date(routeRunDate)} />}
        </TableCell>

        <TableCell
          id={`route-${routeId}-vehicle-name-cell`}
          width="12%"
          align="left"
          padding="defaultCellVertical xSmall"
        >
          {isSnowOrSweeperRoute ? (
            <>
              {!!nowServicing?.length ? (
                <>
                  <Text weight="light" margin="no small no no">
                    {nowServicing[0].vehicleName}
                    {' / '}
                    {nowServicing[0].driverFirstName} {nowServicing[0].driverLastName}
                  </Text>

                  {nowServicing?.length > 1 && (
                    <PopoverWrapper
                      triggerButton={
                        <Text margin="no no no xSmall" block color="primary" singleLine>
                          <b>+{nowServicing.length - 1}</b>
                        </Text>
                      }
                      popoverContent={
                        <Popover>
                          <>
                            {nowServicing.slice(1).map((service, index) => (
                              <Text block size="small" margin={index === 0 ? 'no' : 'xSmall no no no'}>
                                {service.vehicleName}
                                {' / '}
                                {service.driverFirstName} {service.driverLastName}
                              </Text>
                            ))}
                          </>
                        </Popover>
                      }
                      size="sMedium"
                      margin="xxSmall no no no"
                    />
                  )}
                </>
              ) : (
                '-'
              )}
            </>
          ) : (
            <Text weight="light" margin="no no xxSmall" singleLine title={vehicleName}>
              {vehicleName && (
                <>
                  <strong>{vehicleName}</strong>
                  <br />
                </>
              )}
              {vehicleTypeName}
            </Text>
          )}
        </TableCell>

        {isSnowOrSweeperRoute && (
          <TableCell
            id={`route-${routeId}-last-serviced-cell`}
            width="12%"
            vertical
            align="center"
            padding="defaultCellVertical xSmall"
          >
            {!!lastServiced ? (
              <Text weight="light" margin="no no xxSmall">
                {lastServiced?.vehicleName} {' / '}
                {lastServiced?.driverFirstName} {lastServiced?.driverLastName}
              </Text>
            ) : (
              '-'
            )}
          </TableCell>
        )}

        <TableCell
          id={`route-${routeId}-route-name-cell`}
          width={isSnowOrSweeperRoute ? '14%' : '13%'}
          vertical
          align="center"
          padding="defaultCellVertical xSmall"
          singleLine
        >
          <Text block weight="light" margin="no no xxSmall" singleLine title={routeName}>
            <strong>{routeName}</strong>
          </Text>
          {materialType && !isSnowOrSweeperRoute && (
            <Text block weight="light" margin="no no xxSmall" singleLine title={materialType}>
              {materialType}
            </Text>
          )}
          {driverName && (
            <Text block weight="light" margin="no no xxSmall" singleLine title={driverName}>
              {driverName}
            </Text>
          )}
        </TableCell>
        <TableCell id={`route-${routeId}-total-stops-cell`} width="5%" whiteSpace padding="defaultCellVertical xSmall">
          {totalStopsDisplayed || 0}{' '}
          {numberOfAssistedStops != null && (
            <TableActionButton margin="no xxSmall">
              <ActionButtonTooltip
                icon="infoFill"
                tooltip="stopsAssisted"
                tooltipProps={{ numberOfAssistedStops, totalStops: totalStopsDisplayed }}
              />
            </TableActionButton>
          )}
        </TableCell>
        <TableCell id={`route-${routeId}-sequence-cell`} width="3%" padding="no">
          {routeSequencingEnabled && routeSequenceStatus && hasRouteSequencePermission && (
            <TableActionButton>
              <ActionButtonTooltip
                color={(ROUTE_SEQUENCE_TOOLTIP_COLORS as any)[routeSequenceStatus]}
                imageSrc={get(ROUTE_SEQUENCE_STATUS_ICONS[routeSequenceStatus], 'icon')}
                tooltip={`routeSequence${routeSequenceStatus}`}
              />
            </TableActionButton>
          )}
        </TableCell>
        <TableCell id={`route-${routeId}-progress-cell`} width="13.5%" padding="defaultCellVertical xSmall">
          {!!totalStopsDisplayed && (
            <PopoverWrapper
              triggerButton={
                <RouteProgressWrapper isWrap>
                  {isSnowOrSweeperRoute && (
                    <Text size="sMedium" margin="no no xxSmall no" align="center" block singleLine>
                      {routeProgressServiced
                        ? decimal((routeProgressServiced.value * 100) / totalStopsDisplayed, 0)
                        : 0}
                      {'% '}
                      {translate('common.pickupStatuses.completed')}
                    </Text>
                  )}
                  <RouteProgressElementWrapper>
                    {routeProgressFormatted.map((progress, index) => (
                      <RouteProgressElement
                        key={index}
                        width={decimal((progress.value * 100) / totalStopsDisplayed, 0)}
                        type={progress.technicalName}
                        height="18px"
                      />
                    ))}
                  </RouteProgressElementWrapper>
                </RouteProgressWrapper>
              }
              popoverContent={
                <Popover>
                  <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                    {translate('common.total')} : {totalStopsDisplayed}
                  </Text>
                  {routeProgressFormatted.map((detail, index) => (
                    <Text key={index} block size="small" weight="medium" margin="no no xxSmall" align="left">
                      {detail.label} : {detail.value} {`(${decimal((detail.value * 100) / totalStopsDisplayed, 0)}%)`}
                    </Text>
                  ))}
                </Popover>
              }
              size="small"
              width="100%"
            />
          )}
        </TableCell>
        <TableCell id={`route-${routeId}-status-cell`} width="10%" padding="defaultCellVertical xSmall">
          <PopoverWrapper
            triggerButton={
              <Label color={LABEL_COLORS[isSnowOrSweeperRoute ? routeStatusType?.id : routeStatusTypeId]}>
                {startCase(isSnowOrSweeperRoute ? routeStatusType?.name : routeStatusName)}
              </Label>
            }
            popoverContent={
              <Popover>
                {isSnowOrSweeperRoute ? (
                  <>
                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('routes.routeStatusDetails.firstLoggedIn')}
                      </Text>
                      :{' '}
                      {firstLogin ? (
                        <>
                          {dateAndTimeStamp(firstLogin.dateTime) || '-'} {' /'} {firstLogin.vehicleName} {' - '}{' '}
                          {firstLogin.driverFirstName} {firstLogin.driverLastName}
                        </>
                      ) : (
                        '-'
                      )}
                    </Text>
                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('routes.routeStatusDetails.lastLogout')}
                      </Text>{' '}
                      :{' '}
                      {lastLogout ? (
                        <>
                          {dateAndTimeStamp(lastLogout.dateTime) || '-'} {' /'} {lastLogout.vehicleName} {' - '}{' '}
                          {lastLogout.driverFirstName} {lastLogout.driverLastName}
                        </>
                      ) : (
                        '-'
                      )}
                    </Text>

                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('routes.routeStatusDetails.totalServiceTime')}
                      </Text>{' '}
                      : {duration(totalServiceTime) || '-'}
                    </Text>

                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('routes.routeStatusDetails.totalNotServicingTime')}
                      </Text>{' '}
                      : {duration(totalNotServicingTime) || '-'}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('common.startTime')}
                      </Text>{' '}
                      : {time(tripStartTime) || '-'}
                    </Text>
                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('common.endTime')}
                      </Text>{' '}
                      : {time(tripEndTime) || '-'}
                    </Text>
                    <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                      <Text color="grayDark" size="small">
                        {translate('common.duration')}
                      </Text>{' '}
                      : {duration(tripTime, 'minutes', 'hh:mm') || '-'}
                    </Text>
                  </>
                )}

                {isSnowOrSweeperRoute && !!altFleetSettings && (
                  <>
                    {hastLAlternativeFleetSettingsActive && (
                      <Separator color="gray" padding="no no xxSmall" width="70" />
                    )}
                    {isBreakTimeConfigActive && (
                      <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                        <Text color="grayDark" size="small">
                          {translate('routes.routeStatusDetails.totalBreaktime')}
                        </Text>{' '}
                        : {duration(totalBreaktime) || '-'}
                      </Text>
                    )}
                    {isDrivingToAndFromFacilityConfigActive && (
                      <>
                        <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                          <Text color="grayDark" size="small">
                            {translate('routes.routeStatusDetails.totalFacilityTransit')}
                          </Text>{' '}
                          : {duration(totalFacilityTransitTime) || '-'}
                        </Text>
                        <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                          <Text color="grayDark" size="small">
                            {translate('routes.routeStatusDetails.totalFacilityTime')}
                          </Text>{' '}
                          : {duration(totalFacilityTime) || '-'}
                        </Text>
                      </>
                    )}

                    {isOffRouteConfigActive && (
                      <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                        <Text color="grayDark" size="small">
                          {translate('routes.routeStatusDetails.totalOffRoute')}
                        </Text>{' '}
                        : {duration(totalOffRouteTime) || '-'}
                      </Text>
                    )}
                    {isStreetSweeperRoute && isDrivingToDumpDebrisConfigActive && (
                      <Text block weight="medium" size="small" margin="no no xxSmall" align="left">
                        <Text color="grayDark" size="small">
                          {translate('routes.routeStatusDetails.totalDrivingToDumpDebris')}
                        </Text>{' '}
                        : {duration(totalDrivingToDumpDebrisTime) || '-'}
                      </Text>
                    )}
                  </>
                )}
              </Popover>
            }
            size={isSnowOrSweeperRoute ? 'medium' : 'small'}
          />
          {(routeAssistanceEnabled || !!numberOfAssistingVehicles) && (
            <TableActionButton margin="no no no xxSmall">
              <ActionButtonTooltip
                imageSrc={routeAssistance}
                tooltip={
                  numberOfAssistingVehicles
                    ? routeStatusTypeId === IN_PROGRESS
                      ? 'assistingVehicles'
                      : 'assistedVehicles'
                    : undefined
                }
                tooltipProps={{ numberOfAssistingVehicles }}
              />
            </TableActionButton>
          )}
        </TableCell>
        <TableCell id={`route-${routeId}-events-cell`} width="20.5%" padding="defaultCellVertical xSmall">
          {!!numberOfFuelTickets && displayFuelingTicketsSettings && (
            <TableActionButton onClick={event => openFuelTicketsModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip icon="fuelPump" tooltip="fuelTickets" content={numberOfFuelTickets} />
            </TableActionButton>
          )}

          {!vehicleTypeUnsupportedDisposals && (!!disposalTrips || isMissingWeightTickets) && (
            <TableActionButton onClick={event => openWeightTicketsModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip
                color={isMissingWeightTickets ? 'alert' : undefined}
                icon="ticket"
                tooltip={isMissingWeightTickets ? 'ticketsMissing' : 'tickets'}
                content={disposalTrips || ''}
              />
            </TableActionButton>
          )}

          {isSnowPlowRoute && isMissingMaterialTickets && (
            <TableActionButton
              onClick={event => {
                openMaterialTicketsModal(event);
              }}
              margin="no xSmall no no"
            >
              <ActionButtonTooltip
                color="alert"
                icon="ticket"
                tooltip="missingMaterialTickets"
                content={numberOfSnowPlowMaterialPickUpTickets || ''}
              />
            </TableActionButton>
          )}

          {isSnowPlowRoute &&
            numberOfSnowPlowMaterialPickUpTickets > 0 &&
            materialPickUpTicket &&
            !isMissingMaterialTickets && (
              <TableActionButton onClick={event => openMaterialTicketsModal(event)} margin="no xSmall no no">
                <ActionButtonTooltip
                  icon="ticket"
                  tooltip="materialTickets"
                  content={numberOfSnowPlowMaterialPickUpTickets}
                />
              </TableActionButton>
            )}

          {isStreetSweeperRoute && numberOfStreetSweeperDisposalTickets > 0 && loadsDumped && (
            <TableActionButton onClick={event => openLoadsDumpedModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip
                icon="ticket"
                tooltip={'loadsDumpedTickets'}
                content={numberOfStreetSweeperDisposalTickets}
              />
            </TableActionButton>
          )}

          {isStreetSweeperRoute && numberOfStreetSweeperWaterFillUpTickets > 0 && waterFills && (
            <TableActionButton onClick={event => openWaterFillModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip
                icon="ticket"
                tooltip={'waterFillTickets'}
                content={numberOfStreetSweeperWaterFillUpTickets}
              />
            </TableActionButton>
          )}
          {!!numberOfIssuesReported && (
            <TableActionButton onClick={event => openRouteIssuesModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip icon="issue" tooltip="issues" content={numberOfIssuesReported} />
            </TableActionButton>
          )}
          {!!numberOfImages && (
            <TableActionButton onClick={event => openRouteImagesModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip icon="image" tooltip="images" content={numberOfImages} />
            </TableActionButton>
          )}
          {!!cityAlertCount && (
            <TableActionButton onClick={event => openRouteCityAlertsModal(event)} margin="no xSmall no no">
              <ActionButtonTooltip icon="alert" tooltip="cityAlerts" content={cityAlertCount} />
            </TableActionButton>
          )}
        </TableCell>

        <TableCell id={`route-${routeId}-add-job-cell`} width="9%" align="right" padding="defaultCellVertical xSmall">
          {!isSnowOrSweeperTrackerPage &&
            vehicleTypeSupportsJobAdd &&
            !checkIfViewOnly() &&
            hasPermissionsBasedOnGlobalFilters && (
              <TableActionButton onClick={openJobEditor} id={`route-${routeId}-add-job`}>
                <ActionButtonTooltip icon="add" tooltip="addJob" />
              </TableActionButton>
            )}

          {isSnowOrSweeperTrackerPage && !checkIfViewOnly() && hasPermissionsBasedOnGlobalFilters && (
            <TableActionButton onClick={openAddJobModal} id={`route-${routeId}-add-job`}>
              <ActionButtonTooltip icon="add" tooltip="addStreetJob" />
            </TableActionButton>
          )}

          {isResetButtonVisible && (
            <TableActionButton
              id={`route-${routeId}-reset-button`}
              onClick={event => resetSnowOrSweeperRoute(routeId, routeName || '', isSnowPlowRoute, event)}
            >
              <ActionButtonTooltip icon="refresh" tooltip="resetRoute" />
            </TableActionButton>
          )}

          {!isNoRoute && !isYRoute && hasPermissionsBasedOnGlobalFilters && (
            <PermissionGuard permission={ROUTES_TRACKER_COPY}>
              <TableActionButton id={`route-${routeId}-copy-button`} onClick={event => cloneRoute(routeId, event)}>
                <ActionButtonTooltip icon="duplicate" tooltip="copyRoute" />
              </TableActionButton>
            </PermissionGuard>
          )}
          {!isYRoute && hasPermissionsBasedOnGlobalFilters && (
            <PermissionGuard permission={ROUTES_TRACKER_DELETE}>
              <TableActionButton
                onClick={event => deleteRoute(routeId, event)}
                id={`route-${kebabCase(routeName)}-delete-button`}
              >
                <ActionButtonTooltip icon="delete" tooltip="deleteRoute" />
              </TableActionButton>
            </PermissionGuard>
          )}
        </TableCell>
      </TableRow>

      {!!isRouteImagesModalOpen && (
        <RouteLocationImagesModalResolver routeId={routeId} closeModal={closeRouteImagesModal} />
      )}
      {!!isRouteCityAlertsModalOpen && (
        <RouteTrackerCityAlertsModalResolver routeId={routeId} closeModal={closeRouteCityAlertsModal} />
      )}
      {!!isRouteIssuesModalOpen && (
        <RouteTrackerIssuesModalResolver
          routeId={routeId}
          closeModal={closeRouteIssuesModal}
          isSnowPlowRoute={isSnowPlowRoute}
          isStreetSweeperRoute={isStreetSweeperRoute}
        />
      )}
      {!!isRouteWeightTicketModalOpen && (
        <WeightTicketsModalResolver
          closeModal={closeWeightTicketsModal}
          modalTitle={translate('facilities.weightTicket')}
          routeId={routeId}
          vehicleTypeId={vehicleTypeId}
          isDisposalSectionHidden={isSnowOrSweeperRoute}
        />
      )}

      {!!isRouteMaterialTicketsModalOpen && (
        <MaterialTicketsModalResolver
          closeModal={closeMaterialTicketsModal}
          modalTitle={translate('routes.materialTickets')}
          routeId={routeId}
        />
      )}

      {!!isRouteLoadsDumpedModalOpen && (
        <LoadsDumpedModalResolver
          closeModal={closeLoadsDumpedModal}
          modalTitle={translate('common.loadsDumped.loadsDumped')}
          routeId={routeId}
        />
      )}

      {!!isRouteWaterFillModalOpen && (
        <WaterFillModalResolver
          closeModal={closeWaterFillModal}
          modalTitle={translate('common.waterFill.waterFill')}
          routeId={routeId}
        />
      )}

      {isJobEditorModalOpen && (
        <DispatchBoardJobEditorModalResolver
          routeId={routeId}
          vehicleTypeId={vehicleTypeId}
          date={moment(routeDate).format('MM/DD/YYYY')}
          closeModal={closeJobEditor}
        />
      )}

      {isFuelTicketsModalOpen && (
        <FuelTicketsModalResolver
          modalTitle={translate('vendors.featureCodes.fuelTickets')}
          closeModal={closeFuelTicketsModal}
          routeId={routeId}
        />
      )}

      {isAddJobModalOpen && (
        <AddStreetJobModalResolver
          date={snowOrSweeperDate}
          isSourceRouteDetails
          onClose={closeAddJobModal}
          routeId={routeId}
          vehicleTypeId={vehicleTypeId}
        />
      )}
    </>
  );
};

export default RouteTrackerTableRow;
