import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { http } from '../../core/services/http';
import { StreetJobType, StreetJobTypeOptions, StreetJobTypeList } from '../interfaces/StreetJobTypes';

// Define a type for the transformed options
interface StreetJobOption {
  label: string;
  value: number;
  technicalName: string;
}

const transformTechnicalTypeToStreetJobOptions = (technicalTypes: TechnicalType[]): StreetJobOption[] => {
  return technicalTypes.map((option: TechnicalType) => ({
    ...option,
    label: option.name,
    value: option.id,
    technicalName: option.technicalName,
  }));
};

// Load and transform the Street Job Type Options
export const loadStreetJobTypeOptions = () =>
  http.get<StreetJobTypeOptions>('/setup/street-job-type/options').then(response => {
    let data = response.data;

    // Transform each array of technical types in the response
    data = {
      ...data,
      vehicleTypes: transformTechnicalTypeToStreetJobOptions(data.vehicleTypes) as any,
      alertProximityUoms: transformTechnicalTypeToStreetJobOptions(data.alertProximityUoms) as any,
      serviceConfirmationTypes: transformTechnicalTypeToStreetJobOptions(data.serviceConfirmationTypes) as any,
      pictureOptionTypes: transformTechnicalTypeToStreetJobOptions(data.pictureOptionTypes) as any,
      pictureRequirementOptionTypes: transformTechnicalTypeToStreetJobOptions(
        data.pictureRequirementOptionTypes,
      ) as any,
      notesRequirementOptionTypes: transformTechnicalTypeToStreetJobOptions(data.notesRequirementOptionTypes) as any,
      requiredVehicleTypeIds: data.requiredVehicleTypeIds,
      requiredAlertProximityUomId: data.requiredAlertProximityUomId,
      requiredServiceConfirmationId: data.requiredServiceConfirmationId,
      requiredPictureOptionTypeId: data.requiredPictureOptionTypeId,
      requiredPictureRequirementOptionTypeId: data.requiredPictureRequirementOptionTypeId,
      requiredNotesRequirementOptionTypeId: data.requiredNotesRequirementOptionTypeId,
    };

    return data;
  });

export const loadStreetJobTypes = (vendorId: number) =>
  http.get<StreetJobTypeList[]>(`/setup/${vendorId}/street-job-types`).then(response => response.data);

export const saveStreetJobType = (vendorId: number, streetJobType: StreetJobTypeList) =>
  http
    .post(`/setup/${vendorId}/street-job-types/bulk`, streetJobType)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));

export const updateStreetJobType = (vendorId: number, streetJobType: StreetJobType) =>
  http
    .put(`/setup/${vendorId}/street-job-type`, streetJobType)
    .catch(({ response: { status, statusText, data } }) => Promise.reject({ status, statusText, ...data }));
