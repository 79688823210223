import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colorMapper, mapper, sizeMapper } from '../../../utils/styles';
import { fadeAndSlideInUp, loadingOverlay } from '../../styles';
import Icon from '../Icon';
import { BareButtonStyle } from './Button';

type ModalProps = Partial<{
  align: string;
  color: string;
  disabled: boolean;
  flex: boolean;
  flexDirection: string;
  flexGrow: boolean;
  fluid: boolean;
  fontSize: string;
  fontWeight?: string;
  hasBorderRadius: boolean;
  height: string;
  hidden: boolean;
  isLoading: boolean;
  isShadowed?: boolean;
  justifyContent: string;
  margin: string;
  maxHeight: string;
  minHeight: string;
  minWidth: string;
  modalHasDetails?: boolean;
  noOutline: boolean;
  noTransform: boolean;
  overflow: string;
  padding: string;
  position: string;
  size: string;
  textAlign: string;
  verticalSize: string;
  width?: string;
  zIndex?: number;
}>;

export const ModalTitle = styled.h2<ModalProps>`
  margin: ${props => sizeMapper(props.margin, '0 0 20px')};
  line-height: 29px;
  text-transform: ${props => (props.noTransform ? 'none' : 'capitalize')};
  text-align: ${props => mapper(props.textAlign, { left: 'left', center: 'center', right: 'right' }, 'center')};
  font-weight: ${props => props.theme.fontWeightLight};
  font-size: ${p => p.fontSize || '24px'};
`;

export const ModalSubtitle = styled.span<ModalProps>`
  margin: 0 0 20px;
  display: block;
  line-height: 16px;
  text-align: ${props => mapper(props.textAlign, { left: 'left', center: 'center', right: 'right' }, 'center')};
  font-weight: ${props =>
    mapper(
      props.fontWeight,
      {
        light: props.theme.fontWeightLight,
        normal: props.theme.fontWeightNormal,
        medium: props.theme.fontWeightMedium,
      },
      'light',
    )};
  font-size: ${props => mapper(props.size, { small: '13px', medium: '16px', large: '24px' }, 'small')};
  color: ${props => (props.color ? colorMapper : props.theme.grayDark)};

  ${props =>
    !props.modalHasDetails &&
    css`
      margin: 0;
    `};
  ${props =>
    props.margin &&
    css`
      margin: ${sizeMapper(props.margin)};
    `};
`;

export const ModalDialog = styled.div<ModalProps>`
  position: relative;
  min-width: ${p => p.minWidth || 'initial'};
  min-height: ${p => p.minHeight || 'initial'};
  width: ${props =>
    mapper(props.size, {
      xSmall: '400px',
      small: '600px',
      smallMedium: '700px',
      medium: '800px',
      mediumLarge: '900px',
      large: '1000px',
      xLarge: '1200px',
      xxLarge: '1440px',
    })};
  padding: ${props => sizeMapper(props.padding)};
  background-color: #fff;
  box-shadow: 0 4px 8px ${props => transparentize(0.9, props.theme.grayBase)};
  animation: ${fadeAndSlideInUp} 0.3s ease-out;
  max-height: ${p => p.maxHeight || '90vh'};
  overflow: ${props => props.overflow || 'auto'};

  @media (max-width: ${props => props.theme.desktopBreakpoint}) {
    width: ${props =>
      mapper(props.size, {
        xSmall: '400px',
        small: '600px',
        smallMedium: '700px',
        medium: 'calc(100% - 30px)',
        mediumLarge: 'calc(100% - 30px)',
        large: 'calc(100% - 30px)',
        xLarge: 'calc(100% - 30px)',
        xxLarge: 'calc(100% - 30px)',
      })};
  }

  ${props =>
    props.flex &&
    css`
      display: flex;
    `};

  ${props =>
    props.flexDirection &&
    css`
      display: flex;
      flex-direction: ${props.flexDirection};
    `};

  ${props =>
    props.verticalSize &&
    css`
      min-height: ${mapper(props.verticalSize, {
        xSmall: '20vh',
        small: '40vh',
        smallMedium: '55vh',
        medium: '60vh',
        mediumLarge: '65vh',
        mediumLargest: '80vh',
        large: '90vh',
      })};
    `};

  ${props =>
    props.hasBorderRadius &&
    css`
      border-radius: 9px;
    `};

  ${props =>
    props.height &&
    css`
      height: ${props.height};
      min-height: unset;
    `};

  @media (max-width: ${props => props.theme.mobileBreakpoint}) {
    width: 90%;
    max-height: 80vh;
  }
`;

export const ModalCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 8px;
  height: 8px;
`;

export const ModalClose = styled.button.attrs({
  type: 'button',
})<ModalProps>`
  ${BareButtonStyle};
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.grayLight};
  border-radius: 4px;
  color: ${props => props.theme.grayDark};
  transition: background-color 0.3s ease-out;
  margin: ${props => sizeMapper(props.margin, 'no')};

  &:active {
    background-color: ${props => props.theme.gray};
  }

  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
`;

export const ModalActionLink = styled(Link)<{ disabled?: boolean }>`
  line-height: 14px;
  font-size: 12px;
  color: ${props => props.theme.brandPrimary};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.grayDark};
    `}
`;

export const ModalActionButton = styled(ModalActionLink.withComponent('button')).attrs({ type: 'button' })`
  ${BareButtonStyle};
  color: ${props => props.theme.grayDark};
`;

export const ModalSearchIcon = styled(Icon).attrs({ icon: 'search' })`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: ${props => props.theme.gray};
`;

interface ModalBackButtonIconProps {
  forward?: boolean;
  marginTop?: string;
  selfCentered?: boolean;
}

export const ModalBackButtonIcon = styled(Icon).attrs({ icon: 'back' })<ModalBackButtonIconProps>`
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  color: ${props => props.theme.brandPrimary};

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}

  ${props =>
    props.forward &&
    css`
      transform: rotate(180deg);
    `};

  ${props =>
    props.selfCentered &&
    css`
      align-self: center;
    `};
`;

export const ModalSearchInput = styled.input`
  flex: 1;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 22px;
  font-size: 18px;

  &::placeholder {
    text-transform: capitalize;
    color: ${props => props.theme.grayDark};
    font-size: 14px;
  }
`;

export const ModalSearch = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border: solid ${props => props.theme.grayLight};
  border-width: 1px 0;
`;

export const ModalSection = styled.div<ModalProps>`
  height: ${props => props.height || 'auto'};
  min-height: ${p => p.minHeight || 'initial'};
  width: ${props => props.width || 'auto'};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props => sizeMapper(props.padding, 'no')};
  overflow: ${props => props.overflow || 'auto'};

  ${props =>
    props.noOutline &&
    css`
      *:focus {
        outline: none;
      }
    `}

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.flex &&
    css`
      display: flex;
    `}

  ${props =>
    props.flexDirection &&
    css`
      display: flex;
      flex-direction: ${props.flexDirection};
    `};

  ${props =>
    props.flexGrow &&
    css`
      flex-grow: 1;
    `};

  ${props =>
    props.fluid &&
    css`
      width: 100%;
    `};

  ${props =>
    props.position &&
    css`
      position: ${props.position};
    `};

  ${props =>
    props.isLoading &&
    css`
      ${loadingOverlay('24px')};
    `};
`;

export const ModalFixedHeader = styled.div<ModalProps>`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: ${props => sizeMapper(props.padding, 'no')};
  width: 100%;
  background-color: #fff;
`;

export const ModalHeader = styled.div<ModalProps>`
  padding: ${props => sizeMapper(props.padding, 'no')};
`;

export const ModalFixedFooter = styled.div<ModalProps>`
  position: ${props => props.position || 'sticky'};
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
  padding: ${props => sizeMapper(props.padding, 'sMedium')};
  margin: ${props => sizeMapper(props.margin, 'no')};
  width: 100%;
  background-color: #fff;
  overflow: visible;

  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `};

  ${props =>
    props.align &&
    css`
      align-items: ${props.align};
    `};

  ${props =>
    props.isShadowed &&
    css`
      background: #f9f9fd;
      box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.14);
    `};

  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `};
`;

export const ModalAbsoluteFooter = styled.div<ModalProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: ${props => sizeMapper(props.padding, 'sMedium')};
  width: 100%;
  background-color: #fff;
`;

export const ModalFooter = styled.div<ModalProps>`
  display: flex;
  justify-content: ${props => mapper(props.align, { left: 'flex-start', center: 'center', right: 'flex-end' }, 'left')};
  padding: ${props => sizeMapper(props.padding, 'sMedium')};
  margin: ${props => sizeMapper(props.margin, 'no')};
  border-top: 1px solid ${props => props.theme.grayLight};
`;

export const Modal = styled.div<ModalProps>`
  position: fixed;
  display: ${p => (p.hidden ? 'hidden' : 'flex')};
  top: 0;
  left: 0;
  z-index: 6002;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  width: 100vw;
  height: 100vh;
  background-color: ${props => transparentize(0.7, props.theme.grayBase)};

  ${props =>
    props.isLoading &&
    css`
      ${ModalDialog} {
        ${loadingOverlay('24px')};
      }
    `};
`;

export const ModalError = styled.div`
  margin: 10px;
  height: 300px;
  text-align: center;
  color: ${props => props.theme.brandAlert};
`;
