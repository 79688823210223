import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';

import { BOTTOM } from 'src/core/constants';
import { Button, ButtonLink, Panel } from 'src/core/components/styled';
import { checkIfGeoFenceIsEnabled } from 'src/vendors/ducks/features';
import { checkIfSnowPlowIsEnabled, checkIfStreetSweepingIsEnabled } from 'src/vendors/ducks/features';
import { checkIfSupport, checkIfViewOnly } from 'src/account/utils/permissions';
import { DashboardMapLoadingOverlay } from 'src/dashboard/components/pages/dashboardPageSections';
import { GEO_FENCES_CREATE_EDIT_DELETE } from 'src/account/constants';
import { GOLD_ID, PLATINUM_ID } from 'src/vendors/constants/subscriptionPackageTypes';
import { isUserManagerSelector } from 'src/vendors/ducks';
import { MapDragHandle } from 'src/core/components';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { PermissionGuard } from 'src/account/components';
import { PlanARouteExtended } from 'src/routes/components/pages/routeTemplates/routeTemplatePageSections';
import { resetSnowOrSweeperStreetNetwork } from 'src/customers/ducks';
import { STREET_SEGMENTS_MAP_HEIGHT } from 'src/routes/constants/streetSegmentsMap';
import { StreetNetworkMapWrapper } from '../styled';
import { useSelector } from 'src/core/hooks/useSelector';
import createPopover from 'src/core/services/createPopover';
import ServiceAreaModalResolver from '../modals/serviceAreaModal/ServiceAreaModalResolver';
import StreetNetworkMapGL from './streetNetwork/StreetNetworkMapGL';
import translate from 'src/core/services/translate';

const ServiceNetworkPage: FC<RouteComponentProps> = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [mapHeight, setMapHeight] = useState(STREET_SEGMENTS_MAP_HEIGHT);
  const [isServiceAreaModalOpen, setIsServiceAreaModalOpen] = useState(false);

  const isSnowPlowFeatureEnabled = useSelector(checkIfSnowPlowIsEnabled);
  const isStreetSweeperFeatureEnabled = useSelector(checkIfStreetSweepingIsEnabled);
  const isGeoFenceEnabled = useSelector(checkIfGeoFenceIsEnabled);
  const subscriptionTypeId = useSelector(
    state => state.vendors.defaultVendor?.defaultVendor?.subscriptionPackageType?.id || null,
  );
  const { isLoading: isLoadingStreetNetworkDataLayer } = useSelector(state => state.customers.streetNetworkDataLayer);
  const isManager = useSelector(state => isUserManagerSelector(state.vendors.users)) || false;

  const isSupport = checkIfSupport();
  const isViewOnly = checkIfViewOnly();
  const hasAccessToServiceArea =
    subscriptionTypeId && [PLATINUM_ID, GOLD_ID].includes(subscriptionTypeId) && !isManager;

  const planARoute = (event: ChangeEvent<HTMLInputElement>) => {
    event?.stopPropagation();
    createPopover(
      event.target,
      PlanARouteExtended,
      { isSnowPlowFeatureEnabled, isStreetSweeperFeatureEnabled },
      {
        position: BOTTOM,
        size: 'small',
      },
    );
  };

  const handleResizeMap = (_: any, __: any, map: HTMLElement) => {
    setMapHeight(map.offsetHeight);
  };

  useEffect(
    () => () => {
      dispatch(resetSnowOrSweeperStreetNetwork());
    },

    [dispatch],
  );

  return (
    <>
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('customers.streetNetwork.serviceNetwork')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions align="right" alignItems="center">
            {!isViewOnly && !isSupport && (
              <>
                {hasAccessToServiceArea && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => setIsServiceAreaModalOpen(true)}
                    id="define-service-area-button"
                    margin="no xSmall"
                  >
                    {translate('customers.streetNetwork.defineServiceArea')}
                  </Button>
                )}

                {isGeoFenceEnabled && (
                  <PermissionGuard permission={GEO_FENCES_CREATE_EDIT_DELETE}>
                    <ButtonLink
                      size="small"
                      to="/fleet/geo-fences/create"
                      color="primary"
                      id="add-geo-fence-button"
                      margin="no xSmall no no"
                    >
                      {translate('routes.geoFences.addGeoFence')}
                    </ButtonLink>
                  </PermissionGuard>
                )}

                {(isSnowPlowFeatureEnabled || isStreetSweeperFeatureEnabled) && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={(event: ChangeEvent<HTMLInputElement>) => planARoute(event)}
                    id="route-plan-button"
                  >
                    {translate('routes.planARoute')}
                  </Button>
                )}
              </>
            )}
          </PageActions>
        </PageHeader>
        <Panel>
          <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }} onResize={handleResizeMap}>
            <StreetNetworkMapWrapper ref={wrapperRef} height={mapHeight}>
              <StreetNetworkMapGL isStreetNetwork />
              <DashboardMapLoadingOverlay isLoading={isLoadingStreetNetworkDataLayer} isStreetNetwork />
            </StreetNetworkMapWrapper>
          </Resizable>
        </Panel>
      </PageContent>
      {isServiceAreaModalOpen && <ServiceAreaModalResolver onClose={() => setIsServiceAreaModalOpen(false)} />}
    </>
  );
};

export default ServiceNetworkPage;
