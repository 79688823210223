import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { STREET_JOBS_SOURCE } from 'src/routes/components/pages/routes/routePageSections/routeMap/constants';
import { StreetJobs } from 'src/customers/interfaces/Streets';
import store from 'src/store';

export const getStreetJobsGeoJSON = (jobRoutes: StreetJobs[]) =>
  getFeatureCollection<GeoJSON.Point, any>(
    jobRoutes.map(job =>
      getPointFeature(job.id, [job.longitude, job.latitude], {
        id: job.id,
        clickable: true,
      }),
    ),
  );

export const handleStreetJobsFeatureState = (map: mapboxgl.Map) => {
  if (!map.getSource(STREET_JOBS_SOURCE)) {
    return;
  }

  const state = store.getState();
  const { streetJobs, streetJobTypes } = state.customers.streets;

  streetJobs.forEach(job => {
    map.setFeatureState(
      {
        id: job.id,
        source: STREET_JOBS_SOURCE,
      },
      {
        color: `#${streetJobTypes
          .find(streetJobType => streetJobType.id === job.streetJobTypeId)
          ?.iconColor.replace('#', '')}`,
      },
    );
  });
};
