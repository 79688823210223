import { change } from 'redux-form';
import { ChangeEvent, FC, useState } from 'react';
import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { ACTIVE_ID, ASSIGNED_ID, INACTIVE_ID, STREET_JOB_ID, UNASSIGNED_ID } from 'src/customers/constants';
import { BOTTOM, TODAY_FORMATTED, SEVEN_DAYS_AFTER_TODAY } from 'src/core/constants';
import { Button, MapContainer, Panel, PanelSection } from 'src/core/components/styled';
import { createUrl } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { GoogleMapLoading } from 'src/routes/components/styled';
import { loadStreetJobs } from 'src/customers/ducks';
import { MapDragHandle } from 'src/core/components';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { STREET_JOBS_ADDRESS_FORM } from 'src/customers/components/forms/StreetJobsAddressForm';
import { StreetJobs, StreetJobsSearchFormData } from 'src/customers/interfaces/Streets';
import { StreetJobsSearchForm } from '../../forms';
import { StreetsImportModalResolver } from '../../modals/streets/StreetsImportModalResolver';
import { useSelector } from 'src/core/hooks/useSelector';
import AddStreetJobModalResolver from '../../modals/addStreetJobModal/AddStreetJobModalResolver';
import createPopover from 'src/core/services/createPopover';
import StreetsPageMapGL from './map/StreetsPageMapGL';
import translate from 'src/core/services/translate';
import UpdateSegmentAttributesExtended from './UpdateSegmentAttributesExtended';

interface Props {
  active: boolean;
  assigned: boolean;
  endDate?: Date | string;
  startDate: Date | string;
}

const StreetsPage: FC<RouteComponentProps & Props> = ({ active, assigned, endDate, startDate }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const vendorId = useSelector(currentVendorId);
  const { isLoadingStreetJobs, streetJobs } = useSelector(state => state.customers.streets);

  const [isStreetsImportModalOpen, setIsStreetsImportModalOpen] = useState(false);
  const [isSnowPlow, setIsSnowPlow] = useState(false);
  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState(false);
  const [editJobData, setEditJobData] = useState<StreetJobs | undefined>();

  const onUpdateSegmentAttributes = (isSnowPlow: boolean) => {
    setIsSnowPlow(isSnowPlow);
    setIsStreetsImportModalOpen(true);
  };

  const updateSegmentAttributes = (event: ChangeEvent<HTMLInputElement>) => {
    event?.stopPropagation();

    createPopover(
      event.target,
      UpdateSegmentAttributesExtended,
      { onUpdateSegmentAttributes },
      {
        position: BOTTOM,
        size: 'medium',
        zIndex: 300,
      },
    );
  };

  const initialValues = {
    dateRange: { from: startDate as Date | string, to: (endDate || SEVEN_DAYS_AFTER_TODAY) as Date | string },
    type: STREET_JOB_ID,
    assigned: assigned === true ? ASSIGNED_ID : assigned === false ? UNASSIGNED_ID : undefined,
    active: active === true ? ACTIVE_ID : active === false ? INACTIVE_ID : undefined,
  };

  const onStreetJobsSearch = async (formData: StreetJobsSearchFormData) => {
    const newFormData = {
      startDate: formData.dateRange?.from || TODAY_FORMATTED,
      endDate: formData.dateRange?.to || SEVEN_DAYS_AFTER_TODAY,
      assigned: formData.assigned === ASSIGNED_ID ? true : formData.assigned === UNASSIGNED_ID ? false : undefined,
      active: formData.active === ACTIVE_ID ? true : formData.active === INACTIVE_ID ? false : undefined,
    };

    dispatch(
      push(
        createUrl(location.pathname, location.search, {
          ...newFormData,
          assigned:
            (newFormData.assigned === true || newFormData.assigned === false) && newFormData.assigned.toString(),
          active: (newFormData.active === true || newFormData.active === false) && newFormData.active.toString(),
        }),
      ),
    );

    loadStreetJobs(vendorId, newFormData)(dispatch);
    dispatch(change(STREET_JOBS_ADDRESS_FORM, 'address', undefined));
  };

  const openAddJobModal = () => {
    setEditJobData(undefined);
    setIsAddJobModalOpen(true);
  };

  const openEditJobModal = (jobData: any) => {
    setEditJobData(jobData);
    setIsAddJobModalOpen(true);
  };

  const closeAddJobModal = () => {
    setEditJobData(undefined);
    setIsAddJobModalOpen(false);
  };

  const closeStreetsImportModal = () => {
    setIsStreetsImportModalOpen(false);
  };

  return (
    <>
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('customers.streets.streets')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions align="right" alignItems="center">
            <Button
              onClick={openAddJobModal}
              color="primary"
              margin="no small no no"
              id="add-segment-attributes-button"
            >
              + {translate('routes.addJob')}
            </Button>
            <Button
              onClick={(event: ChangeEvent<HTMLInputElement>) => updateSegmentAttributes(event)}
              color="primary"
              id="update-segment-attributes-button"
            >
              {translate('customers.streets.updateSegmentAttributes')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <StreetJobsSearchForm initialValues={initialValues} onSubmit={onStreetJobsSearch} />

          <PanelSection>
            <Resizable minWidth="100%" handleComponent={{ bottom: <MapDragHandle /> }}>
              <MapContainer size="large">
                {isLoadingStreetJobs && <GoogleMapLoading />}
                <StreetsPageMapGL openEditJobModal={openEditJobModal} streetJobs={streetJobs} vendorId={vendorId} />
              </MapContainer>
            </Resizable>
          </PanelSection>
        </Panel>
      </PageContent>

      {isStreetsImportModalOpen && (
        <StreetsImportModalResolver closeModal={closeStreetsImportModal} isSnowPlow={isSnowPlow} />
      )}

      {isAddJobModalOpen && <AddStreetJobModalResolver editJobData={editJobData} onClose={closeAddJobModal} />}
    </>
  );
};

export default StreetsPage;
