import { AugmentedTechnicalType, StreetJobOptions, StreetJobRequest, StreetJobTypeId } from '../interfaces/Streets';
import { downloadExcelFile } from 'src/utils/services/downloadExcelFile';
import { http } from 'src/core/services/http';
import { SNOW_PLOW_ID } from 'src/fleet/constants';
import { StreetJobsSearchFormData } from '../interfaces/Streets';
import translate from 'src/core/services/translate';

export const loadStreetsImportUploadedFilesStatus = () =>
  http.get('/streetSegmentImport/list', { params: {} }).then(response => response.data);

export const downloadSampleStreetsTemplate = (vehicleTypeId: number) =>
  downloadExcelFile(
    `/streetSegmentImport/downloadTemplate?vehicleTypeId=${vehicleTypeId}`,
    translate(
      vehicleTypeId === SNOW_PLOW_ID
        ? 'customers.streets.streetsSnowDataSampleFile'
        : 'customers.streets.streetsStreetSweeperDataSampleFile',
    ),
  );

export const uploadStreetsImportFile = (
  fileData: File,
  vehicleTypeId: number,
  updateUploadPercent: (percent: number) => void,
) => {
  const payload = new FormData();
  payload.append('file', fileData);
  payload.append('data', JSON.stringify({ vehicleTypeId }));

  return http
    .post('/streetSegmentImport/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.floor((loaded * 100) / total);
        updateUploadPercent(percentCompleted);
      },
    })
    .then(response => response.data);
};

export const loadStreetJobs = (vendorId: number, streetJobsSearchFormData: StreetJobsSearchFormData) =>
  http.post(`${vendorId}/street-job/map/search`, streetJobsSearchFormData).then(response => response.data);

export const saveStreetJob = (vendorId: number, streetJobsSearchFormData: StreetJobRequest, isEditMode: boolean) => {
  const method = isEditMode ? 'put' : 'post';
  const url = `${vendorId}/street-job`;

  return http[method](url, streetJobsSearchFormData).then(response => response.data);
};

export const loadStreetJob = (vendorId: number, id: number) =>
  http.get(`${vendorId}/street-job/${id}`, { params: {} }).then(response => response.data);

export const loadStreetJobTypes = (vendorId: number) =>
  http.get(`/setup/${vendorId}/street-job-types`, { params: {} }).then(response => response.data);

export const deleteStreetJob = (vendorId: number, streetJobId: number) =>
  http.delete(`/${vendorId}/street-job/${streetJobId}`).then(response => response.data);

export const deleteStreetJobs = (vendorId: number, streetJobIds: number[]) =>
  http
    .put(`/${vendorId}/street-job/bulk`, { streetJobIds, changeRoute: false, deleteJobs: true })
    .then(response => response.data);

export const transferStreetJobs = (vendorId: number, streetJobIds: number[], routeCompositedId: string) =>
  http
    .put(`/${vendorId}/street-job/bulk`, { streetJobIds, routeCompositedId, changeRoute: true, deleteJobs: false })
    .then(response => response.data);

export const loadAvailableRoutesForStreetJobsReassignment = (
  vendorId: number,
  vehicleTypeId: number,
  startDate: Date | string,
  endDate?: Date | string,
  intervalType?: number,
  routeId?: number,
  routeTemplateId?: number,
) =>
  http
    .post(`/street-job/routes/search`, {
      vendorId,
      vehicleTypeId,
      startDate,
      endDate,
      intervalType: Number(intervalType),
      routeId,
      routeTemplateId,
    })
    .then(response => {
      const { searchedRoutes } = response.data;

      const enhancedRoutes = searchedRoutes.map((route: any) => ({
        ...route,
        label: route.name,
        value: route.routeCompositedId,
      }));

      return {
        ...response.data,
        searchedRoutes: enhancedRoutes,
      };
    })
    .catch(error => {
      throw error;
    });

export const loadStreetJobOptions = (vendorId: number): Promise<StreetJobOptions> =>
  http.get(`/${vendorId}/street-job/options`, { params: {} }).then(response => {
    const data = response.data as StreetJobOptions;

    // Map over the fields to add `label` and `value`
    data.vehicleTypes =
      data.vehicleTypes &&
      data.vehicleTypes.map((item: AugmentedTechnicalType) => ({
        ...item,
        label: item.technicalName,
        value: item.id,
      }));

    data.jobPriorityTypes =
      data.jobPriorityTypes &&
      data.jobPriorityTypes.map((item: AugmentedTechnicalType) => ({
        ...item,
        label: item.technicalName,
        value: item.id,
      }));

    data.streetJobTypeIds =
      data.streetJobTypeIds &&
      data.streetJobTypeIds.map((item: StreetJobTypeId) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));

    data.jobIntervalTypes =
      data.jobIntervalTypes &&
      data.jobIntervalTypes.map((item: AugmentedTechnicalType) => ({
        ...item,
        label: item.technicalName,
        value: item.id,
      }));

    return data;
  });
