import { useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { ChangeEvent } from 'react';
import { formValueSelector } from 'redux-form';
import moment from 'moment';

import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalFixedFooter,
  ModalClose,
  ModalCloseIcon,
} from 'src/core/components/styled';
import { dateFormat, isBeforeStartDate, isRequired } from 'src/utils/services/validator';
import { DatePicker, Input, Switch, TypedField } from 'src/core/components';
import { FormContainer } from 'src/core/components/styled/Form';
import { LocationAndCityAlertsFormValues } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { TODAY, TODAY_FORMATTED } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import confirm from 'src/core/services/confirm';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import translate from 'src/core/services/translate';

interface PropsWithoutReduxForm {
  closeModal: (pristine?: boolean) => void;
  isCityAlert: boolean;
}

const LOCATION_AND_CITY_ALERTS_FORM = 'locationAndCityAlertsEditForm';
const formSelector = formValueSelector(LOCATION_AND_CITY_ALERTS_FORM);

type Props = PropsWithoutReduxForm & InjectedFormProps<LocationAndCityAlertsFormValues, PropsWithoutReduxForm>;

const LocationAndCityAlertsEditForm = ({ change, closeModal, handleSubmit, isCityAlert, pristine }: Props) => {
  const startDate = useSelector(state => formSelector(state, 'startDate'));
  const endDate = useSelector(state => formSelector(state, 'endDate'));
  const expirationDate = useSelector(state => formSelector(state, 'expirationDate'));

  const [fieldShouldClear, setFieldShouldClear] = useState<boolean>(false);

  const handleIsActiveChange = async (isActive: ChangeEvent | boolean) => {
    if (!isActive && endDate && moment(endDate).isSameOrAfter(moment().format(dateFormat))) {
      if (!(await confirm(translate('vendors.cityAlerts.alertMessages.confirmInactivateAlert')))) {
        change('isActive', true);
        return;
      } else {
        // hack-ish approach to clear the date field before setting correct value
        setFieldShouldClear(true);
        change('endDate', TODAY_FORMATTED);
        setFieldShouldClear(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalClose onClick={() => closeModal(pristine)}>
        <ModalCloseIcon />
      </ModalClose>

      <FormContainer height="485px">
        <Grid padding="sMedium small no small">
          <GridColumn size="4/12" margin="small no no no">
            <TypedField
              name="isActive"
              component={Switch}
              props={{
                label: translate('common.active'),
              }}
              onChange={handleIsActiveChange}
            />
          </GridColumn>

          {!isCityAlert && (
            <GridColumn size="8/12">
              <TypedField
                name="description"
                component={Input}
                props={{
                  label: translate('vendors.cityAlerts.locationDescription'),
                  disabled: true,
                }}
              />
            </GridColumn>
          )}
        </Grid>
        <Grid padding={isCityAlert ? 'sMedium small no small' : 'no small no small'} multiLine>
          {isCityAlert ? (
            <GridColumn size="12/12">
              <TypedField
                name="address"
                component={Input}
                props={{
                  label: translate('common.location'),
                  disabled: true,
                }}
              />
            </GridColumn>
          ) : (
            <>
              <Grid padding="no">
                <GridColumn size="12/12">
                  <TypedField
                    name="address"
                    component={Input}
                    props={{
                      label: translate('common.address'),
                      disabled: true,
                    }}
                  />
                </GridColumn>
              </Grid>

              <Grid padding="no">
                <GridColumn size="6/12">
                  <TypedField
                    name="customerName"
                    component={Input}
                    props={{
                      label: translate('common.customerName'),
                      disabled: true,
                    }}
                  />
                </GridColumn>
                <GridColumn size="6/12">
                  <TypedField
                    name="locationName"
                    component={Input}
                    props={{
                      label: translate('common.locationName'),
                      disabled: true,
                    }}
                  />
                </GridColumn>
              </Grid>
            </>
          )}

          <Grid padding="no">
            <GridColumn size="6/12">
              <TypedField
                name="latitude"
                component={Input}
                props={{
                  label: translate('common.latitude'),
                  disabled: true,
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="longitude"
                component={Input}
                props={{
                  label: translate('common.longitude'),
                  disabled: true,
                }}
              />
            </GridColumn>
          </Grid>
        </Grid>

        {isCityAlert && (
          <Grid padding="no small no small">
            <GridColumn size="6/12">
              <TypedField
                name="startDate"
                component={DatePicker}
                props={{
                  raisedLabel: true,
                  label: translate('common.startDate'),
                  margin: 'no',
                  isClearable: true,
                  disabled: true,
                }}
              />
            </GridColumn>
            <GridColumn size="6/12">
              <TypedField
                name="endDate"
                component={DatePicker}
                props={{
                  raisedLabel: true,
                  label: translate('common.endDate'),
                  margin: 'no',
                  isClearable: true,
                  disabledDays: [{ before: TODAY }],
                  fieldShouldClear: fieldShouldClear,
                  disabled: !!expirationDate,
                }}
                validate={startDate && endDate ? [isRequired, isBeforeStartDate] : []}
              />
              <TypedField
                name="expirationDate"
                component={Input}
                props={{
                  type: 'hidden',
                }}
              />
            </GridColumn>
          </Grid>
        )}

        {!isCityAlert && (
          <Grid padding="no small no small">
            <GridColumn size="6/12">
              <TypedField
                name="endDate"
                component={DatePicker}
                props={{
                  raisedLabel: true,
                  label: translate('common.endDate'),
                  margin: 'no',
                  isClearable: true,
                  disabledDays: [{ before: TODAY }],
                  fieldShouldClear: fieldShouldClear,
                  verticalAlign: 'top',
                }}
              />
            </GridColumn>
          </Grid>
        )}

        <ModalFixedFooter margin="medium no no" position="absolute" isShadowed>
          <ButtonSet margin="no">
            <Button type="button" color="secondary" margin="no xSmall no no" onClick={() => closeModal(pristine)}>
              {translate('common.cancel')}
            </Button>
            <Button type="submit" color="primary" margin="no no no xSmall">
              {translate('common.save')}
            </Button>
          </ButtonSet>
        </ModalFixedFooter>
      </FormContainer>
    </form>
  );
};

export default reduxForm({
  form: 'locationAndCityAlertsEditForm',
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(LocationAndCityAlertsEditForm);
