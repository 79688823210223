import React from 'react';
import { arrayPush, arraySplice, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { AppState } from 'src/store';
import {
  Button,
  Grid,
  GridColumn,
  IconButtonIcon,
  ModalFixedFooter,
  PanelSection,
  Popover,
  Text,
} from '../../../core/components/styled';
import { PopoverWrapper, Table } from '../../../core/components';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import StreetJobsFormTableRow from './components/StreetJobsFormTableRow';
import translate from '../../../core/services/translate';

interface Props extends InjectedFormProps {
  onClose: () => void;
}

export const STREET_JOBS_FORM = 'streetJobsForm';

const streetJobsTableCellWidths = {
  name: '14%',
  active: '7%',
  iconColor: '7%',
  vehicleTypeIds: '14%',
  serviceConfirmationTypeId: '12%',
  alertProximityUomId: '13%',
  pictureRequirementOptionTypeId: '15%',
  pictureOptionTypeId: '9%',
  notesRequirementOptionTypeId: '10%',
  trackServicingInterval: '8%',
};

export const getEmptyStreetJob = () => ({
  id: -1,
  name: '',
  active: true,
  iconColor: {
    r: 1,
    g: 1,
    b: 1,
    a: 1,
  },
  vehicleTypeIds: [SNOW_PLOW_ID, STREET_SWEEPER_ID],
  serviceConfirmationTypeId: undefined,
  alertProximityUomId: undefined,
  pictureRequirementOptionTypeId: undefined,
  pictureOptionTypeId: undefined,
  notesRequirementOptionTypeId: undefined,
  trackServicingInterval: false,
});

const StreetJobsForm: React.FC<Props> = ({ onClose, handleSubmit }) => {
  const dispatch = useDispatch();

  const isLoadingOptions = useSelector((state: AppState) => state.vendors.streetJobs.isLoadingOptions);
  const isLoadingList = useSelector((state: AppState) => state.vendors.streetJobs.isLoadingList);

  const streetJobsTableCell = [
    {
      name: 'name',
      label: translate('vendors.streetJobs.table.jobName'),
      width: streetJobsTableCellWidths.name,
      align: 'left',
      component: () => (
        <PopoverWrapper
          isBottomPositioned
          triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xxSmall" />}
          popoverContent={
            <Popover size="xSmall" position="right">
              <Text margin="no" block>
                {translate('vendors.streetJobs.table.jobNamePopover')}
              </Text>
            </Popover>
          }
          size="medium"
        />
      ),
    },
    {
      name: 'active',
      label: translate('vendors.streetJobs.table.active'),
      width: streetJobsTableCellWidths.active,
      align: 'center',
      noPaddingRight: true,
    },
    {
      name: 'iconColor',
      label: translate('vendors.streetJobs.table.color'),
      width: streetJobsTableCellWidths.iconColor,
      align: 'center',
      noPaddingRight: true,
    },
    {
      name: 'vehicleTypeIds',
      label: translate('vendors.streetJobs.table.vehicle'),
      width: streetJobsTableCellWidths.vehicleTypeIds,
      align: 'left',
    },
    {
      name: 'serviceConfirmationTypeId',
      label: translate('vendors.streetJobs.table.confirm'),
      width: streetJobsTableCellWidths.serviceConfirmationTypeId,
      align: 'left',
      component: () => (
        <PopoverWrapper
          isBottomPositioned
          triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xxSmall" />}
          popoverContent={
            <Popover position="right">
              <Text margin="no" block>
                {translate('vendors.streetJobs.table.cofirmPopover')}
              </Text>
            </Popover>
          }
          size="medium"
        />
      ),
    },
    {
      name: 'alertProximityUomId',
      label: translate('vendors.streetJobs.table.proximity'),
      width: streetJobsTableCellWidths.alertProximityUomId,
      align: 'left',
      component: () => (
        <PopoverWrapper
          isBottomPositioned
          triggerButton={<IconButtonIcon icon="info" color="black" size="medium" margin="no no no xxSmall" />}
          popoverContent={
            <Popover position="right">
              <Text margin="no" block>
                {translate('vendors.streetJobs.table.proximityPopup')}
              </Text>
            </Popover>
          }
          size="medium"
        />
      ),
    },
    {
      name: 'pictureRequirementOptionTypeId',
      label: translate('vendors.streetJobs.table.picture'),
      width: streetJobsTableCellWidths.pictureRequirementOptionTypeId,
      align: 'left',
    },
    {
      name: 'notesRequirementOptionTypeId',
      label: translate('vendors.streetJobs.table.notes'),
      width: streetJobsTableCellWidths.notesRequirementOptionTypeId,
      align: 'left',
    },
    {
      name: 'trackServicingInterval',
      label: translate('vendors.streetJobs.table.times'),
      width: streetJobsTableCellWidths.trackServicingInterval,
      align: 'center',
      noPaddingRight: true,
    },
  ];

  const handleNewStreetJob = () => {
    dispatch(arrayPush(STREET_JOBS_FORM, 'streetJobs', getEmptyStreetJob()));
  };

  const handleRemoveStreetJob = (index: number) =>
    dispatch(arraySplice(STREET_JOBS_FORM, 'streetJobs', index, 1, null));

  return (
    <form onSubmit={handleSubmit} noValidate>
      <PanelSection isLoading={isLoadingList || isLoadingOptions} margin="xSmall no no no" zIndex={10}>
        <Grid centered margin="no">
          <GridColumn size="12/12">
            <Table
              cells={streetJobsTableCell}
              scrollMarker
              tableHeadProps={{
                sticky: true,
              }}
              tableBodyProps={{
                height: 500,
              }}
            >
              <FieldArray
                name="streetJobs"
                component={StreetJobsFormTableRow as any}
                handleRemove={handleRemoveStreetJob}
                cellWidths={streetJobsTableCellWidths}
              />
            </Table>
          </GridColumn>
        </Grid>
      </PanelSection>
      <Grid centered margin="small no large no">
        <Button color="primary" type="button" onClick={() => handleNewStreetJob()}>
          {translate('common.addNew')}
        </Button>
      </Grid>

      <ModalFixedFooter zIndex={5} isShadowed>
        <Button type="button" color="secondary" margin="no xSmall" onClick={() => onClose()}>
          {translate('common.cancel')}
        </Button>
        <Button color="primary" type="submit">
          {translate('common.save')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<any, any>({
  form: STREET_JOBS_FORM,
  enableReinitialize: true,
  onSubmitFail: focusFirstInvalidField,
})(StreetJobsForm);
