import { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DocumentTitle } from 'src/common/components';
import { getQueryParams } from '../../../../utils/services/queryParams';
import { loadStreetJobs, loadStreetJobTypes } from 'src/customers/ducks';
import { PageLoading } from 'src/common/components/styled';
import { Resolver } from 'src/core/components';
import { TODAY_FORMATTED, SEVEN_DAYS_AFTER_TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import StreetsPage from './StreetsPage';
import translate from 'src/core/services/translate';

const StreetsPageResolver: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const vendorId = useSelector(currentVendorId);

  const params = getQueryParams(location.search);

  const startDate = (params.startDate || TODAY_FORMATTED) as Date | string;
  const endDate = (params.endDate || SEVEN_DAYS_AFTER_TODAY) as Date | string;
  const assigned = params.assigned === 'true' ? true : params.assigned === 'false' ? false : true;
  const active = params.active === 'true' ? true : params.active === 'false' ? false : true;

  const loadDependencies = () => {
    const streetJobsSearchFormData = { startDate, endDate, assigned, active };
    const dependencies = [
      loadStreetJobs(vendorId, streetJobsSearchFormData)(dispatch),
      loadStreetJobTypes(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  };

  return (
    <Fragment>
      <DocumentTitle>{translate('customers.streets.streets')}</DocumentTitle>
      <Resolver
        successComponent={StreetsPage}
        loadingComponent={PageLoading}
        resolve={loadDependencies}
        redirectOnError="/customers/customers"
        successProps={{ startDate, endDate, assigned, active, vendorId }}
      />
    </Fragment>
  );
};

export default StreetsPageResolver;
