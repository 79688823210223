import { useState } from 'react';
import { camelCase } from 'lodash-es';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Button, Grid, GridColumn, Label, ModalFixedFooter, Popover, Text } from 'src/core/components/styled';
import { clearRouteMapSelectedFeature, RouteMapFeature } from 'src/routes/ducks/mapControls';
import { dateFormat } from 'src/utils/services/validator';
import { MapGLPopup, MapGLPopupWrapper } from 'src/common/components/map/MapGLPopup';
import { MapGLPopupContent, ServiceStatusWrapper } from 'src/customers/components/styled';
import { PICKUP_STATUSES } from 'src/common/constants';
import { RouteMapFiltersTitle } from 'src/routes/components/styled/RouteMapFilters';
import { StreetJobs, StreetJobRuns, StreetJobRunsSimplified } from 'src/customers/interfaces/Streets';
import { TOP } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';

export default function StreetsPageJobGLPopup({
  deleteStreetJob,
  editStreetJob,
  selectedJob,
}: {
  deleteStreetJob: (streetJobId: number) => void;
  editStreetJob: () => void;
  selectedJob?: StreetJobs;
}) {
  const dispatch = useDispatch();

  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const { streetJob } = useSelector(state => state.customers.streets);

  const [isServiceStatusOpen, setIsServiceStatusOpen] = useState(false);

  if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.streetJob || !selectedJob || !streetJob) {
    return null;
  }

  const serviceDate =
    !!streetJob.startDate && !!streetJob.endDate
      ? `${translate('common.dateRange')}: ${moment(streetJob.startDate).format(dateFormat)} - ${moment(
          streetJob.endDate,
        ).format(dateFormat)}`
      : !!streetJob.startDate
      ? `${translate('common.startDate')}: ${moment(streetJob.startDate).format(dateFormat)}`
      : !!streetJob.endDate
      ? `${translate('common.endDate')}: ${moment(streetJob.endDate).format(dateFormat)}`
      : '-';

  const streetJobRunsWithDate =
    streetJob?.streetJobRuns?.map((streetJobRun: StreetJobRuns) => {
      return {
        ...streetJobRun,
        date: streetJobRun.date || streetJobRun.routeDate,
      };
    }) || [];

  const streetJobsRunsUniq = streetJobRunsWithDate.reduce(
    (acc: StreetJobRunsSimplified[], current: StreetJobRunsSimplified) => {
      const alreadyExists = acc.some(
        (item: StreetJobRunsSimplified) =>
          item.date === current.date && item.pickupStatusTypeId === current.pickupStatusTypeId,
      );

      if (!alreadyExists) {
        acc.push({ date: current.date, pickupStatusTypeId: current.pickupStatusTypeId });
      }

      return acc;
    },
    [],
  );

  const serviceStatus =
    streetJobsRunsUniq?.map((streetJobRun: StreetJobRunsSimplified, index: number) => {
      return (
        <GridColumn size="12/12" padding="no" margin="no" key={index}>
          <Text block margin="xxSmall no no no" size="medium">
            {streetJobRun.date ? moment(streetJobRun.date).format(dateFormat) : undefined}
            {' - '}
            {PICKUP_STATUSES[streetJobRun.pickupStatusTypeId]?.name}
          </Text>
        </GridColumn>
      );
    }) || [];

  const serviceStatusLimit = 2;

  return (
    <MapGLPopupWrapper
      closeOnClick={false}
      latitude={selectedJob.latitude}
      longitude={selectedJob.longitude}
      onClose={() => dispatch(clearRouteMapSelectedFeature())}
    >
      <MapGLPopup overflow="hidden">
        <MapGLPopupContent>
          {streetJob && (
            <Grid multiLine>
              <GridColumn size="12/12" padding="no" margin="no">
                <RouteMapFiltersTitle>{translate('customers.streets.streetJobDetails')}</RouteMapFiltersTitle>
              </GridColumn>

              <GridColumn size="9/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.address')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {streetJob.line1 || '-'}
                </Text>
              </GridColumn>
              <GridColumn size="3/12" padding="no" margin="small no no no" align="right">
                <Label color={streetJob.active ? 'success' : 'info'}>
                  {streetJob.active
                    ? translate('customers.streetNetwork.active')
                    : translate('customers.streetNetwork.inactive')}
                </Label>
              </GridColumn>

              <GridColumn size="6/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.jobType')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {translate(
                    `customers.streets.jobIntervalType.${camelCase(streetJob?.jobIntervalType?.technicalName)}`,
                  )}
                </Text>
              </GridColumn>

              <GridColumn size="6/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.jobName')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {streetJob.streetJobType?.name || '-'}
                </Text>
              </GridColumn>

              <GridColumn size="12/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.serviceDate')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {serviceDate}
                </Text>
              </GridColumn>

              <GridColumn size="12/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.route')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {streetJob.routeName || '-'}
                </Text>
              </GridColumn>

              <GridColumn size="12/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.jobPriority')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {translate(
                    `customers.streets.jobPriorityType.${camelCase(streetJob?.jobPriorityType?.technicalName)}`,
                  )}
                </Text>
              </GridColumn>

              <GridColumn size="12/12" padding="no" margin="small no no no">
                <Text block weight="medium" size="sMedium">
                  {translate('customers.streets.serviceStatus')}
                </Text>
                <Text block margin="xxSmall no no no" size="medium">
                  {serviceStatus.slice(0, serviceStatusLimit)}

                  {serviceStatus.length > serviceStatusLimit && (
                    <ServiceStatusWrapper>
                      <Text
                        color="primary"
                        cursor="pointer"
                        block
                        onMouseOver={() => setIsServiceStatusOpen(true)}
                        onMouseOut={() => setIsServiceStatusOpen(false)}
                        margin="xxSmall no no no"
                      >
                        + {serviceStatus.length - serviceStatusLimit} {translate('common.more')}
                      </Text>
                      <Popover position={TOP} isOpened={isServiceStatusOpen}>
                        {serviceStatus.slice(serviceStatusLimit)}
                      </Popover>
                    </ServiceStatusWrapper>
                  )}

                  {!serviceStatus.length && '-'}
                </Text>
              </GridColumn>
            </Grid>
          )}
        </MapGLPopupContent>
      </MapGLPopup>

      <ModalFixedFooter position="absolute">
        <GridColumn size="12/12" padding="no" margin="small no no no" align="center">
          <Button type="button" color="primary" margin="no xSmall no no" onClick={editStreetJob}>
            {translate('common.edit')}
          </Button>
          <Button
            type="button"
            line
            color="primary"
            margin="no no Xsmall no"
            onClick={() => deleteStreetJob(streetJob.id)}
          >
            {translate('common.delete')}
          </Button>
        </GridColumn>
      </ModalFixedFooter>
    </MapGLPopupWrapper>
  );
}
