import { isEqual } from 'lodash-es';
import { FC, useEffect, useState } from 'react';

import { Coordinates } from 'src/common/components/map/Coordinates';
import { getMapBounds } from 'src/common/components/map/util';
import { InfoSection } from 'src/core/components/styled/Button';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { MAP_CITY_ZOOM } from 'src/core/constants';
import { MapGL } from 'src/common/components/map/MapGL';
import { MapGLViewport } from 'src/common/interfaces/MapGLViewport';
import { MapGLWrapper } from 'src/customers/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import LocationMapPickerLayer from 'src/routes/components/LocationMapPickerLayer';
import translate from 'src/core/services/translate';

interface Props {
  handleLocationChange: (location: Coordinates) => void;
  isLocationPickerChanged: boolean;
  isPinOnMapVisible: boolean;
  location?: LocationData;
}

const AddJobLocationMapPicker: FC<Props> = ({
  handleLocationChange,
  isLocationPickerChanged,
  isPinOnMapVisible,
  location,
}) => {
  const vendorAddress = useSelector(state => state.vendors.vendor.vendor.homeAddress);

  const [mapRef, setMapRef] = useState<mapboxgl.Map>();
  const [dragPan, setDragPan] = useState(true);

  const [viewport, setViewport] = useState<MapGLViewport>({
    latitude: location ? location.latitude : vendorAddress?.latitude,
    longitude: location ? location.longitude : vendorAddress?.longitude,
    zoom: MAP_CITY_ZOOM,
  });

  useEffect(() => {
    if (
      location &&
      location.latitude &&
      location.longitude &&
      location.latitude !== viewport.latitude &&
      location.longitude !== viewport.longitude &&
      isLocationPickerChanged
    ) {
      setViewport({
        ...viewport,
        latitude: location.latitude,
        longitude: location.longitude,
      });
    }
  }, [location, viewport, isLocationPickerChanged]);

  useEffect(() => {
    const points: { latitude: number; longitude: number }[] = [];

    if (points.length > 0) {
      const bounds = getMapBounds(points, {
        capZoom: MAP_CITY_ZOOM,
      });

      !isEqual(viewport, bounds) &&
        setViewport({
          ...viewport,
          ...bounds,
        });
    }
  }, [viewport]);

  return (
    <MapGLWrapper>
      <MapGL
        key="AddJobLocationMapPicker"
        disableDefaultSatelliteView
        enableNewSatelliteView
        disableDefaultNavigationControl
        enableNewNavigationControl
        onMapRefLoaded={setMapRef}
        viewport={viewport}
        dragPan={dragPan}
        doubleClickZoom={false}
      >
        {mapRef && (
          <>
            <LocationMapPickerLayer
              isPinOnMapVisible={isPinOnMapVisible}
              location={location}
              map={mapRef}
              onLocationChanged={handleLocationChange}
              setDragPan={setDragPan}
            />
          </>
        )}

        {isPinOnMapVisible && <InfoSection>{translate('vendors.streetJobs.mapDoubleClick')}</InfoSection>}
      </MapGL>
    </MapGLWrapper>
  );
};

export default AddJobLocationMapPicker;
