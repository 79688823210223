import { Field, getFormValues, change, WrappedFieldArrayProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { AppState } from 'src/store';
import { Grid, GridColumn, Popover, TableActionButton, TableCell, TableRow } from 'src/core/components/styled';
import { Input, Dropdown, Switch, MultiSelect, PopoverWrapper, ActionButtonTooltip } from 'src/core/components';
import { isRequired, isWithinProximityRange } from 'src/utils/services/validator';
import { STREET_JOBS_FORM } from '../StreetJobsForm';
import confirm from 'src/core/services/confirm';
import CustomizedColorPicker from '../../styled/CustomizedColorPicker';
import translate from 'src/core/services/translate';

type Props = WrappedFieldArrayProps<any> & {
  index: number;
  cellWidths: { [key: string]: string };
  handleRemove: (index: number) => void;
};

const StreetJobsFormTableRow: React.FC<Props> = ({ fields, cellWidths, handleRemove }) => {
  const dispatch = useDispatch();
  const formValues: any = useSelector(getFormValues(STREET_JOBS_FORM));
  const streetJobOptions = useSelector((state: AppState) => state.vendors.streetJobs.streetJobTypeOptions);

  const handleActiveChange = async (index: number, value: boolean) => {
    if (!value && (await confirm(translate('customers.streets.alertMessages.statusDisableError')))) {
      dispatch(change(STREET_JOBS_FORM, `streetJobs[${index}].active`, value));
    } else {
      dispatch(change(STREET_JOBS_FORM, `streetJobs[${index}].active`, !value));
    }
  };

  const {
    vehicleTypes: streetJobTypes,
    alertProximityUoms,
    serviceConfirmationTypes,
    pictureOptionTypes,
    pictureRequirementOptionTypes,
    notesRequirementOptionTypes,
    requiredVehicleTypeIds,
    requiredAlertProximityUomId,
    requiredNotesRequirementOptionTypeId,
    requiredPictureOptionTypeId,
    requiredPictureRequirementOptionTypeId,
    requiredServiceConfirmationId,
    maximumAlertProximityDistance,
    minimumAlertProximityDistance,
  } = streetJobOptions;

  const proximityDistanceValidators = React.useMemo(() => {
    if (requiredAlertProximityUomId) {
      return [
        isRequired,
        (value: any) => isWithinProximityRange(value, minimumAlertProximityDistance, maximumAlertProximityDistance),
      ];
    }
    return [];
  }, [requiredAlertProximityUomId, minimumAlertProximityDistance, maximumAlertProximityDistance]);

  return (
    <>
      {fields.map((field, index) => {
        const iconColor = formValues?.streetJobs?.[index]?.iconColor || { r: 0, g: 0, b: 0, a: 1 }; // Default color
        const jobName = formValues?.streetJobs?.[index]?.name || '';
        const isNameOver15Characters = jobName.length > 16;
        const requiredPictureType =
          formValues?.streetJobs?.[index]?.pictureRequirementOptionTypeId === 1 ||
          formValues?.streetJobs?.[index]?.pictureRequirementOptionTypeId === 2; // Check if picture option "Required" or "Optional" is selected
        const isNew = formValues?.streetJobs?.[index]?.id === -1;

        return (
          <TableRow key={index} id={`streetJobCell-${field}`}>
            <TableCell width={cellWidths.name}>
              <PopoverWrapper
                triggerButton={
                  <Field
                    name={`${field}.name`}
                    component={Input}
                    props={{
                      placeholder: translate('vendors.streetJobs.table.jobName'),
                      margin: 'no',
                    }}
                    validate={[isRequired]}
                  />
                }
                popoverContent={isNameOver15Characters && <Popover>{jobName}</Popover>}
              />
            </TableCell>
            <TableCell width={cellWidths.active} align="center">
              <Field
                name={`${field}.active`}
                onChange={(_, value: boolean) => handleActiveChange(index, value)}
                component={Switch}
                validate={[]}
                props={{ margin: 'no' }}
              />
            </TableCell>
            <TableCell position="relative" width={cellWidths.iconColor} align="center">
              <Field
                name={`${field}.iconColor`}
                component={CustomizedColorPicker}
                props={{ disabled: false, color: iconColor, isRightPosition: true }}
              />
            </TableCell>
            <TableCell width={cellWidths.vehicleTypeIds} align="center">
              <Field
                name={`${field}.vehicleTypeIds`}
                component={MultiSelect}
                validate={requiredVehicleTypeIds ? [isRequired] : []}
                props={{
                  options: streetJobTypes,
                  width: '100%',
                  margin: 'no',
                  padding: 'no',
                  defaultToAll: true,
                }}
              />
            </TableCell>
            <TableCell width={cellWidths.serviceConfirmationTypeId} align="center">
              <Field
                name={`${field}.serviceConfirmationTypeId`}
                component={Dropdown}
                validate={requiredServiceConfirmationId ? [isRequired] : []}
                props={{
                  options: serviceConfirmationTypes,
                  width: '100%',
                  margin: 'no',
                  padding: 'no',
                }}
              />
            </TableCell>
            <TableCell width={cellWidths.alertProximityUomId} align="center">
              <Grid multiLine>
                <GridColumn size="5/12" padding="no">
                  <Field
                    name={`${field}.alertProximityDistance`}
                    component={Input}
                    validate={proximityDistanceValidators}
                    props={{
                      width: '100%',
                      type: 'number',
                      margin: 'no',
                      padding: 'no',
                    }}
                  />
                </GridColumn>
                <GridColumn size="7/12" padding="no">
                  <Field
                    name={`${field}.alertProximityUomId`}
                    component={Dropdown}
                    validate={requiredAlertProximityUomId ? [isRequired] : []}
                    props={{
                      options: alertProximityUoms,
                      width: '100%',
                      margin: 'no',
                      padding: 'no',
                    }}
                  />
                </GridColumn>
              </Grid>
            </TableCell>
            <TableCell width={cellWidths.pictureRequirementOptionTypeId} align="center">
              <Grid multiLine>
                <GridColumn size="12/12" padding="no">
                  <Field
                    name={`${field}.pictureOptionTypeId`}
                    component={Dropdown}
                    validate={requiredPictureOptionTypeId || requiredPictureType ? [isRequired] : []}
                    props={{
                      options: pictureOptionTypes,
                      width: '100%',
                      label: translate('vendors.streetJobs.table.pictureType'),
                      margin: 'no',
                    }}
                  />
                </GridColumn>
                <GridColumn size="12/12" padding="no">
                  <Field
                    name={`${field}.pictureRequirementOptionTypeId`}
                    component={Dropdown}
                    validate={requiredPictureRequirementOptionTypeId ? [isRequired] : []}
                    props={{
                      options: pictureRequirementOptionTypes,
                      width: '100%',
                      label: translate('vendors.streetJobs.table.pictureRequirement'),
                      margin: 'no',
                    }}
                  />
                </GridColumn>
              </Grid>
            </TableCell>
            <TableCell width={cellWidths.notesRequirementOptionTypeId} align="center">
              <Field
                name={`${field}.notesRequirementOptionTypeId`}
                component={Dropdown}
                validate={requiredNotesRequirementOptionTypeId ? [isRequired] : []}
                props={{
                  options: notesRequirementOptionTypes,
                  width: '100%',
                  margin: 'no',
                }}
              />
            </TableCell>
            <TableCell width={cellWidths.trackServicingInterval} align="center">
              <Field name={`${field}.trackServicingInterval`} component={Switch} props={{ margin: 'no' }} />

              {isNew && (
                <TableActionButton
                  onClick={() => handleRemove(index)}
                  id={`streetJobs-${field}-delete-button`}
                  margin="no no no xSmall"
                >
                  <ActionButtonTooltip size="small" icon="delete" tooltip="delete" />
                </TableActionButton>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default StreetJobsFormTableRow;
