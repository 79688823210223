import { Fragment } from 'react';
import { camelCase, find, filter, map, sortBy } from 'lodash-es';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import {
  Button,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  ModalFixedFooter,
  ModalSection,
  PanelSection,
  Text,
} from '../../../core/components/styled';
import { createSuccessNotification } from 'src/core/services/createNotification';
import {
  D_0_1_ID,
  D_1_3_ID,
  D_3_5_ID,
  D_5_7_ID,
  D_7_ID,
  DAYS_ID,
  H_0_4_ID,
  H_16_24_ID,
  H_24_ID,
  H_4_8_ID,
  H_8_16_ID,
  HOURS_ID,
  TIME_MEASURMENT_TYPES_OPTIONS,
  W_0_1_ID,
  W_1_2_ID,
  W_2_3_ID,
  W_3_4_ID,
  W_4_ID,
  WEEKS_ID,
  M_0_1_ID,
  M_1_2_ID,
  M_2_3_ID,
  M_3_4_ID,
  M_4_ID,
} from 'src/vendors/constants/streetSegmentAgingIntervals';
import { Dropdown, Input, Table, TypedField } from '../../../core/components';
import { DropdownOption } from 'src/core/components/Dropdown';
import { getAgingIntervalOptionLabel } from 'src/routes/utils/routeDetails';
import { loadSnowPlowDefaultSettings } from 'src/vendors/ducks';
import { loadStreetSegmentAgingIntervals } from 'src/common/ducks';
import {
  NON_SERVICE_CONFIGURATION_TYPES,
  SERVICE_CONFIGURATION_TYPES,
} from 'src/common/constants/serviceConfiguration';
import { optionalMaxValueNumeric9999_99, optionalMinValueNumeric0_1 } from 'src/utils/services/validator';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import {
  SegmentConfiguration,
  ServiceConfiguration,
  SnowPlowSettings,
  StreetSegmentAgingInterval,
  WeightConfiguration,
} from '../../interfaces/SnowPlowSettings';
import { Separator } from 'src/core/components/styled';
import { snowPlowModalId } from '../modals/SnowPlowModal';
import { SnowPlowTableRow } from '../';
import { UNIT_OF_MEASUREMENT } from 'src/fleet/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import DriverExperience from './DriverExperience';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import NonServiceSubTypes from './NonServiceSubTypes';
import ServiceSubTypes from './ServiceSubTypes';
import SnowPlowDisplayOptionsTableRow from '../SnowPlowDisplayOptionsTableRow';
import translate from '../../../core/services/translate';

const SNOW_PLOW_FORM_NAME = 'snowPlowForm';

interface ComponentProps {
  initialValues: SnowPlowSettings;
  onCancel: (pristine: boolean) => void;
  pristine?: boolean;
  vendorId: number;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

function SnowPlowForm({ change, handleSubmit, initialValues, onCancel, pristine, vendorId }: Props) {
  const dispatch = useDispatch();

  const formValues = useSelector(getFormValues(SNOW_PLOW_FORM_NAME)) as SnowPlowSettings;
  const isMaterialPickUpTicketEnabled = formValues.materialPickUpTicket;

  const [segmentConfiguration, setSegmentConfiguration] = useState<SegmentConfiguration[]>(
    initialValues.displayConfiguration.segmentColorSettings,
  );
  const [agingIntervalErrors, setAgingIntervalErrors] = useState<boolean[]>([]);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(
    !(
      segmentConfiguration &&
      (segmentConfiguration[0]?.enabled || segmentConfiguration[segmentConfiguration.length - 1]?.enabled)
    ),
  );
  const [agingIntervalMeasurmentTypeId, setAgingIntervalMeasurmentTypeId] = useState<number>(
    segmentConfiguration[0]?.streetSegmentAgingInterval.timeMeasurementType.id,
  );

  const [agingInterval, setAgingInterval] = useState<StreetSegmentAgingInterval[]>([]);
  const [agingIntervalOptions, setAgingIntervalOptions] = useState<DropdownOption[]>([]);

  const loadStreetSegmentInterval = useCallback(
    (lastServicedTimeFormatId: number) => {
      loadStreetSegmentAgingIntervals(lastServicedTimeFormatId)(dispatch).then(
        (response: StreetSegmentAgingInterval[]) => {
          setAgingInterval(response);
          const sortedAgingIntervalsWithMaxValue = sortBy(
            filter(response, (item: StreetSegmentAgingInterval) => item.maxValue),
            ['minValue', 'maxValue'],
            ['desc', 'desc'],
          );
          const sortedAgingIntervalsWithMinValue = sortBy(
            filter(response, (item: StreetSegmentAgingInterval) => !item.maxValue),
            [(el: StreetSegmentAgingInterval) => el.minValue],
          );
          const sortedAgingIntervals = [
            ...sortedAgingIntervalsWithMaxValue,
            ...sortedAgingIntervalsWithMinValue,
          ] as StreetSegmentAgingInterval[];
          setAgingIntervalOptions(
            sortedAgingIntervals.map((item: StreetSegmentAgingInterval) => ({
              label: getAgingIntervalOptionLabel(item.timeMeasurementType.id, item.name),
              value: item.id,
            })),
          );
        },
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (agingIntervalMeasurmentTypeId) loadStreetSegmentInterval(agingIntervalMeasurmentTypeId);
  }, [agingIntervalMeasurmentTypeId, loadStreetSegmentInterval]);

  useEffect(() => {
    if (!isMaterialPickUpTicketEnabled) {
      initialValues.weightConfiguration.map((_, index) => change(`weightConfiguration[${index}].weight`, null));
    }
  }, [change, isMaterialPickUpTicketEnabled, initialValues.weightConfiguration]);

  if (!isFormDirty && find(agingIntervalErrors, (item: boolean) => item)) {
    setIsFormDirty(true);
  } else if (isFormDirty && !find(agingIntervalErrors, (item: boolean) => item)) {
    setIsFormDirty(false);
  }

  const handleLastServicedTimeFormatChange = (e: any) => {
    const id = e as unknown as number;

    setAgingIntervalMeasurmentTypeId(id);

    segmentConfiguration.forEach((el: SegmentConfiguration, i: number) => {
      let value;

      switch (i) {
        case 0:
          value = id === HOURS_ID ? H_0_4_ID : id === DAYS_ID ? D_0_1_ID : id === WEEKS_ID ? W_0_1_ID : M_0_1_ID;
          break;

        case 1:
          value = id === HOURS_ID ? H_4_8_ID : id === DAYS_ID ? D_1_3_ID : id === WEEKS_ID ? W_1_2_ID : M_1_2_ID;
          break;

        case 2:
          value = id === HOURS_ID ? H_8_16_ID : id === DAYS_ID ? D_3_5_ID : id === WEEKS_ID ? W_2_3_ID : M_2_3_ID;
          break;

        case 3:
          value = id === HOURS_ID ? H_16_24_ID : id === DAYS_ID ? D_5_7_ID : id === WEEKS_ID ? W_3_4_ID : M_3_4_ID;
          break;

        case 4:
          value = id === HOURS_ID ? H_24_ID : id === DAYS_ID ? D_7_ID : id === WEEKS_ID ? W_4_ID : M_4_ID;
          break;

        default:
          break;
      }

      value = initialValues.lastServicedTimeFormatId === id ? el.streetSegmentAgingInterval.id : value;
      return change(`displayConfiguration.segmentColorSettings[${i}].streetSegmentAgingInterval.id`, value);
    });
  };

  const LastServicedTimeTableCellDropdown = () => {
    return (
      <TypedField
        name="lastServicedTimeFormatId"
        component={Dropdown}
        onChange={handleLastServicedTimeFormatChange}
        props={{
          margin: 'xxSmall no no small',
          width: '90px',
          placeholder: 'Format',
          options: TIME_MEASURMENT_TYPES_OPTIONS.map(el => {
            return { value: el.id, label: el.name };
          }),
        }}
      />
    );
  };

  const snowPlowFormTableCells = [
    { name: 'features', label: translate('vendors.features'), width: '80%' },
    { name: 'isActive', label: translate('common.active'), width: '20%' },
  ];

  const SnowPlowDisplayOptionsTableCells = [
    { name: 'displayOptions', label: translate('vendors.displayOptions'), width: '30%' },
    {
      name: 'lastServicedTime',
      label: translate('vendors.lastServicedTime'),
      component: LastServicedTimeTableCellDropdown,
      width: '50%',
    },
    { name: 'color', label: translate('common.color'), width: '20%' },
  ];

  const snowPlowSettings = [
    {
      label: translate('vendors.snowPlow.snowPlowConditions'),
      name: 'snowPlowConditions',
      value: initialValues.isPlowing,
    },
    {
      label: translate('vendors.snowPlow.switchRoutes'),
      name: 'switchRoutes',
      value: initialValues.switchRoutes,
    },
    {
      label: translate('vendors.snowPlow.preTripInspectionAtLogin'),
      name: 'preTripInspectionAtLogin',
      value: initialValues.preTripInspectionAtLogin,
    },
    {
      label: translate('vendors.snowPlow.postTripInspectionAtLogout'),
      name: 'postTripInspectionAtLogout',
      value: initialValues.postTripInspectionAtLogout,
    },
    {
      label: translate('vendors.snowPlow.materialPickUpTicket'),
      name: 'materialPickUpTicket',
      value: initialValues.materialPickUpTicket,
    },
    {
      label: translate('vendors.snowPlow.routeResetPortal'),
      name: 'routeResetPortal',
      value: initialValues.routeResetPortal,
    },
    {
      label: translate('vendors.snowPlow.routeResetApp'),
      name: 'routeResetApp',
      value: initialValues.routeResetApp,
    },
    {
      label: translate('vendors.snowPlow.simpleDriverExperience'),
      name: 'simpleDriverExperience',
      value: initialValues.simpleDriverExperience,
    },
  ];

  const restoreToDefaults = () => {
    scrollToTopOfModal(snowPlowModalId);

    loadSnowPlowDefaultSettings(vendorId)(dispatch).then(async response => {
      const lastServicedTimeFormatId =
        response.displayConfiguration.segmentConfiguration[0].streetSegmentAgingInterval.timeMeasurementType.id;
      await setAgingIntervalMeasurmentTypeId(lastServicedTimeFormatId);

      change('lastServicedTimeFormatId', lastServicedTimeFormatId);

      change('snowPlowConditions', response.snowPlowConditions);
      change('isPlowing', response.isPlowing);
      change('switchRoutes', response.switchRoutes);
      change('materialPickUpTicket', response.materialPickUpTicket);
      change('preTripInspectionAtLogin', response.preTripInspectionAtLogin);
      change('postTripInspectionAtLogout', response.postTripInspectionAtLogout);
      change('routeResetPortal', response.routeResetPortal);
      change('routeResetApp', response.routeResetApp);
      change('simpleDriverExperience', response.simpleDriverExperience);

      change(
        'serviceSubTypes',
        response.serviceConfiguration
          ?.filter(
            (el: ServiceConfiguration) =>
              !!SERVICE_CONFIGURATION_TYPES[el.alternativeFleetServiceType.technicalName] && el.isActive,
          )
          .map((el: ServiceConfiguration) => el.alternativeFleetServiceType.technicalName),
      );
      change(
        'nonServiceSubTypes',
        response.serviceConfiguration
          ?.filter(
            (el: ServiceConfiguration) =>
              !!NON_SERVICE_CONFIGURATION_TYPES[el.alternativeFleetServiceType.technicalName] && el.isActive,
          )
          .map((el: ServiceConfiguration) => el.alternativeFleetServiceType.technicalName),
      );

      change('drivingComplexityType', response.drivingComplexityType.id.toString());

      response.displayConfiguration.segmentConfiguration.forEach((el: SegmentConfiguration, i: number) => {
        change(`displayConfiguration.segmentColorSettings[${i}].enabled`, el.enabled);
        change(
          `displayConfiguration.segmentColorSettings[${i}].streetSegmentAgingInterval.id`,
          el.streetSegmentAgingInterval.id,
        );
        change(`displayConfiguration.segmentColorSettings[${i}].color`, {
          r: el.red,
          g: el.green,
          b: el.blue,
        });
      });

      const segmentConfigurationFormatted = segmentConfiguration.map((segment, index) => ({
        ...segment,
        red: response.displayConfiguration.segmentConfiguration[index].red,
        green: response.displayConfiguration.segmentConfiguration[index].green,
        blue: response.displayConfiguration.segmentConfiguration[index].blue,
      }));
      setSegmentConfiguration(segmentConfigurationFormatted);

      createSuccessNotification(translate('vendors.alertMessages.defaultsSuccessfullyRestored'));
    });
  };

  const uomOptions = map(UNIT_OF_MEASUREMENT, ({ id, name }) => ({
    label: name,
    value: id,
  }));

  return (
    <>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <form onSubmit={handleSubmit}>
        <PanelSection padding="small xSmall no" vertical>
          <Table cells={snowPlowFormTableCells} rowComponent={SnowPlowTableRow} rows={snowPlowSettings} />

          {isMaterialPickUpTicketEnabled &&
            initialValues.weightConfiguration.map((config: WeightConfiguration, index: number) => (
              <Fragment key={index}>
                <Grid centered padding={index === 0 ? 'small no xSmall medium' : 'no no xSmall medium'}>
                  <GridColumn size="4/12" margin="medium no no no">
                    {translate(`vendors.weightConfiguration.${camelCase(config.unitOfMeasure.technicalName)}`)}
                  </GridColumn>
                  <GridColumn size="4/12">
                    <TypedField
                      name={`weightConfiguration[${index}].weight`}
                      component={Input}
                      props={{
                        label: translate('vendors.weightConfiguration.weightValue'),
                        disabled: false,
                        margin: 'no',
                        type: 'number',
                      }}
                      validate={[optionalMinValueNumeric0_1, optionalMaxValueNumeric9999_99]}
                    />
                  </GridColumn>
                  <GridColumn size="4/12">
                    <TypedField
                      name={`weightConfiguration[${index}].unitOfMass.id`}
                      component={Dropdown}
                      props={{
                        label: translate('common.unitOfMeasurement'),
                        disabled: false,
                        margin: 'no',
                        options: uomOptions,
                      }}
                    />
                  </GridColumn>
                </Grid>
                <Separator padding="xSmall no xSmall medium" size={1} color="grayLight" />
              </Fragment>
            ))}

          <Table
            cells={SnowPlowDisplayOptionsTableCells}
            rowComponent={SnowPlowDisplayOptionsTableRow}
            rows={segmentConfiguration}
            rowProps={{
              agingInterval,
              agingIntervalErrors,
              agingIntervalMeasurmentTypeId,
              agingIntervalOptions,
              formName: SNOW_PLOW_FORM_NAME,
              segmentConfigurationSize: segmentConfiguration.length - 1,
              setAgingIntervalErrors,
              setIsFormDirty,
            }}
          />

          {!segmentConfiguration?.length && (
            <ModalSection padding="small small medium">
              <Text>{translate('routes.snowPlow.noSegmentColorSettings')}</Text>
            </ModalSection>
          )}

          <ServiceSubTypes />
          <NonServiceSubTypes />

          <DriverExperience />
        </PanelSection>

        <ModalFixedFooter isShadowed zIndex={5}>
          <Button type="button" color="primary" margin="no small no no" onClick={() => restoreToDefaults()}>
            {translate('routes.restoreToDefaults')}
          </Button>
          <Button disabled={isFormDirty} type="submit" color="primary">
            {translate('common.save')}
          </Button>
        </ModalFixedFooter>
      </form>
    </>
  );
}

export default reduxForm({ form: SNOW_PLOW_FORM_NAME, onSubmitFail: focusFirstInvalidField })(SnowPlowForm);
