import translate from '../../core/services/translate';

export const STREET_JOB_ID = 1;
// const STREET_SEGMENT_ID = 2;

export const TYPE_OPTIONS = [
  {
    value: STREET_JOB_ID,
    label: translate('customers.streets.streetJob'),
  },
  // uncomment this when street segments are visible on map
  //   {
  //     value: STREET_SEGMENT_ID,
  //     label: translate('customers.streets.streetSegment'),
  //   },
];

export const ASSIGNED_ID = 1;
export const UNASSIGNED_ID = 2;

export const ROUTE_ASSIGNMENT_OPTIONS = [
  {
    value: ASSIGNED_ID,
    label: translate('customers.streets.assigned'),
  },
  {
    value: UNASSIGNED_ID,
    label: translate('customers.streets.unassigned'),
  },
];

export const ACTIVE_ID = 1;
export const INACTIVE_ID = 2;

export const STATUS_OPTIONS = [
  {
    value: ACTIVE_ID,
    label: translate('customers.streets.active'),
  },
  {
    value: INACTIVE_ID,
    label: translate('customers.streets.inactive'),
  },
];

export const ROUTE_UNASSIGNED_ID = '-1';
