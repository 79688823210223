import { Modal } from 'src/core/components';
import { StreetJobsTransferForm } from '../../forms';
import { StreetJobsTransferFormValues } from '../../forms/StreetJobsTransferForm';
import { useSelector } from 'src/core/hooks/useSelector';

type Props = {
  closeModal: () => void;
  hasSnowPlowSelectedStreetJobs: boolean;
  hasStreetSweeperSelectedStreetJobs: boolean;
  isStreetJobAssigned: boolean;
  modalTitle: string;
  transferStreetJobs?: (formData: StreetJobsTransferFormValues) => void;
};

export default function StreetJobsTransferModal({
  closeModal,
  hasSnowPlowSelectedStreetJobs,
  hasStreetSweeperSelectedStreetJobs,
  isStreetJobAssigned,
  modalTitle,
  transferStreetJobs,
}: Props) {
  const { isTransferingStreetJobs } = useSelector(state => state.customers.streets);

  return (
    <Modal
      title={modalTitle}
      onClose={closeModal}
      padding="medium no no no"
      isLoading={isTransferingStreetJobs}
      size="smallMedium"
    >
      <StreetJobsTransferForm
        onSubmit={transferStreetJobs}
        isStreetJobAssigned={isStreetJobAssigned}
        hasSnowPlowSelectedStreetJobs={hasSnowPlowSelectedStreetJobs}
        hasStreetSweeperSelectedStreetJobs={hasStreetSweeperSelectedStreetJobs}
      />
    </Modal>
  );
}
