import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import AdminOnlyGuard from 'src/account/components/AdminOnlyGuard';
import { Icon } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { OPPORTUNITIES, WORK_ORDERS, AUTO_DISPATCH } from 'src/opportunity/constants/status';
import matchRoute from 'src/utils/services/matchRoute';
import SelectDefaultVendorModalToggle from 'src/vendors/components/modals/SelectDefaultVendorModalToggle';
import { getTokenFromUrl } from '../utils/navigation';
import {
  CloseNavigationBar,
  MainNavigationIcon,
  MainNavigationLogo,
  MobileNavigationBarContainer,
  MobileNavigationNode,
  MobileNavigationNodesList,
  MobileNavigationNodeTitleContainer,
  MobileNavigationOpener,
  MobileNavigationSecondaryContent,
  MobileNavigationSubNode,
  MobileNavigationSubNodesList,
  MobilePageTitleContainer,
  NavigationBarFlyout,
  NavigationBarFlyoutBackground,
  StickyNav,
  SubNavigationItemCount,
} from './styled/NavigationBar';
import { useIsMobileView } from 'src/utils/hooks';
import {
  billingFeatureStatusSelector,
  checkIfSnowPlowIsEnabled,
  checkIfStreetSweepingIsEnabled,
  checkIfStreetJobsIsEnabled,
} from 'src/vendors/ducks/features';
import { isRubiconNonTechHauler, vendorGusIdSelector } from 'src/account/ducks';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { hasRouteTrackerVehiclesSelector } from 'src/fleet/ducks/vehicleTypesForVendor';

interface BaseNode {
  title: string;
  isHidden?: boolean;
}

interface NavigationSubNode extends BaseNode {
  url: string;
  notificationCount?: number | string;
}
interface NavigationNode extends BaseNode {
  isRubiconLogo?: boolean;
  logo: string;
  subNodes?: NavigationSubNode[];
  url?: string;
}

const SubNavigationLink = styled(NavLink)`
  display: flex;
  align-items: center;

  &.active {
    color: ${p => p.theme.brandPrimary};
  }
`;

interface Props extends RouteComponentProps {
  isLoggedIn: boolean;
}

const MobileNavigationBarComponent: React.FC<Props> = ({ location: { pathname } }, isLoggedIn) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const dispatchOpportunitiesCounts = useSelector(
    s => s.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts || {},
  );

  const hasVehiclesForRouteTracker = useSelector(hasRouteTrackerVehiclesSelector);

  const showReleaseNotesNotification = useSelector(s => s.settings.releaseNotes.showNotification);
  const isSnowPlowFeatureEnabled = useSelector(s => checkIfSnowPlowIsEnabled(s));
  const isStreetSweepingFeatureEnabled = useSelector(s => checkIfStreetSweepingIsEnabled(s));
  const isStreetJobsFeatureEnabled = useSelector(s => checkIfStreetJobsIsEnabled(s));
  const isBillingFeatureActive = useSelector(s => billingFeatureStatusSelector(s.vendors.features.features));

  const isNonTechHauler = useSelector(state =>
    isRubiconNonTechHauler(state.account.login, state.vendors.defaultVendor, state.account.createUserAccount),
  );

  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));

  const opportunitiesCount = dispatchOpportunitiesCounts.opportunities + dispatchOpportunitiesCounts.bidOpportunities;

  const autoDispatchToken = matchRoute(pathname, '/workorders')
    ? getTokenFromUrl(pathname, WORK_ORDERS.toLowerCase())
    : getTokenFromUrl(pathname, AUTO_DISPATCH);

  const isActive = (path: string) => matchRoute(pathname, path);

  const opportunitiesToken = getTokenFromUrl(pathname, OPPORTUNITIES.toLowerCase());

  const token = autoDispatchToken || opportunitiesToken;

  const hideLoggedInPages = !!token || !isLoggedIn;

  const isMobile = useIsMobileView();

  const navigationNodes: NavigationNode[] = useMemo(
    () => [
      {
        title: 'Rubicon',
        isRubiconLogo: true,
        logo: 'main',
        subNodes: [
          {
            title: translate('opportunity.opportunities.opportunities'),
            url: '/opportunities',
            notificationCount: opportunitiesCount,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('customers.rubiconServices'),
            url: `/rubicon-services`,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('opportunity.serviceChangeRequests'),
            url: `/opportunities/service-change-requests${token ? `/${token}` : ''}`,
            notificationCount: dispatchOpportunitiesCounts.serviceChangeRequests,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('autoDispatch.workOrders'),
            url: `/workorders${token ? `/${token}` : ''}`,
            notificationCount: dispatchOpportunitiesCounts.workOrders,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('invoice.invoices'),
            url: '/invoices',
            isHidden: !isVendorWithGusId,
          },
        ],
      },
      {
        title: translate('tooltips.select'),
        logo: 'rubiconSelect',
        url: '/rubicon-select',
      },
      {
        title: translate('routes.dispatch'),
        logo: 'routesCircle',
        isHidden: hideLoggedInPages || isNonTechHauler,
        subNodes: [
          {
            title: translate('dashboard.dashboard'),
            url: '/dashboard',
          },
          {
            title: translate('routes.tracker'),
            url: '/routes/route-tracker',
            isHidden: !hasVehiclesForRouteTracker && (isSnowPlowFeatureEnabled || isStreetSweepingFeatureEnabled),
          },
          {
            title: translate('routes.snowTracker'),
            url: `/routes/snow-tracker?vehicleTypeIds=${SNOW_PLOW_ID}`,
            isHidden: !isSnowPlowFeatureEnabled,
          },
          {
            title: translate('routes.sweeperTracker'),
            url: `/routes/sweeper-tracker?vehicleTypeIds=${STREET_SWEEPER_ID}`,
            isHidden: !isStreetSweepingFeatureEnabled,
          },
          {
            title: translate('routes.planner.routePlanner'),
            url: '/routes/route-templates',
          },
          {
            title: translate('routes.dispatchBoard'),
            url: '/routes/dispatch-board',
          },
        ],
      },

      {
        title: translate('customers.customers'),
        logo: 'customer',
        isHidden: hideLoggedInPages || isNonTechHauler,
        subNodes: [
          {
            title: translate('customers.customers'),
            url: '/customers/customers',
          },
          {
            title: translate('customers.streets.streets'),
            url: '/customers/streets',
            isHidden: !isStreetJobsFeatureEnabled || (!isSnowPlowFeatureEnabled && !isStreetSweepingFeatureEnabled),
          },
          {
            title: translate('customers.suspendedLocations'),
            url: '/customers/suspended-locations',
          },
          {
            title: translate('customers.streetNetwork.serviceNetwork'),
            url: '/customers/service-network',
          },
        ],
      },
      {
        title: translate('common.fleet'),
        logo: 'fleetCircle',
        isHidden: hideLoggedInPages || isNonTechHauler,
        subNodes: [
          {
            title: translate('vehicles.vehicles'),
            url: '/fleet/vehicles',
          },
          {
            title: translate('resources.resources'),
            url: '/fleet/resources',
          },
          {
            title: translate('containers.containers'),
            url: '/fleet/containers',
          },
          {
            title: translate('facilities.facilities'),
            url: '/fleet/facilities',
          },
          {
            title: translate('routes.geoFences.geoFencesTitle'),
            url: '/fleet/geo-fences',
          },
          {
            title: translate('vendors.cityAlerts.alerts'),
            url: '/fleet/alerts',
          },
        ],
      },
      {
        title: translate('finance.finance'),
        logo: 'financeCircle',
        isHidden: hideLoggedInPages || isNonTechHauler || !isBillingFeatureActive,
        subNodes: [
          {
            title: translate('finance.billing'),
            url: '/finance/billing',
          },
          {
            title: translate('finance.applyChecks.applyChecks'),
            url: '/finance/payments',
          },
          // {
          //   title: translate('finance.rateManager.rateManager'),
          //   url: '/finance/rate-configuration',
          // },
        ],
      },
      {
        title: translate('insights.insights'),
        logo: 'insightsCircle',
        isHidden: hideLoggedInPages || isNonTechHauler,
        subNodes: [
          {
            title: translate('common.fleet'),
            url: '/insights/insightsfleet',
          },
          {
            title: translate('dashboard.wasteAudit'),
            url: '/insights/wasteaudit',
          },
          {
            title: translate('insights.community'),
            url: '/insights/community-insights',
          },
          {
            title: translate('insights.dataExport'),
            url: '/insights/reports',
          },
        ],
      },
      {
        title: translate('common.settings'),
        logo: 'settingsCircle',
        isHidden: hideLoggedInPages,
        subNodes: [
          {
            title: translate('common.applicationSettings'),
            url: '/account-settings',
          },
          {
            title: translate('common.adminSettings'),
            url: '/settings/tracking',
          },
          {
            title: translate('haulerProfile.haulerProfile'),
            url: '/hauler-profile/overview',
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('versioning.releaseNotes'),
            url: '/release-notes',
            notificationCount: showReleaseNotesNotification ? '!' : undefined,
          },
        ],
      },
    ],
    [
      dispatchOpportunitiesCounts.serviceChangeRequests,
      dispatchOpportunitiesCounts.workOrders,
      hasVehiclesForRouteTracker,
      hideLoggedInPages,
      isBillingFeatureActive,
      isNonTechHauler,
      isSnowPlowFeatureEnabled,
      isStreetJobsFeatureEnabled,
      isStreetSweepingFeatureEnabled,
      isVendorWithGusId,
      opportunitiesCount,
      showReleaseNotesNotification,
      token,
    ],
  );

  const pageTitle = useSelector(s => s.core.mobilePage.title);
  const pageSubtitle = useSelector(s => s.core.mobilePage.subTitle);
  const secondaryContent = useSelector(s => s.core.mobilePage.secondaryContent);

  return (
    <StickyNav>
      <MobileNavigationBarContainer>
        <MobileNavigationOpener margin="no small" onClick={() => setIsFlyoutOpen(true)} text>
          <Icon customViewBox="0 0 24 24" width="24px" icon="menu" />
        </MobileNavigationOpener>
        <MobilePageTitleContainer>
          <Text margin="no xxSmall" size="xLarge">
            {pageTitle}
          </Text>
          {pageSubtitle && (
            <Text margin="no xxSmall" size="small">
              {pageSubtitle}
            </Text>
          )}
        </MobilePageTitleContainer>

        {isMobile && secondaryContent && (
          <MobileNavigationSecondaryContent>{secondaryContent}</MobileNavigationSecondaryContent>
        )}
        {isFlyoutOpen && <CloseNavigationBar onClick={() => setIsFlyoutOpen(false)}>X</CloseNavigationBar>}
        {isFlyoutOpen && <NavigationBarFlyoutBackground />}
        <NavigationBarFlyout isOpen={isFlyoutOpen}>
          <MobileNavigationNodesList>
            {navigationNodes
              .filter(n => !n.isHidden)
              .map((n, i) => (
                <MobileNavigationNode key={i}>
                  {n.isRubiconLogo ? (
                    <>
                      <MainNavigationLogo
                        onClick={() => setIsFlyoutOpen(false)}
                        to={isMobile ? '/workorders' : '/dashboard'}
                      />
                    </>
                  ) : n.url ? (
                    <MobileNavigationNodeTitleContainer>
                      <SubNavigationLink onClick={() => setIsFlyoutOpen(false)} to={n.url!}>
                        <MainNavigationIcon icon={n.logo} />
                        <Text
                          margin="xSmall xxSmall"
                          color={`${isActive(n.url) ? 'primary' : 'grayDark'}`}
                          size="xxLarge"
                        >
                          {n.title}
                        </Text>
                      </SubNavigationLink>
                    </MobileNavigationNodeTitleContainer>
                  ) : (
                    <MobileNavigationNodeTitleContainer>
                      <MainNavigationIcon icon={n.logo} />
                      <Text margin="no xxSmall" color="grayDark" size="xxLarge">
                        {n.title}
                      </Text>
                    </MobileNavigationNodeTitleContainer>
                  )}

                  {n.subNodes && n.subNodes.length && (
                    <MobileNavigationSubNodesList>
                      {n.isRubiconLogo && (
                        <AdminOnlyGuard>
                          <MobileNavigationSubNode key="vendorChanger">
                            <SelectDefaultVendorModalToggle />
                          </MobileNavigationSubNode>
                        </AdminOnlyGuard>
                      )}
                      {n.subNodes
                        .filter(n => !n.isHidden)
                        .map((sn, i) => (
                          <MobileNavigationSubNode key={i}>
                            <SubNavigationLink onClick={() => setIsFlyoutOpen(false)} to={sn.url!}>
                              <Text size="large">{sn.title}</Text>
                              {!!sn.notificationCount && (
                                <SubNavigationItemCount isVisible={!!sn.notificationCount}>
                                  {Number(sn.notificationCount) > 99 ? '99+' : sn.notificationCount}
                                </SubNavigationItemCount>
                              )}
                            </SubNavigationLink>
                          </MobileNavigationSubNode>
                        ))}
                    </MobileNavigationSubNodesList>
                  )}
                </MobileNavigationNode>
              ))}
            {!hideLoggedInPages && (
              <MobileNavigationSubNodesList isAccount>
                <SubNavigationLink to={'/account/logout'}>
                  <Text size="large">{translate('account.logout')}</Text>
                </SubNavigationLink>
              </MobileNavigationSubNodesList>
            )}
          </MobileNavigationNodesList>
        </NavigationBarFlyout>
      </MobileNavigationBarContainer>
    </StickyNav>
  );
};

export const MobileNavigationBar = withRouter(MobileNavigationBarComponent);
