import { omit } from 'lodash-es';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import React, { useState, SyntheticEvent } from 'react';

import { ActionButtonTooltip, PopoverWrapper } from 'src/core/components';
import { CITY_ALERT_TYPES, CITY_ALERT_TYPE_ID } from 'src/fleet/constants/locationAndCityAlerts';
import { createSuccessNotification, createErrorNotification } from 'src/core/services/createNotification';
import { dateFormat, dateTimeFormat, dateTimeSecondsFormat, timeFormat } from 'src/utils/services/validator';
import { getLatLng } from './locationAndCityAlertsSections/utils';
import { getMapBounds } from 'src/common/components/map/util';
import { getWindowShouldScroll, noScrollTrigger, scrollToTopOfMap } from 'src/common/hooks/scroll';
import { loadCityAlertImages } from 'src/vendors/ducks';
import { LocationAndCityAlertsFormValues } from 'src/fleet/interfaces/LocationAndCityAlerts';
import { MAP_CITY_ZOOM, TABLE_ROW_HEIGHT_LARGEST } from 'src/core/constants';
import { ModalImage } from 'src/routes/interfaces/RouteImages';
import { RouteImagesModal } from 'src/routes/components/modals';
import { RouteMapFeature, clearRouteMapSelectedFeature } from 'src/routes/ducks/mapControls';
import { saveLocationAndCityAlert, setLocationAndCityAlertsMapViewport } from 'src/fleet/ducks';
import { setRouteMapSelectedFeature } from 'src/routes/ducks';
import { TableCell, TableRow, TableActionButton, Text, IconButtonIcon, Popover } from 'src/core/components/styled';
import { TOP } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import createPopover from 'src/core/services/createPopover';
import LocationAndCityAlertsEditModal from '../modal/LocationAndCityAlertsEditModal';
import LocationAndCityAlertsRoutesPopover from './LocationAndCityAlertsRoutesPopover';
import translate from 'src/core/services/translate';

interface Props {
  address: string;
  alertTypeId: number;
  cityAlertTypeId: number;
  createdBy?: string;
  createdOn: Date | string;
  customerName?: string;
  description: string;
  driverName: string;
  endDate: Date | string;
  expirationDate: Date | string;
  id: number;
  imageCount: number;
  isActive: boolean;
  lastFlagged: Date | string;
  latitude: number;
  locationAndCityAlertsTableCellsWidth: any[];
  locationName?: string;
  longitude: number;
  onSaveLocationAndCityAlert: () => void;
  routeNames: string[];
  startDate: Date | string;
  title: string;
  vehicleName: string;
}

const LocationAndCityAlertsTableRow: React.FC<Props> = ({
  address,
  alertTypeId,
  cityAlertTypeId,
  createdBy,
  createdOn,
  customerName,
  description,
  driverName,
  endDate,
  expirationDate,
  id,
  imageCount,
  isActive,
  lastFlagged,
  latitude,
  locationAndCityAlertsTableCellsWidth,
  locationName,
  longitude,
  onSaveLocationAndCityAlert,
  routeNames,
  startDate,
  title,
  vehicleName,
}) => {
  const dispatch = useDispatch();

  const { images } = useSelector(state => state.vendors.cityAlerts);
  const { selectedFeature } = useSelector(state => state.routes.mapControls);

  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [isEditAlertModalOpen, setIsEditAlertModalOpen] = useState<boolean>(false);

  const isCityAlert = alertTypeId === CITY_ALERT_TYPE_ID;
  const alertName = isCityAlert ? CITY_ALERT_TYPES[cityAlertTypeId].name : title;

  const openAlertImageModal = (id: number) => {
    loadCityAlertImages(id)(dispatch).then(() => {
      setIsImageModalOpen(true);
    });
  };

  const alertImages: ModalImage[] = images.map(image => ({
    imageUrl: image.url,
    rawImageUrl: image.rawUrl,
    timeStamp: image.timeStamp || '',
    imageBlobId: image.blobId,
    routeName: cityAlertTypeId && CITY_ALERT_TYPES[cityAlertTypeId].name,
    locationName: address,
  }));

  const editAlert = () => {
    setIsEditAlertModalOpen(true);
  };

  const onSubmit = (formData: LocationAndCityAlertsFormValues) => {
    const payload = {
      ...omit(formData, 'expirationDate'),
      id,
      alertTypeId,
    };

    saveLocationAndCityAlert(payload)(dispatch)
      .then(() => {
        createSuccessNotification(
          translate(
            `vendors.cityAlerts.alertMessages.${isCityAlert ? 'saveCityAlertSuccess' : 'saveLocationAlertSuccess'}`,
          ),
        );
        setIsEditAlertModalOpen(false);
        onSaveLocationAndCityAlert();
      })
      .catch(() => {
        createErrorNotification(
          translate(
            `vendors.cityAlerts.alertMessages.${isCityAlert ? 'saveCityAlertError' : 'saveLocationAlertError'}`,
          ),
        );
      });
  };

  const handleRowClick = async (value: SyntheticEvent) => {
    const shouldScroll = !getWindowShouldScroll(value.target);
    await dispatch(clearRouteMapSelectedFeature());

    if (shouldScroll) {
      scrollToTopOfMap();
      dispatch(setRouteMapSelectedFeature(RouteMapFeature.cityAlert, Number(id), { noTableScroll: true }));

      const bounds = getMapBounds([{ longitude, latitude }], {
        padding: 25,
        capZoom: MAP_CITY_ZOOM,
      });

      dispatch(setLocationAndCityAlertsMapViewport(bounds));
    }
  };

  const showRoutes = (event: any) => {
    event.stopPropagation();
    createPopover(
      event.currentTarget,
      LocationAndCityAlertsRoutesPopover,
      { routeNames },
      { position: TOP, size: 'large', maxHeight: 200, hasCloseButton: true },
    );
  };

  const getStartEndDatePopover = (isCityAlert: boolean) => {
    return (
      (startDate || endDate) && (
        <PopoverWrapper
          triggerButton={
            <IconButtonIcon
              icon="info"
              color="black"
              size="medium"
              margin="no no no xSmall"
              className={noScrollTrigger}
            />
          }
          popoverContent={
            <Popover>
              <>
                {startDate && (
                  <Text block margin={endDate ? 'no no xxSmall no' : 'no'}>
                    <b>{translate('common.startDate')}</b>: {moment(startDate).format(dateFormat)}
                  </Text>
                )}
                {endDate && (
                  <Text block>
                    <b>{translate('common.endDate')}</b>:{' '}
                    {moment(endDate).format(isCityAlert ? dateTimeSecondsFormat : dateFormat)}
                  </Text>
                )}
              </>
            </Popover>
          }
          size="medium"
        />
      )
    );
  };

  return (
    <>
      <TableRow height={TABLE_ROW_HEIGHT_LARGEST} onClick={handleRowClick} isHighlighted={selectedFeature?.id === id}>
        <TableCell width={locationAndCityAlertsTableCellsWidth[0]}>
          <Text>
            {moment(createdOn).format(dateFormat)}
            {lastFlagged && (
              <Text block size="small">
                {moment(lastFlagged).format(dateTimeFormat)}
              </Text>
            )}
            {createdBy &&
              (createdBy.includes('@') ? (
                <>
                  <Text block size="small">
                    {createdBy.split('@')[0].toLowerCase()}
                  </Text>
                  <Text block size="small">
                    @{createdBy.split('@')[1].toLowerCase()}
                  </Text>
                </>
              ) : (
                <Text block size="small">
                  {createdBy.toLowerCase()}
                </Text>
              ))}
          </Text>
        </TableCell>
        <TableCell width={locationAndCityAlertsTableCellsWidth[1]}>
          {alertName}

          {(customerName || locationName) && (
            <PopoverWrapper
              triggerButton={
                <IconButtonIcon
                  icon="info"
                  color="black"
                  size="medium"
                  margin="no no no xSmall"
                  className={noScrollTrigger}
                />
              }
              popoverContent={
                <Popover>
                  <>
                    {customerName && (
                      <Text block margin={locationName ? 'no no xxSmall no' : 'no'}>
                        <b>{translate('common.customerName')}</b>: {customerName}
                      </Text>
                    )}
                    {locationName && (
                      <Text block>
                        <b>{translate('common.locationName')}</b>: {locationName}
                      </Text>
                    )}
                  </>
                </Popover>
              }
              size="large"
            />
          )}
        </TableCell>
        <TableCell width={locationAndCityAlertsTableCellsWidth[2]}>{address || '-'}</TableCell>
        <TableCell width={locationAndCityAlertsTableCellsWidth[3]}>
          {routeNames?.length > 0 ? routeNames[0] : '-'}

          {routeNames?.length > 1 && (
            <Text
              margin="no no no xxSmall"
              block
              color="primary"
              whiteSpace="nowrap"
              className={noScrollTrigger}
              onClick={showRoutes}
            >
              <b>+{routeNames?.length - 1}</b>
            </Text>
          )}
        </TableCell>
        <TableCell width={locationAndCityAlertsTableCellsWidth[4]} vertical align="center">
          {expirationDate ? (
            <>
              <Text>
                {moment(expirationDate).format(dateFormat)}
                {getStartEndDatePopover(isCityAlert)}
              </Text>
              <Text size="small">{moment(expirationDate).format(timeFormat)}</Text>
            </>
          ) : endDate ? (
            <>
              <Text>
                {moment(endDate).format(dateFormat)}
                {getStartEndDatePopover(isCityAlert)}
              </Text>
              {isCityAlert && <Text size="small">{moment(endDate).format(timeFormat)}</Text>}
            </>
          ) : isCityAlert ? (
            <Text size="small">
              <i>{translate('vendors.cityAlerts.noExpiration')}</i>
              {getStartEndDatePopover(isCityAlert)}
            </Text>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell width={locationAndCityAlertsTableCellsWidth[5]}>
          {vehicleName || driverName ? (
            <>
              {vehicleName}
              {vehicleName && driverName && ' / '}
              {driverName}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell width={locationAndCityAlertsTableCellsWidth[6]}>
          {translate(isActive ? 'common.active' : 'common.inactive')}
        </TableCell>
        <TableCell align="right" width={locationAndCityAlertsTableCellsWidth[7]}>
          {!!imageCount && (
            <TableActionButton onClick={() => openAlertImageModal(id)} className={noScrollTrigger}>
              <ActionButtonTooltip
                icon="image"
                tooltip={imageCount > 1 ? 'viewImages' : 'viewImage'}
                content={imageCount > 0 ? imageCount : undefined}
              />
            </TableActionButton>
          )}
          <TableActionButton onClick={editAlert} className={noScrollTrigger}>
            <ActionButtonTooltip icon="edit" tooltip="edit" />
          </TableActionButton>
        </TableCell>
      </TableRow>

      {isImageModalOpen && <RouteImagesModal images={alertImages} closeModal={() => setIsImageModalOpen(false)} />}

      {isEditAlertModalOpen && (
        <LocationAndCityAlertsEditModal
          alertName={alertName}
          initialValues={{
            address,
            alertTypeId,
            cityAlertTypeId,
            customerName,
            description,
            endDate: expirationDate
              ? moment(expirationDate).format(dateFormat)
              : endDate && moment(endDate).format(dateFormat),
            expirationDate,
            isActive,
            latitude: getLatLng(latitude),
            locationName,
            longitude: getLatLng(longitude),
            startDate: startDate && moment(startDate).format(dateFormat),
          }}
          closeModal={() => setIsEditAlertModalOpen(false)}
          onSubmit={onSubmit}
          isCityAlert={isCityAlert}
        />
      )}
    </>
  );
};

export default LocationAndCityAlertsTableRow;
