import styled, { css } from 'styled-components';

import { Icon } from 'src/core/components';
import { sizeMapper } from 'src/utils/styles';
import { theme } from '../../../core/styles';

interface Props {
  isOnRightSide?: boolean;
  margin?: string;
  width?: string;
}

export const RouteMapFiltersWrapper = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 250px;
  height: 100%;

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  ${props =>
    props.isOnRightSide &&
    css`
      left: auto;
      right: 0;
    `}

  padding: 20px;
  overflow-y: auto;
  background-color: ${theme.brandWhite};
  box-shadow: 13px 4px 14px rgba(0, 0, 0, 0.07);
`;

export const RouteMapFiltersIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const RouteMapFiltersTitle = styled.div<Props>`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin: ${props => sizeMapper(props.margin, 'xxSmall no no no')};
`;

export const RouteMapFiltersClose = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
`;

export const RouteMapFiltersCloseIcon = styled(Icon).attrs({ icon: 'close' })`
  width: 10px;
  height: 10px;
  color: ${props => props.theme.grayDark};

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const RouteMapFiltersSectionTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-top: 14px;
`;

export const RouteMapFiltersSectionWording = styled.div`
  font-size: 12px;
  font-style: italic;
  color: grey;
  margin-top: 10px;
`;

export const RouteMapFiltersSection = styled.div``;
