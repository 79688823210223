import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Button, Grid, GridColumn, PanelSection } from '../../../core/components/styled';
import { DATE_RANGE_PICKER_TODAY_7_30_FUTURE } from 'src/core/constants/weekdays';
import { DateRangePicker, Dropdown, TypedField } from '../../../core/components';
import { ROUTE_ASSIGNMENT_OPTIONS, STATUS_OPTIONS } from 'src/customers/constants';
import { StreetJobsSearchFormData } from 'src/customers/interfaces/Streets';
// uncomment this when street segments are visible on map
// import { TYPE_OPTIONS, ROUTE_ASSIGNMENT_OPTIONS, STATUS_OPTIONS } from 'src/customers/constants';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';

interface PropsWithoutReduxForm {}

type Props = PropsWithoutReduxForm & InjectedFormProps<StreetJobsSearchFormData, PropsWithoutReduxForm>;

export const STREET_JOBS_SEARCH_FORM = 'StreetJobsSearchForm';

const StreetJobsSearchForm: React.FC<Props> = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <PanelSection padding="small">
        <Grid multiLine>
          <GridColumn size="3/12">
            <TypedField
              name="dateRange"
              component={DateRangePicker}
              props={{
                label: translate('customers.streets.dateRange'),
                margin: 'no',
                hasMarginLeft: 'normal',
                options: DATE_RANGE_PICKER_TODAY_7_30_FUTURE,
              }}
            />
          </GridColumn>

          {/* uncomment this when street segments are visible on map */}
          {/* <GridColumn size="2/12">
            <TypedField
              name="type"
              component={Dropdown}
              props={{
                label: translate('customers.streets.type'),
                options: TYPE_OPTIONS,
                margin: 'no',
              }}
            />
          </GridColumn> */}
          <GridColumn size="3/12">
            <TypedField
              name="assigned"
              component={Dropdown}
              props={{
                label: translate('customers.streets.routeAssignment'),
                options: ROUTE_ASSIGNMENT_OPTIONS,
                margin: 'no',
              }}
            />
          </GridColumn>
          <GridColumn size="2/12">
            <TypedField
              name="active"
              component={Dropdown}
              props={{
                label: translate('customers.streets.status'),
                options: STATUS_OPTIONS,
                margin: 'no',
              }}
            />
          </GridColumn>

          <GridColumn size="4/12" align="right">
            <Button type="submit" color="primary" margin="small no no no">
              {translate('common.search')}
            </Button>
          </GridColumn>
        </Grid>
      </PanelSection>
    </form>
  );
};

export default connect()(
  reduxForm<StreetJobsSearchFormData, PropsWithoutReduxForm>({
    form: STREET_JOBS_SEARCH_FORM,
    onSubmitFail: focusFirstInvalidField,
  })(StreetJobsSearchForm),
);
