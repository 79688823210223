import { InjectedFormProps, isPristine, submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { AppState } from '../../../store';
import { convertHexToIconColor, rgbaToHex } from 'src/utils/colorUtils';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { Modal } from '../../../core/components';
import { ModalProps } from '../../interfaces/ModalProps';
import { ModalSection } from '../../../core/components/styled';
import { saveStreetJobType } from 'src/vendors/ducks/StreetJobs';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { StreetJobTypeList } from 'src/vendors/interfaces/StreetJobTypes';
import StreetJobsForm, { STREET_JOBS_FORM } from '../forms/StreetJobsForm';
import translate from '../../../core/services/translate';

const streetJobsModalId = 'street-jobs-modal-id';

interface Props extends ModalProps, InjectedFormProps<any, ModalProps> {}

function parseErrorResponse(apiError: any, payload: any): string {
  if (!apiError || typeof apiError !== 'object') {
    return '';
  }

  const errorMessages: string[] = [];

  Object.entries(apiError).forEach(([key, messages]) => {
    if (key === 'status' || key === 'statusText') return;
    if (!Array.isArray(messages)) return;

    const match = key.match(/streetJobTypes\[(\d+)\]\.(\w+)/);
    if (match) {
      const [, index, field] = match;
      const payloadIndex = parseInt(index, 10);

      const jobName = payload?.streetJobTypes?.[payloadIndex]?.name || `Job #${payloadIndex + 1}`;

      messages.forEach(message => {
        errorMessages.push(`${jobName} - ${field}: ${message}`);
      });
    }
  });

  return errorMessages.join('\n');
}

function formatStreetJobTypes(streetJobTypes: any[]) {
  return streetJobTypes.map(job => {
    return {
      ...job,
      iconColor: job.iconColor && convertHexToIconColor(job.iconColor),
    };
  });
}

const StreetJobsModal: React.FC<Props> = ({ closeModal }) => {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const streetJobTypes = useSelector((state: AppState) => state.vendors.streetJobs.streetJobTypeList.streetJobTypes);
  const initialValues = { streetJobs: formatStreetJobTypes(streetJobTypes) };
  const formPristine = useSelector((state: AppState) => isPristine(STREET_JOBS_FORM)(state));
  const { isSaving, isLoadingList, isLoadingOptions } = useSelector((state: AppState) => state.vendors.streetJobs);
  const onClose = (pristine = formPristine) => {
    closeModal(pristine);
  };

  const onSubmit = async (formData: any) => {
    dispatch(submit(STREET_JOBS_FORM));

    const formattedData: StreetJobTypeList = {
      streetJobTypes: formData.streetJobs.map((job: any) => ({
        ...job,
        iconColor: job.iconColor ? rgbaToHex(job.iconColor) : null,
      })),
    };

    scrollToTopOfModal(streetJobsModalId);

    try {
      await saveStreetJobType(vendorId, formattedData)(dispatch);
      createSuccessNotification(translate('vendors.streetJobs.sucessSaving'));
      closeModal(true);
    } catch (error) {
      const errorMessage = parseErrorResponse(error, formattedData);

      if (errorMessage) {
        createErrorNotification(errorMessage);
      } else {
        createErrorNotification(translate('vendors.streetJobs.errorSaving'));
      }
    }
  };

  const isLoading = isSaving || isLoadingList || isLoadingOptions;

  return (
    <Modal
      size="xxLarge"
      headerPadding="medium no no no"
      padding="no no no no"
      title={translate('vendors.featureCodes.streetJobs')}
      onClose={() => onClose()}
      id={streetJobsModalId}
      overflow={isLoading ? 'hidden' : 'auto'}
    >
      <ModalSection overflow="visible" fluid noOutline flexGrow isLoading={isLoading}>
        <StreetJobsForm initialValues={initialValues} onSubmit={onSubmit} onClose={onClose} />
      </ModalSection>
    </Modal>
  );
};

export default StreetJobsModal;
