import { reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Button, Grid, GridColumn, ModalFixedFooter, PanelSection } from '../../../core/components/styled';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { DateRangeOptionValue } from 'src/core/components/DateRangePicker';
import { isRequired } from '../../../utils/services/validator';
import { loadAvailableRoutesForStreetJobsReassignment } from 'src/customers/ducks/streets';
import { SNOW_PLOW_ID, STREET_SWEEPER_ID } from 'src/fleet/constants';
import { TypedField, Dropdown, DateRangePicker } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from '../../../core/services/translate';

const STREET_JOBS_TRANSFER_FORM = 'streetJobsTransferForm';

export interface StreetJobsTransferFormValues {
  dateRange: DateRangeOptionValue;
  snowPlowTargetRouteId: string;
  stretSweeperTargetRouteId: string;
}

interface PropsWithoutReduxForm {
  hasSnowPlowSelectedStreetJobs: boolean;
  hasStreetSweeperSelectedStreetJobs: boolean;
  isStreetJobAssigned: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<StreetJobsTransferFormValues, PropsWithoutReduxForm>;

const StreetJobsTransferForm: React.FC<Props> = ({
  handleSubmit,
  hasSnowPlowSelectedStreetJobs,
  hasStreetSweeperSelectedStreetJobs,
  isStreetJobAssigned,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const {
    isLoadingAvailableRoutesForStreetJobsReassignment,
    routesForSnowPlowStreetJobsReassignment,
    routesForStreetSweeperStreetJobsReassignment,
  } = useSelector(state => state.customers.streets);

  const loadAvailableRoutesForReassignment = (_: unknown, value: DateRangeOptionValue) => {
    hasSnowPlowSelectedStreetJobs &&
      loadAvailableRoutesForStreetJobsReassignment(vendorId, SNOW_PLOW_ID, value.from, value.to)(dispatch);
    hasStreetSweeperSelectedStreetJobs &&
      loadAvailableRoutesForStreetJobsReassignment(vendorId, STREET_SWEEPER_ID, value.from, value.to)(dispatch);
  };

  const routesSnowPlowForReassignmentOptions = routesForSnowPlowStreetJobsReassignment?.searchedRoutes?.map(
    ({ name, routeCompositedId }) => ({
      label: name,
      value: routeCompositedId,
    }),
  );

  const routesStreetSweeperForReassignmentOptions = routesForStreetSweeperStreetJobsReassignment?.searchedRoutes?.map(
    ({ name, routeCompositedId }) => ({
      label: name,
      value: routeCompositedId,
    }),
  );

  return (
    <form onSubmit={handleSubmit}>
      <PanelSection
        padding="medium"
        vertical
        isLoading={isLoadingAvailableRoutesForStreetJobsReassignment}
        minHeight={380}
      >
        <Grid centered>
          <GridColumn size="6/12">
            <TypedField
              component={DateRangePicker}
              name="dateRange"
              onChange={loadAvailableRoutesForReassignment}
              props={{
                label: translate('customers.streets.dateRange'),
                hasMarginLeft: 'normal',
                margin: 'no',
              }}
              validate={[isRequired]}
            />
          </GridColumn>
        </Grid>

        <Grid centered>
          {hasSnowPlowSelectedStreetJobs && (
            <GridColumn size="6/12">
              <TypedField
                component={Dropdown}
                name="snowPlowTargetRouteId"
                props={{
                  label: translate('customers.streets.snowPlowTargetRoutes'),
                  disabled: !routesForSnowPlowStreetJobsReassignment?.searchedRoutes?.length,
                  options: routesSnowPlowForReassignmentOptions,
                  margin: 'small no no no',
                }}
                validate={isRequired}
              />
            </GridColumn>
          )}

          {hasStreetSweeperSelectedStreetJobs && (
            <GridColumn size="6/12">
              <TypedField
                component={Dropdown}
                name="stretSweeperTargetRouteId"
                props={{
                  label: translate('customers.streets.streetSweeperTargetRoutes'),
                  disabled: !routesForStreetSweeperStreetJobsReassignment?.searchedRoutes?.length,
                  options: routesStreetSweeperForReassignmentOptions,
                  margin: 'small no no no',
                }}
                validate={isRequired}
              />
            </GridColumn>
          )}
        </Grid>
      </PanelSection>

      <ModalFixedFooter isShadowed>
        <Button type="submit" color="primary" margin="no">
          {translate(isStreetJobAssigned ? 'common.transfer' : 'common.add')}
        </Button>
      </ModalFixedFooter>
    </form>
  );
};

export default reduxForm<StreetJobsTransferFormValues, PropsWithoutReduxForm>({
  form: STREET_JOBS_TRANSFER_FORM,
})(StreetJobsTransferForm);
