import { connect } from 'react-redux';
import { get, size } from 'lodash-es';
import { MouseEvent, PureComponent } from 'react';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router';
import humps from 'humps';

import { PermissionGuard } from 'src/account/components';
import { CUSTOMERS_CREATE, CUSTOMERS_DELETE } from 'src/account/constants';
import { hasPermissionSelector } from 'src/account/ducks';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { BUSINESS } from 'src/common/constants';
import { SEARCH_BY_ACCOUNT_NUMBER, SEARCH_BY_NAME_OR_ADDRESS } from 'src/common/constants/searchTypes';
import { SearchType } from 'src/common/interfaces/SearchType';
import { DuckFunction } from 'src/contracts/ducks';
import { MoreButton, Pagination, Table } from 'src/core/components';
import { MoreButtonItem } from 'src/core/components/MoreButton';
import { Button, IconButtonIcon, Message, Panel, PanelSection, PanelSectionGroup } from 'src/core/components/styled';
import { SortOrder } from 'src/core/components/Table';
import { LIMIT_PER_PAGE } from 'src/core/constants/pagination';
import confirm from 'src/core/services/confirm';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import translate from 'src/core/services/translate';
import {
  deleteCustomer,
  loadCustomersSimplified,
  resetCustomer,
  resetCustomerLocations,
  resetCustomers,
  saveCustomer,
  setCurrentCustomersPageUrl,
} from 'src/customers/ducks';
import { routeRecommendationsFeatureSelector } from 'src/customers/ducks/routeRecommendations';
import { Customer, Feature } from 'src/customers/interfaces/Customers';
import { checkCustomerHasVendorContainers } from 'src/customers/services/customer';
import { checkCustomerHasScheduledDailyRoutes } from 'src/routes/services/routeTemplate';
import { AppState } from 'src/store';
import createTranslationKey from 'src/utils/services/createTranslationKey';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { open311ConfigurationSelector, billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { CustomersTableRow } from '.';
import { esriImportFeatureSelector } from '../../ducks/customers';
import { CustomersForm } from '../forms';
import { CustomerDetailsEditorFormValues } from '../forms/CustomerDetailsEditorForm';
import {
  CustomerDetailsEditorModal,
  CustomerProximitySearchModalResolver,
  DeleteCustomerLocationOrServiceModal,
} from '../modals';
import CustomerAccountStatusUploaderModal from '../modals/CustomerAccountStatusUploaderModal';
import EsriUploadModal from '../modals/esriImportModal/EsriImportModal';
import TransferContainerModalResolver from '../modals/TransferContainerModalResolver';
import { CustomerImportModalResolver } from '../modals/customerImportModal/CustomerImportModalResolver';
import { getFutureAccountStatus } from 'src/customers/services/futureAccountStatusValidator';
import { FutureAccountStatus } from 'src/customers/interfaces/Services';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { customerDetailsEditorModalId } from 'src/customers/components/modals/CustomerDetailsEditorModal';
import { RateManagerImportModalResolver } from 'src/finance/components/modals/RateManagerImportModalResolver';

interface LocationState {
  customerTypeId: number;
  customerStatusTypeIds: string;
  page: number;
  sortedBy: string;
  sortOrder: SortOrder;
  accountNumber: string;
  showUploadStatus: boolean;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  customers?: Customer[];
  deleteCustomer: DuckFunction<typeof deleteCustomer>;
  esriImportFeature: Feature;
  hasDeletePermission: boolean;
  isBillingFeatureActive: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  loadCustomersSimplified: DuckFunction<typeof loadCustomersSimplified>;
  open311ConfigurationEnabled: Feature;
  push: (url: string) => void;
  recommendationsFeature: Feature;
  reset: any;
  resetCustomer: () => void;
  resetCustomerLocations: () => void;
  resetCustomers: () => void;
  saveCustomer: DuckFunction<typeof saveCustomer>;
  setCurrentCustomersPageUrl: (currentCustomersPageUrl: string) => void;
  showUploadStatus?: boolean;
  total?: number;
  vendorId: number;
}

interface State {
  esriImportModalIsVisible: boolean;
  isCustomerDetailsModalOpen: boolean;
  isCustomerProximitySearchModalOpen: boolean;
  isDeleteCustomerModalOpen: boolean;
  isRateImportModalVisible?: boolean;
  isTransferContainersModalOpen: boolean;
  moveContainersToFacilityId?: number;
  selectedCustomerId: number;
  uploadCustomerAccountStatusModalIsVisible?: boolean;
  uploadCustomersModalIsVisible?: boolean;
}

class CustomersPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      esriImportModalIsVisible: false,
      isCustomerDetailsModalOpen: false,
      isCustomerProximitySearchModalOpen: false,
      isDeleteCustomerModalOpen: false,
      isRateImportModalVisible: false,
      isTransferContainersModalOpen: false,
      moveContainersToFacilityId: 0,
      selectedCustomerId: 0,
      uploadCustomerAccountStatusModalIsVisible: false,
      uploadCustomersModalIsVisible: this.props.showUploadStatus || false,
    };

    const { resetCustomer, resetCustomerLocations } = this.props;
    resetCustomer();
    resetCustomerLocations();
  }

  componentDidUpdate = (prevProps: Props) => {
    const { location, vendorId, loadCustomersSimplified } = this.props;
    if (prevProps.location.search !== location.search) {
      const {
        customerTypeId,
        customerStatusTypeIds,
        isOverdue,
        page,
        sortedBy = 'name',
        sortOrder = 'asc',
        accountNumber,
      } = getQueryParams(location.search);
      let { searchTerm } = getQueryParams(location.search);
      let searchType: SearchType = searchTerm ? SEARCH_BY_NAME_OR_ADDRESS : SEARCH_BY_ACCOUNT_NUMBER;
      loadCustomersSimplified({
        vendorId,
        searchTerm: searchTerm || accountNumber,
        customerTypeIds: customerTypeId,
        customerStatusTypeIds: customerStatusTypeIds,
        isPaymentOverdue: isOverdue,
        page,
        limit: LIMIT_PER_PAGE,
        sortedBy,
        sortOrder,
        searchType,
      });
    }
  };

  componentWillUnmount() {
    this.props.resetCustomers();
  }

  onSortOrderChange = (sortedBy: string, sortOrder: SortOrder) => {
    const { location, push } = this.props;
    push(createUrl(location.pathname, location.search, { sortedBy, sortOrder }));
  };

  onCustomerEditorFormSubmit = async (data: CustomerDetailsEditorFormValues) => {
    const { saveCustomer, vendorId } = this.props;

    const futureAccountStatus = get(data, 'futureAccountStatus', {} as FutureAccountStatus);
    const redirectToPage = true;
    const customer = {
      ...data,
      vendorId,
      futureAccountStatus: getFutureAccountStatus(futureAccountStatus),
    };

    scrollToTopOfModal(customerDetailsEditorModalId);

    await saveCustomer(customer, redirectToPage)
      .then(() => {
        this.props.reset('customerLocationEditor');
        this.closeCustomerDetailsEditorModal();
        createSuccessNotification(translate('customers.alertMessages.customerSaved'));
      })
      .catch(e => {
        const errorMessageCode =
          e && e.response && e.response.data && e.response.data.code && humps.camelize(e.response.data.code);

        createErrorNotification(
          errorMessageCode
            ? translate(createTranslationKey(errorMessageCode, 'customers.alertMessages'))
            : translate('customers.alertMessages.customerSaveError'),
        );
      });
  };

  onCancelSaveCustomerDetails = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.props.reset('customerLocationEditor');
    this.closeCustomerDetailsEditorModal();
  };

  onCancelDeleteCustomer = () => {
    this.setState({ isDeleteCustomerModalOpen: false, selectedCustomerId: 0 });
  };

  onDeleteCustomer = (shouldRecreateRoutes: boolean) => {
    const { selectedCustomerId, moveContainersToFacilityId } = this.state;
    this.setState({ isDeleteCustomerModalOpen: false, selectedCustomerId: 0 });

    this.props
      .deleteCustomer(selectedCustomerId, shouldRecreateRoutes, moveContainersToFacilityId)
      .then(() => {
        createSuccessNotification(`${translate('customers.alertMessages.customerDeleted')}`);
      })
      .catch(() => {
        createErrorNotification(`${translate('customers.alertMessages.customerDeleteError')}`);
      });
  };

  onTransferContainer = (values: any) => {
    this.setState({
      isTransferContainersModalOpen: false,
      isDeleteCustomerModalOpen: true,
      moveContainersToFacilityId: values.facilityTypeId,
    });
  };

  openCustomerDetailsModal = () => {
    this.setState({ isCustomerDetailsModalOpen: true });
  };

  closeCustomerDetailsEditorModal = () => {
    this.setState({ isCustomerDetailsModalOpen: false });
  };

  openDeleteCustomerModal = async (customerId: number, event: MouseEvent) => {
    event.stopPropagation();
    const hasContainers = await checkCustomerHasVendorContainers(customerId);
    if (hasContainers) this.setState({ isTransferContainersModalOpen: true, selectedCustomerId: customerId });
    else this.setState({ isDeleteCustomerModalOpen: true, selectedCustomerId: customerId });
  };

  openCustomerProximitySearchModal = (event: MouseEvent) => {
    event.stopPropagation();
    this.setState({ isCustomerProximitySearchModalOpen: true });
  };

  closeCustomerProximitySearchModal = () => {
    this.setState({ isCustomerProximitySearchModalOpen: false });
  };

  closeTransferContainersModal = () => {
    this.setState({ isTransferContainersModalOpen: false, moveContainersToFacilityId: 0 });
  };

  showCustomerAccountStatusUploadModal = (visible: boolean = true) => {
    this.setState({ uploadCustomerAccountStatusModalIsVisible: visible });
  };

  showCustomersUploadModal = (visible: boolean = true) => {
    this.setState({ uploadCustomersModalIsVisible: visible });
  };

  showEsriImportModal = (visible: boolean = true) => {
    this.setState({ esriImportModalIsVisible: visible });
  };

  closeRateImportModalVisible = () => {
    this.setState({ isRateImportModalVisible: false });
  };

  showRateImportModalVisible = (visible: boolean = true) => {
    this.setState({ isRateImportModalVisible: visible });
  };

  render() {
    const {
      customers,
      esriImportFeature,
      hasDeletePermission,
      isBillingFeatureActive,
      isDeleting,
      isLoading,
      location,
      open311ConfigurationEnabled,
      push,

      setCurrentCustomersPageUrl,
      total,
      vendorId,
    } = this.props;

    const {
      esriImportModalIsVisible,
      isCustomerDetailsModalOpen,
      isCustomerProximitySearchModalOpen,
      isDeleteCustomerModalOpen,
      isRateImportModalVisible,
      isTransferContainersModalOpen,
      selectedCustomerId,
      uploadCustomerAccountStatusModalIsVisible,
      uploadCustomersModalIsVisible,
    } = this.state;

    const customerTableCells = [
      {
        name: 'name',
        label: translate('customers.customerName'),
        width: '30%',
        sortable: true,
      },
      {
        name: 'address',
        label: translate('common.address'),
        width: '60%',
      },
      {
        name: 'options',
        label: hasDeletePermission && translate('common.options'),
        width: '10%',
        align: 'right',
      },
    ];

    const moreButtonItems: MoreButtonItem[] = [
      {
        text: translate('customers.customerUpload.buttonTitle'),
        handler: () => this.showCustomersUploadModal(),
      },
      {
        id: 'customer-account-status-upload-button',
        text: translate('customers.accountStatusUpload.buttonTitle'),
        handler: () => this.showCustomerAccountStatusUploadModal(),
      },
    ];

    isBillingFeatureActive &&
      moreButtonItems.splice(1, 0, {
        id: 'rate-import-button',
        text: translate('finance.rateManager.rateImport'),
        handler: () => this.showRateImportModalVisible(),
      });

    if (esriImportFeature?.enabled) {
      moreButtonItems.push({
        text: translate('customers.esriImport'),
        handler: () => this.showEsriImportModal(),
      });
    }
    /*
    REMOVED AS PART OF RVP-11283 - Decommission Route Recommendations Page
    if (recommendationsFeature?.enabled && hasPermission(CUSTOMERS_LOCATION_DETAILS_EDIT_SERVICE)) {
      moreButtonItems.push({
        text: translate('customers.routeRecommendations.title'),
        isLink: true,
        to: '/customers/route-recommendations',
      });
    }
    */
    if (open311ConfigurationEnabled?.enabled) {
      moreButtonItems.push({
        text: translate('customers.adhocServiceHistory'),
        isLink: true,
        to: '/customers/adhoc-service-history',
      });
    }
    // !we should refactor these pushes in one object instead of three - Monty

    moreButtonItems.push({
      id: 'customer-proximity-search-button',
      text: translate('customers.customerProximitySearch'),
      handler: this.openCustomerProximitySearchModal,
      startAdornment: <IconButtonIcon icon="searchVehicle" margin="no xSmall no no" />,
    });
    if (isBillingFeatureActive) {
      moreButtonItems.push({
        id: 'rate-builder-button',
        text: translate('customers.rateBuilder.rateBuilder'),
        isLink: true,
        to: '/customers/rate-builder',
      });
    }

    const currentCustomersPageUrl = window.location.pathname + window.location.search;
    setCurrentCustomersPageUrl(currentCustomersPageUrl);

    const { isOverdue } = getQueryParams(location.search);

    const filteredCustomers = isOverdue
      ? customers?.filter(cust => (!!isOverdue ? !!cust.isPaymentOverdue : true))
      : customers;

    return (
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer>
              <PageTitle>{translate('customers.customers')}</PageTitle>
            </PageTitleContainer>
          </PageDetails>
          <PageActions align="right">
            {!!moreButtonItems.length && <MoreButton margin="no small no no" items={moreButtonItems} />}

            <PermissionGuard permission={CUSTOMERS_CREATE}>
              <Button onClick={this.openCustomerDetailsModal} color="primary" id="create-customer-button">
                {translate('customers.createCustomer')}
              </Button>
            </PermissionGuard>
          </PageActions>
        </PageHeader>
        <Panel>
          <PanelSectionGroup isLoading={isLoading || isDeleting}>
            <CustomersForm />
            <PanelSection>
              {!!size(customers) && (
                <Table
                  cells={customerTableCells}
                  rows={filteredCustomers}
                  rowComponent={CustomersTableRow}
                  rowProps={{ deleteCustomer: this.openDeleteCustomerModal, push, location: this.props.location }}
                  sort={this.onSortOrderChange}
                  withClickableRows
                />
              )}
            </PanelSection>
            {!size(filteredCustomers) && <Message padding="sMedium">{translate('customers.noCustomers')}</Message>}

            {!!total && <Pagination totalResults={total} />}
          </PanelSectionGroup>
        </Panel>

        {!!isDeleteCustomerModalOpen && (
          <DeleteCustomerLocationOrServiceModal
            checkMethod={checkCustomerHasScheduledDailyRoutes}
            modalTitle={translate('customers.alertMessages.confirmDeleteCustomer')}
            onCancel={this.onCancelDeleteCustomer}
            onDelete={this.onDeleteCustomer}
            selectedId={selectedCustomerId}
            vendorId={vendorId}
          />
        )}

        {!!isCustomerDetailsModalOpen && (
          <CustomerDetailsEditorModal
            onCancel={this.onCancelSaveCustomerDetails}
            onSaveCustomer={this.onCustomerEditorFormSubmit}
            allowedCustomerTypeId={BUSINESS}
          />
        )}

        {uploadCustomersModalIsVisible && (
          <CustomerImportModalResolver closeModal={() => this.showCustomersUploadModal(false)} />
        )}

        {esriImportModalIsVisible && (
          <EsriUploadModal vendorId={vendorId} onClose={() => this.showEsriImportModal(false)} />
        )}

        {uploadCustomerAccountStatusModalIsVisible && (
          <CustomerAccountStatusUploaderModal
            vendorId={vendorId}
            onClose={() => this.showCustomerAccountStatusUploadModal(false)}
          />
        )}

        {!!isCustomerProximitySearchModalOpen && (
          <CustomerProximitySearchModalResolver onCancel={this.closeCustomerProximitySearchModal} />
        )}

        {!!isTransferContainersModalOpen && (
          <TransferContainerModalResolver
            title={translate('containers.whereTransferContainers', {
              containerPlace: translate('common.customer'),
            })}
            closeModal={this.closeTransferContainersModal}
            handleContainerTransfer={this.onTransferContainer}
          />
        )}

        {isRateImportModalVisible && (
          <RateManagerImportModalResolver isRateImport closeModal={this.closeRateImportModalVisible} />
        )}
      </PageContent>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customers: state.customers.customers.customers,
  esriImportFeature: esriImportFeatureSelector(state),
  hasDeletePermission: hasPermissionSelector(state.account.permissions, CUSTOMERS_DELETE),
  isBillingFeatureActive: !!billingFeatureStatusSelector(state.vendors.features.features),
  isDeleting: state.customers.customers.isDeleting,
  isLoading: state.customers.customers.isLoading,
  open311ConfigurationEnabled: open311ConfigurationSelector(state),
  recommendationsFeature: routeRecommendationsFeatureSelector(state),
  total: state.customers.customers.total,
  vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  deleteCustomer,
  loadCustomersSimplified,
  push,
  reset,
  resetCustomer,
  resetCustomerLocations,
  resetCustomers,
  saveCustomer,
  setCurrentCustomersPageUrl,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomersPage));
