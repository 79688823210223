import styled from 'styled-components';

import { Popover } from 'src/core/components/styled';

export const StreetJobsAddressWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 30%;
  width: 40%;
  z-index: 6;

  input {
    padding: 5px 10px;
  }
`;

export const ServiceStatusWrapper = styled.div`
  position: relative;
  z-index: 12;

  ${Popover} {
    left: 0;
    right: auto;
    transform: none;

    &:before,
    &:after {
      left: 20px;
    }
  }
`;
