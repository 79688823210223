import { change, getFormValues } from 'redux-form';
import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { ACTIVE_ID, ASSIGNED_ID, INACTIVE_ID, ROUTE_UNASSIGNED_ID, UNASSIGNED_ID } from 'src/customers/constants';
import { Coordinates } from 'src/common/components/map/Coordinates';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { dateFormat, newDateFormat } from 'src/utils/services/validator';
import { DropdownOption } from 'src/core/components/Dropdown';
import { getLatLng } from 'src/fleet/components/pages/locationAndCityAlertsSections/utils';
import { loadAvailableRoutesForStreetJobsReassignment } from 'src/customers/services/streets';
import { loadStreetJobs, saveStreetJob } from 'src/customers/ducks/streets';
import { LocationData } from 'src/customers/interfaces/LocationServiceTypes';
import { ModalSection } from 'src/core/components/styled';
import {
  RoutesForStreetJobsReassignment,
  StreetJobRequest,
  StreetJobs,
  StreetJobsSearchFormData,
} from 'src/customers/interfaces/Streets';
import { saveGeocode } from 'src/customers/services/locations';
import { STREET_JOBS_ADDRESS_FORM } from 'src/customers/components/forms/StreetJobsAddressForm';
import { STREET_JOBS_SEARCH_FORM } from '../../forms/StreetJobsSearchForm';
import { TODAY_FORMATTED, SEVEN_DAYS_AFTER_TODAY } from 'src/core/constants';
import { useSelector } from 'src/core/hooks/useSelector';
import AddJobLocationMapPicker from './AddJobLocationMapPicker';
import AddStreetJobForm, { ADD_STREET_JOB_FORM, AddStreetJobFormValues } from '../../forms/AddStreetJobForm';
import Modal, { ModalProps } from 'src/core/components/Modal';
import translate from 'src/core/services/translate';

const addStreetJobModalId = 'add-street-job-modal';
const INTERVAL_TYPE_ONE_TIME = '1';

export interface Props extends ModalProps {
  date?: Date | string;
  editJobData?: StreetJobs;
  isSourceRouteDetails?: boolean;
  onSavedSuccess?: (id?: number) => void;
  routeId?: number;
  routeTemplateId?: number;
  vehicleTypeId?: number;
}

const AddStreetJobModal: FC<Props> = ({
  date,
  editJobData,
  isSourceRouteDetails,
  onClose,
  routeId,
  routeTemplateId,
  vehicleTypeId,
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const { streetJobOptions, isSavingStreetJob, streetJob } = useSelector(state => state.customers.streets);

  const [isFetchingAddress, setIsFetchingAddress] = useState(false);
  const [isLocationPickerChanged, setIsLocationPickerChanged] = useState(false);
  const [currentPinnedAddress, setCurrentPinnedAddress] = useState<LocationData | undefined>();
  const [isPinOnMapVisible, setIsPinOnMapVisible] = useState<boolean>(true);
  const [routesOptions, setRoutesOptions] = useState<DropdownOption[]>([]);

  const formValues = useSelector(state => getFormValues(STREET_JOBS_SEARCH_FORM)(state)) as StreetJobsSearchFormData;
  const isAssigned = formValues?.assigned === ASSIGNED_ID;
  const isUnassigned = formValues?.assigned === UNASSIGNED_ID;
  const isActive = formValues?.active === ACTIVE_ID;
  const isInactive = formValues?.active === INACTIVE_ID;
  const streetJobsSearchFormData = {
    startDate: formValues?.dateRange?.from || TODAY_FORMATTED,
    endDate: formValues?.dateRange?.to || SEVEN_DAYS_AFTER_TODAY,
    assigned: isAssigned ? true : isUnassigned ? false : undefined,
    active: isActive ? true : isInactive ? false : undefined,
  };

  const updateLatitudeAndLongitude = useCallback(
    (latitude?: number, longitude?: number) => {
      dispatch(change(ADD_STREET_JOB_FORM, 'latitude', latitude ? getLatLng(latitude) : undefined));
      dispatch(change(ADD_STREET_JOB_FORM, 'longitude', longitude ? getLatLng(longitude) : undefined));
    },
    [dispatch],
  );

  const updatePinAddress = useCallback(
    ({ lat, lng }: Coordinates) => {
      setIsFetchingAddress(true);
      setIsLocationPickerChanged(false);
      saveGeocode(lat, lng)
        .then(loc => {
          const location = {
            ...loc,
            latitude: lat,
            longitude: lng,
          };

          const pinnedAddress = {
            ...location,
            formattedAddress: location.line1,
            latitude: location.latitude,
            longitude: location.longitude,
          };

          dispatch(change(ADD_STREET_JOB_FORM, 'pinnedAddress', pinnedAddress));

          setCurrentPinnedAddress(pinnedAddress);

          updateLatitudeAndLongitude(location.latitude, location.longitude);
          setIsFetchingAddress(false);
        })
        .catch(err => {
          setIsFetchingAddress(false);
        });
    },
    [dispatch, updateLatitudeAndLongitude],
  );

  const isEditMode = !!editJobData;

  const handleSaveStreetJob = useCallback(
    (values: any) => {
      const payload: StreetJobRequest = {
        id: isEditMode ? values.id : null,
        vendorId: vendorId,
        latitude: currentPinnedAddress?.latitude || 0,
        longitude: currentPinnedAddress?.longitude || 0,
        line1: currentPinnedAddress?.line1 || null,
        line2: currentPinnedAddress?.line2 || null,
        city: currentPinnedAddress?.city || null,
        country: currentPinnedAddress?.country || null,
        state: currentPinnedAddress?.state || null,
        street: currentPinnedAddress?.street || null,
        streetNumber: currentPinnedAddress?.streetNumber || null,
        zip: currentPinnedAddress?.zip || null,
        vehicleTypeId: values.vehicleTypeId,
        jobPriorityId: values.jobPriorityId ? parseInt(values.jobPriorityId) : null,
        streetJobTypeId: parseInt(values.streetJobTypeId),
        intervalType: parseInt(values.intervalType),
        routeCompositedId: values.routeCompositedId === ROUTE_UNASSIGNED_ID ? null : values.routeCompositedId,
        startDate: values.startDate ? moment(values.startDate).format(newDateFormat) : null,
        endDate: values.endDate ? moment(values.endDate).format(newDateFormat) : null,
        active: values.active,
      };

      saveStreetJob(
        vendorId,
        payload,
        isEditMode,
      )(dispatch)
        .then(() => {
          createSuccessNotification(translate('customers.streets.alertMessages.streetJobSavedSuccess'));
          if (!isSourceRouteDetails) {
            loadStreetJobs(vendorId, streetJobsSearchFormData)(dispatch);
            dispatch(change(STREET_JOBS_ADDRESS_FORM, 'address', undefined));
          }
          onClose && onClose(false);
        })
        .catch(() => {
          createErrorNotification(translate('customers.streets.alertMessages.streetJobAddedError'));
        });
    },
    [vendorId, currentPinnedAddress, onClose, dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const initialValues: AddStreetJobFormValues = isEditMode
    ? {
        id: streetJob?.id || null,
        pinnedAddress: {
          latitude: streetJob?.latitude || 0,
          longitude: streetJob?.longitude || 0,
          line1: streetJob?.line1 || null,
          line2: streetJob?.line2 || null,
          city: streetJob?.city || null,
          country: streetJob?.country || null,
          state: streetJob?.state || null,
          street: streetJob?.street || null,
          streetNumber: streetJob?.streetNumber || null,
          zip: streetJob?.zip || null,
          formattedAddress:
            streetJob?.line1 ||
            `${streetJob?.streetNumber} ${streetJob?.street} ${streetJob?.city} ${streetJob?.state}` ||
            null,
        },
        vehicleTypeId: streetJob?.vehicleType?.id || null,
        jobPriorityId: streetJob?.jobPriorityId || null,
        streetJobTypeId: streetJob?.streetJobType?.id || null,
        intervalType: streetJob?.jobIntervalType?.id.toString() || null,
        routeCompositedId: streetJob?.routeCompositedId || ROUTE_UNASSIGNED_ID,
        startDate: streetJob?.startDate ? moment(streetJob.startDate).format(dateFormat) : null,
        endDate: streetJob?.endDate ? moment(streetJob.endDate).format(dateFormat) : null,
        active: streetJob?.active || false,
      }
    : {
        active: true,
        intervalType: INTERVAL_TYPE_ONE_TIME,
      };

  const initialValuesFromRouteDetails = isSourceRouteDetails && {
    active: true,
    vehicleTypeId,
    startDate: moment(date).format(dateFormat),
    routeCompositedId: routeId
      ? (routesOptions as any[])?.find(routesOption => routesOption.routeId === routeId)?.value
      : routeTemplateId
      ? (routesOptions as any[])?.find(routesOption => routesOption.routeTemplateId === routeTemplateId)?.value
      : undefined,
    intervalType: INTERVAL_TYPE_ONE_TIME,
  };

  const onLocationPickerChanged = (
    pinnedAddress?: any | LocationData,
    locationId?: number,
    isPinOnMapVisible?: boolean,
  ) => {
    setCurrentPinnedAddress(pinnedAddress);
    setIsLocationPickerChanged(true);
    setIsPinOnMapVisible(isPinOnMapVisible !== undefined ? isPinOnMapVisible : true);

    updateLatitudeAndLongitude(getLatLng(pinnedAddress?.latitude), getLatLng(pinnedAddress?.longitude));
  };

  const loadAvailableRoutes = async (
    vendorId: number,
    vehicleTypeId: number,
    startDate: Date | string,
    endDate?: Date | string,
    intervalType?: number,
    routeId?: number,
    routeTemplateId?: number,
  ) => {
    const response: RoutesForStreetJobsReassignment = await loadAvailableRoutesForStreetJobsReassignment(
      vendorId,
      vehicleTypeId,
      startDate,
      endDate,
      intervalType,
      routeId,
      routeTemplateId,
    );

    const unassignedRoute = {
      value: ROUTE_UNASSIGNED_ID,
      label: <i>{translate('customers.streets.unassigned')}</i>,
    };

    setRoutesOptions(
      ([
        unassignedRoute,
        ...response.searchedRoutes.map(searchedRoute => {
          return {
            value: searchedRoute.routeCompositedId,
            label: searchedRoute.name,
            routeId: searchedRoute.routeId,
            routeTemplateId: searchedRoute.routeTemplateId,
          };
        }),
      ] as any[]) || [],
    );
  };

  return (
    <Modal
      title={isEditMode ? translate('routes.editJob') : translate('routes.addJob')}
      size="mediumLarge"
      padding="medium no no"
      onClose={onClose}
      id={addStreetJobModalId}
      isLoading={isSavingStreetJob}
    >
      <AddStreetJobForm
        initialValues={initialValuesFromRouteDetails || initialValues}
        isEditMode={isEditMode}
        isFetchingAddress={isFetchingAddress}
        isSourceRouteDetails={isSourceRouteDetails}
        loadAvailableRoutes={loadAvailableRoutes}
        onLocationPickerChanged={onLocationPickerChanged}
        onSubmit={handleSaveStreetJob}
        routeId={routeId}
        routesOptions={routesOptions}
        routeTemplateId={routeTemplateId}
        streetJobOptions={streetJobOptions || {}}
      />

      <ModalSection height="500px" width="100%" padding="small" position="relative">
        <AddJobLocationMapPicker
          handleLocationChange={updatePinAddress}
          isLocationPickerChanged={isLocationPickerChanged}
          isPinOnMapVisible={isPinOnMapVisible}
          location={currentPinnedAddress}
        />
      </ModalSection>
    </Modal>
  );
};

export default AddStreetJobModal;
