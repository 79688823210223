import React from 'react';

import { useDispatch } from 'react-redux';

import { currentVendorId } from '../../services/currentVendorSelector';
import { ModalProps } from '../../interfaces/ModalProps';
import { PageLoadingOverlay } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import StreetJobsModal from './StreetJobsModal';
import { loadStreetJobTypeOptions, loadStreetJobTypes } from 'src/vendors/ducks/StreetJobs';

const StreetJobsModalResolver: React.FC<ModalProps> = props => {
  const dispatch = useDispatch();
  const vendorId = useSelector(currentVendorId);

  const loadDependencies = React.useCallback(() => {
    if (!vendorId) {
      return Promise.reject();
    }

    const dependencies: Promise<unknown>[] = [
      loadStreetJobTypeOptions()(dispatch),
      loadStreetJobTypes(vendorId)(dispatch),
    ];

    return Promise.all(dependencies);
  }, [vendorId, dispatch]);

  return (
    <Resolver
      successComponent={StreetJobsModal}
      successProps={props}
      loadingComponent={PageLoadingOverlay}
      resolve={loadDependencies}
    />
  );
};

export default StreetJobsModalResolver;
