import { parseToRgb } from 'polished';

export interface IconColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export function convertHexToIconColor(hexColor: string): IconColor {
  const normalizedHex = normalizeHexColor(hexColor);

  try {
    const { red, green, blue } = parseToRgb(normalizedHex);
    return { r: red, g: green, b: blue, a: 1 }; // Default alpha to 1
  } catch (error) {
    console.error('Invalid hexColor provided:', hexColor);
    throw new Error('Invalid hexColor format.');
  }
}

export function normalizeHexColor(hexColor: string): string {
  let hex = hexColor.trim();

  if (!hex.startsWith('#')) {
    hex = `#${hex}`;
  }

  const hexBody = hex.slice(1);
  if (hexBody.length === 3) {
    hex = `#${hexBody
      .split('')
      .map(char => char + char)
      .join('')}`;
  } else if (hexBody.length !== 6) {
    throw new Error('Invalid hexColor format.');
  }

  return hex;
}

export function rgbaToHex({ r, g, b }: { r: number; g: number; b: number }): string {
  // Helper function to convert a single color value to a two-digit hex string
  const toHex = (value: number) => value.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
