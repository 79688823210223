import { darken } from 'polished';
import styled, { css, StyledProps } from 'styled-components';

import {
  COLOR_ALERT,
  COLOR_BLACK,
  COLOR_INFO,
  COLOR_SECONDARY,
  COLOR_WHITE,
  COLOR_ORANGE_DARK,
  rotate,
} from '../../styles';
import { colorMapperWithoutProps } from 'src/utils/styles/colorMapper';
import { Link } from './Link';
import { mapper, sizeMapper, colorMapper } from '../../../utils/styles';
import Icon from '../Icon';

const getButtonColor = (props: StyledButtonProps) =>
  props.color === COLOR_SECONDARY ? props.theme.grayDarker : '#fff';

const getButtonBackgroundColor = (props: StyledButtonProps) =>
  props.color === COLOR_SECONDARY ? '#fff' : colorMapper(props);

export const BareButtonStyle = css`
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  text-align: center;
  color: inherit;
  user-select: none;
`;

export type ButtonProps = Partial<{
  alignRight: boolean;
  borderRadius?: string;
  borderColor?: string;
  color: string;
  disabled: boolean;
  fluid: boolean;
  fullWidth: boolean;
  hoverColor: string;
  line: boolean;
  margin: string;
  marginTop: string;
  noCapitalize: boolean;
  noWrap: boolean;
  onClick: any;
  padding: string;
  rotate: boolean;
  size: string;
  squaredOnBottom: boolean;
  squaredOnTop: boolean;
  text: boolean;
  verticalAlignCenter: boolean;
  withAnimatedIcon: boolean;
  borderRight: boolean;
  underline: boolean;
}>;

type StyledButtonProps = StyledProps<ButtonProps>;

export const ButtonIcon = styled(Icon)<ButtonProps>`
  width: ${props => mapper(props.size, { small: '12px', medium: '14px', xLarge: '16px' })};
  height: ${props => mapper(props.size, { small: '12px', medium: '14px', xLarge: '16px' })};
  margin: ${props => sizeMapper(props.margin, 'no xSmall no no')};
  vertical-align: ${props => (props.verticalAlignCenter ? 'middle' : 'top')};

  ${props =>
    props.color &&
    css`
      color: ${colorMapper};
    `};

  ${props =>
    props.rotate &&
    css`
      animation: ${rotate} 2s linear infinite;
    `};
`;

export const Button = styled.button<ButtonProps>`
  ${BareButtonStyle};
  margin: ${props => sizeMapper(props.margin, 'no')};
  padding: ${props =>
    props.padding
      ? sizeMapper(props.padding)
      : mapper(props.size, {
          xSmall: '4px 10px',
          cSmall: '8px 8px',
          small: '8px 20px',
          xMedium: '8px 12px',
          medium: '8px 30px',
          xLarge: '8px 50px',
        })};
  background-color: ${colorMapper};
  border-radius: 5px;
  text-transform: ${props => (props.noCapitalize ? 'initial' : 'capitalize')};
  line-height: ${props => mapper(props.size, { xSmall: '12px', small: '15px', medium: '18px', lMedium: '25px' })};
  font-size: ${props => mapper(props.size, { xSmall: '11px', small: '12px', medium: '14px', xxLarge: '30px' })};
  color: ${getButtonColor};

  &:active {
    background-color: ${props => darken(0.05, colorMapper(props))};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:active {
      background-color: ${colorMapper};
    }
  }

  ${props =>
    props.withAnimatedIcon &&
    css`
      span {
        display: inline-block;
        margin-right: 5px;
      }
    `};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  ${props =>
    props.fluid &&
    css`
      flex: 1;
    `};

  ${props =>
    props.line &&
    css`
      background-color: transparent;
      border: 1px solid ${props.borderColor ? colorMapperWithoutProps(props.borderColor) : colorMapper};
      color: ${colorMapper};

      &:active {
        background-color: transparent;
        border-color: ${props => darken(0.05, colorMapper(props))};
        color: ${props => darken(0.05, colorMapper(props))};
      }
    `};

  ${props =>
    props.text &&
    css`
      padding: ${mapper(props.padding, { no: '0', small: '4px 0', medium: '8px 0', large: '12px 0' })};
      background-color: transparent;
      color: ${colorMapper};

      &:active {
        background-color: transparent;
        color: ${props => darken(0.05, colorMapper(props))};
      }
    `};

  ${props =>
    props.squaredOnBottom &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${props =>
    props.squaredOnTop &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `};

  ${props =>
    props.borderRight &&
    css`
      border-right: 1px solid ${props => props.theme.grayLight};
    `};

  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  ${props =>
    props.alignRight &&
    css`
      align-self: end;
    `}
`;

export const ButtonLink = Button.withComponent(({ borderColor, margin, size, color, fluid, line, text, ...props }) => (
  <Link decoration="none" {...props} />
));

export const IconButton = styled.button<ButtonProps>`
  ${BareButtonStyle};
  border-radius: 50%;
  margin: ${props =>
    mapper(props.margin, { no: 0, small: '5px', medium: '10px', large: '15px', xLarge: '20px' }, 'medium no no')};
  width: ${props => mapper(props.size, { xSmall: '25px', small: '30px', medium: '35px', large: '40px' }, 'medium')};
  height: ${props => mapper(props.size, { xSmall: '25px', small: '30px', medium: '35px', large: '40px' }, 'medium')};
  background-color: ${getButtonBackgroundColor};
  color: ${getButtonColor};

  &:not(:disabled):active {
    background-color: ${props => darken(0.05, colorMapper(props))};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${props =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius};
    `};
`;

export type IconButtonIconProps = {
  color?: string;
  margin?: string;
  marginTop?: string;
  size?: string;
  rotate?: number;
  scale?: number[];
};

export const IconButtonIcon = styled(Icon)<IconButtonIconProps>`
  width: ${props =>
    mapper(
      props.size,
      { xSmall: '8px', small: '10px', medium: '15px', large: '20px', mLarge: '25px', xLarge: '30px', xxLarge: '35px' },
      'medium',
    )};
  height: ${props =>
    mapper(
      props.size,
      { xSmall: '8px', small: '10px', medium: '15px', large: '20px', mLarge: '25px', xLarge: '30px', xxLarge: '35px' },
      'medium',
    )};
  vertical-align: middle;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};

  ${props =>
    props.color &&
    css`
      color: ${props.color === COLOR_ALERT
        ? props.theme.brandAlert
        : props.color === COLOR_INFO
        ? props.theme.brandInfoLight
        : props.color === COLOR_WHITE
        ? props.theme.brandWhite
        : props.color === COLOR_BLACK
        ? props.theme.black
        : props.color === COLOR_ORANGE_DARK
        ? props.theme.orangeDark
        : props.theme.brandPrimary};
    `};

  ${props =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `};

  ${props =>
    props.scale &&
    css`
      transform: scale(${props.scale[0]}, ${props.scale[1]});
    `};
`;

export const IconButtonWrapper = styled.span<{ margin?: string }>`
  margin: ${props => sizeMapper(props.margin, 'no xSmall')};
  display: inline-block;
  position: relative;
  top: 5px;

  & > div {
    height: 16px;
    line-height: 100%;

    > svg {
      vertical-align: top;
    }
  }
`;

export const InfoSection = styled.div`
  padding: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
  max-width: 300px;
  background-color: white;
  text-align: left;
`;

export const AddJobSaveButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: -5px;
  z-index: 2;
`;
