import { AnyAction, Dispatch } from 'redux';
import { orderBy } from 'lodash-es';
import update from 'immutability-helper';

import {
  loadStreetsImportUploadedFilesStatus as doLoadStreetsImportUploadedFilesStatus,
  uploadStreetsImportFile as doUploadStreetsImportFile,
  loadStreetJobs as doLoadStreetJobs,
  loadStreetJob as doLoadStreetJob,
  deleteStreetJob as doDeleteStreetJob,
  deleteStreetJobs as doDeleteStreetJobs,
  transferStreetJobs as doTransferStreetJobs,
  loadStreetJobTypes as doLoadStreetJobTypes,
  loadAvailableRoutesForStreetJobsReassignment as doLoadAvailableRoutesForStreetJobsReassignment,
  loadStreetJobOptions as doLoadStreetJobOptions,
  saveStreetJob as doSaveStreetJob,
} from '../services/streets';
import {
  RoutesForStreetJobsReassignment,
  StreetJobOptions,
  StreetJobRequest,
  StreetJobs,
  StreetJobsSearchFormData,
  StreetJobTypes,
  StreetsImportFile,
  StreetsReducerState,
} from '../interfaces/Streets';
import { SNOW_PLOW_ID } from 'src/fleet/constants';

// Actions
const START_LOAD = 'customers/streets/START_LOAD';
const COMPLETE_LOAD = 'customers/streets/COMPLETE_LOAD';
const FAIL_LOAD = 'customers/streets/FAIL_LOAD';
const UPDATE_UPLOAD_PERCENT = 'customers/streets/UPDATE_UPLOAD_PERCENT';
const START_UPLOAD = 'customers/streets/START_UPLOAD';
const COMPLETE_UPLOAD = 'customers/streets/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'customers/streets/FAIL_UPLOAD';
const START_LOAD_STREET_JOBS = 'customers/streets/START_LOAD_STREET_JOBS';
const COMPLETE_LOAD_STREET_JOBS = 'customers/streetsCOMPLETE_LOAD_STREET_JOBS';
const FAIL_LOAD_STREET_JOBS = 'customers/streets/FAIL_LOAD_STREET_JOBS';
const START_LOAD_STREET_JOB = 'customers/streets/START_LOAD_STREET_JOB';
const COMPLETE_LOAD_STREET_JOB = 'customers/streetsCOMPLETE_LOAD_STREET_JOB';
const FAIL_LOAD_STREET_JOB = 'customers/streets/FAIL_LOAD_STREET_JOB';
const START_DELETE_STREET_JOB = 'customers/streets/START_DELETE_STREET_JOB';
const COMPLETE_DELETE_STREET_JOB = 'customers/COMPLETE_DELETE_STREET_JOB';
const FAIL_DELETE_STREET_JOB = 'customers/streets/FAIL_DELETE_STREET_JOB';
const START_DELETE_STREET_JOBS = 'customers/streets/START_DELETE_STREET_JOBS';
const COMPLETE_DELETE_STREET_JOBS = 'customers/COMPLETE_DELETE_STREET_JOBS';
const FAIL_DELETE_STREET_JOBS = 'customers/streets/FAIL_DELETE_STREET_JOBS';
const START_TRANSFER_STREET_JOBS = 'customers/streets/START_TRANSFER_STREET_JOBS';
const COMPLETE_TRANSFER_STREET_JOBS = 'customers/COMPLETE_TRANSFER_STREET_JOBS';
const FAIL_TRANSFER_STREET_JOBS = 'customers/streets/FAIL_TRANSFER_STREET_JOBS';
const START_LOAD_STREET_JOB_TYPES = 'customers/streets/START_LOAD_STREET_JOB_TYPES';
const COMPLETE_LOAD_STREET_JOB_TYPES = 'customers/COMPLETE_LOAD_STREET_JOB_TYPES';
const FAIL_LOAD_STREET_JOB_TYPES = 'customers/streets/FAIL_LOAD_STREET_JOB_TYPES';
const START_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT =
  'customers/streets/START_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT';
const COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_SNOW_PLOW_STREET_JOBS_REASSIGNMENT =
  'customers/COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_SNOW_PLOW_STREET_JOBS_REASSIGNMENT';
const COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_STREET_SWEEPER_STREET_JOBS_REASSIGNMENT =
  'customers/COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_STREET_SWEEPER_STREET_JOBS_REASSIGNMENT';
const FAIL_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT =
  'customers/streets/FAIL_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT';
const SELECT_STREET_JOBS = 'customers/streets/SELECT_STREET_JOBS';
const START_LOAD_STREET_JOB_OPTIONS = 'customers/streets/START_LOAD_STREET_JOB_OPTIONS';
const COMPLETE_LOAD_STREET_JOB_OPTIONS = 'customers/streets/COMPLETE_LOAD_STREET_JOB_OPTIONS';
const FAIL_LOAD_STREET_JOB_OPTIONS = 'customers/streets/FAIL_LOAD_STREET_JOB_OPTIONS';
const START_SAVE_STREET_JOB = 'customers/streets/START_SAVE_STREET_JOB';
const COMPLETE_SAVE_STREET_JOB = 'customers/streets/COMPLETE_SAVE_STREET_JOB';
const FAIL_SAVE_STREET_JOB = 'customers/streets/FAIL_SAVE_STREET_JOB';

// Initial state
const initialState: StreetsReducerState = {
  isDeletingStreetJob: false,
  isDeletingStreetJobs: false,
  isLoading: false,
  isLoadingAvailableRoutesForStreetJobsReassignment: false,
  isLoadingStreetJob: false,
  isLoadingStreetJobs: false,
  isLoadingStreetJobTypes: false,
  isTransferingStreetJobs: false,
  isUploading: false,
  isLoadingStreetJobOptions: false,
  isSavingStreetJob: false,
  streetJobOptions: {} as StreetJobOptions,
  routesForSnowPlowStreetJobsReassignment: {} as RoutesForStreetJobsReassignment,
  routesForStreetSweeperStreetJobsReassignment: {} as RoutesForStreetJobsReassignment,
  selectedStreetJobs: [],
  streetJob: {} as StreetJobs,
  streetJobs: [],
  streetJobTypes: [],
  uploadedFiles: [],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        isLoading: { $set: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        isLoading: { $set: false },
        uploadedFiles: { $set: action.uploadedFiles },
      });

    case FAIL_LOAD:
      return update(state, {
        isLoading: { $set: false },
      });

    case START_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: true,
        },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
          uploadedFiles: action.uploadedFiles,
        },
      });

    case FAIL_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
          uploadedFiles: [],
        },
      });

    case START_LOAD_STREET_JOBS:
      return update(state, {
        $merge: {
          isLoadingStreetJobs: true,
        },
      });

    case COMPLETE_LOAD_STREET_JOBS:
      return update(state, {
        $merge: {
          isLoadingStreetJobs: false,
          streetJobs: action.streetJobs,
        },
      });

    case FAIL_LOAD_STREET_JOBS:
      return update(state, {
        $merge: {
          isLoadingStreetJobs: false,
          streetJobs: [],
        },
      });

    case START_LOAD_STREET_JOB:
      return update(state, {
        $merge: {
          isLoadingStreetJob: true,
        },
      });

    case COMPLETE_LOAD_STREET_JOB:
      return update(state, {
        $merge: {
          isLoadingStreetJob: false,
          streetJob: action.streetJob,
        },
      });

    case FAIL_LOAD_STREET_JOB:
      return update(state, {
        $merge: {
          isLoadingStreetJob: false,
          streetJob: undefined,
        },
      });

    case START_DELETE_STREET_JOB:
      return update(state, {
        $merge: {
          isDeletingStreetJob: true,
        },
      });

    case COMPLETE_DELETE_STREET_JOB:
      return update(state, {
        $merge: {
          isDeletingStreetJob: false,
        },
      });

    case FAIL_DELETE_STREET_JOB:
      return update(state, {
        $merge: {
          isDeletingStreetJob: false,
        },
      });

    case START_DELETE_STREET_JOBS:
      return update(state, {
        $merge: {
          isDeletingStreetJobs: true,
        },
      });

    case COMPLETE_DELETE_STREET_JOBS:
      return update(state, {
        $merge: {
          isDeletingStreetJobs: false,
        },
      });

    case FAIL_DELETE_STREET_JOBS:
      return update(state, {
        $merge: {
          isDeletingStreetJobs: false,
        },
      });

    case START_TRANSFER_STREET_JOBS:
      return update(state, {
        $merge: {
          isTransferingStreetJobs: true,
        },
      });

    case COMPLETE_TRANSFER_STREET_JOBS:
      return update(state, {
        $merge: {
          isTransferingStreetJobs: false,
        },
      });

    case FAIL_TRANSFER_STREET_JOBS:
      return update(state, {
        $merge: {
          isTransferingStreetJobs: false,
        },
      });

    case START_LOAD_STREET_JOB_TYPES:
      return update(state, {
        $merge: {
          isLoadingStreetJobTypes: true,
        },
      });

    case COMPLETE_LOAD_STREET_JOB_TYPES:
      return update(state, {
        $merge: {
          isLoadingStreetJobTypes: false,
          streetJobTypes: action.streetJobTypes,
        },
      });

    case FAIL_LOAD_STREET_JOB_TYPES:
      return update(state, {
        $merge: {
          isLoadingStreetJobTypes: false,
          streetJobTypes: [],
        },
      });

    case START_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT:
      return update(state, {
        $merge: {
          isLoadingAvailableRoutesForStreetJobsReassignment: true,
        },
      });

    case COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_SNOW_PLOW_STREET_JOBS_REASSIGNMENT:
      return update(state, {
        $merge: {
          isLoadingAvailableRoutesForStreetJobsReassignment: false,
          routesForSnowPlowStreetJobsReassignment: action.routesForStreetJobsReassignment,
        },
      });

    case COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_STREET_SWEEPER_STREET_JOBS_REASSIGNMENT:
      return update(state, {
        $merge: {
          isLoadingAvailableRoutesForStreetJobsReassignment: false,
          routesForStreetSweeperStreetJobsReassignment: action.routesForStreetJobsReassignment,
        },
      });

    case FAIL_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT:
      return update(state, {
        $merge: {
          isLoadingAvailableRoutesForStreetJobsReassignment: false,
          streetJobTypes: [],
        },
      });

    case SELECT_STREET_JOBS:
      return update(state, {
        $merge: {
          selectedStreetJobs: action.selectedStreetJobs,
        },
      });

    case START_LOAD_STREET_JOB_OPTIONS:
      return update(state, {
        $merge: {
          isLoadingStreetJobOptions: true,
        },
      });

    case COMPLETE_LOAD_STREET_JOB_OPTIONS:
      return update(state, {
        $merge: {
          isLoadingStreetJobOptions: false,
          streetJobOptions: action.streetJobOptions,
        },
      });

    case FAIL_LOAD_STREET_JOB_OPTIONS:
      return update(state, {
        $merge: {
          isLoadingStreetJobOptions: false,
          streetJobOptions: {},
        },
      });

    case START_SAVE_STREET_JOB:
      return update(state, {
        $merge: {
          isSavingStreetJob: true,
        },
      });

    case COMPLETE_SAVE_STREET_JOB:
      return update(state, {
        $merge: {
          isSavingStreetJob: false,
        },
      });

    case FAIL_SAVE_STREET_JOB:
      return update(state, {
        $merge: {
          isSavingStreetJob: false,
        },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (uploadedFiles: StreetsImportFile[]) => ({
  type: COMPLETE_LOAD,
  uploadedFiles,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startUpload = () => ({
  type: START_UPLOAD,
});

const completeUpload = (uploadedFiles: StreetsImportFile[]) => ({
  type: COMPLETE_UPLOAD,
  uploadedFiles,
});

const failUpload = () => ({
  type: FAIL_UPLOAD,
});

const startLoadStreetJobs = () => ({
  type: START_LOAD_STREET_JOBS,
});

const completeLoadStreetJobs = (streetJobs: StreetJobs[]) => ({
  type: COMPLETE_LOAD_STREET_JOBS,
  streetJobs,
});

const failLoadStreetJobs = () => ({
  type: FAIL_LOAD_STREET_JOBS,
});

const startLoadStreetJob = () => ({
  type: START_LOAD_STREET_JOB,
});

const completeLoadStreetJob = (streetJob: StreetJobs) => ({
  type: COMPLETE_LOAD_STREET_JOB,
  streetJob,
});

const failLoadStreetJob = () => ({
  type: FAIL_LOAD_STREET_JOB,
});

const startDeleteStreetJob = () => ({
  type: START_DELETE_STREET_JOB,
});

const completeDeleteStreetJob = () => ({
  type: COMPLETE_DELETE_STREET_JOB,
});

const failDeleteStreetJob = () => ({
  type: FAIL_DELETE_STREET_JOB,
});

const startDeleteStreetJobs = () => ({
  type: START_DELETE_STREET_JOBS,
});

const completeDeleteStreetJobs = () => ({
  type: COMPLETE_DELETE_STREET_JOBS,
});

const failDeleteStreetJobs = () => ({
  type: FAIL_DELETE_STREET_JOBS,
});

const startTransferStreetJobs = () => ({
  type: START_TRANSFER_STREET_JOBS,
});

const completeTransferStreetJobs = () => ({
  type: COMPLETE_TRANSFER_STREET_JOBS,
});

const failTransferStreetJobs = () => ({
  type: FAIL_TRANSFER_STREET_JOBS,
});

const startLoadStreetJobTypes = () => ({
  type: START_LOAD_STREET_JOB_TYPES,
});

const completeLoadStreetJobTypes = (streetJobTypes: StreetJobTypes[]) => ({
  type: COMPLETE_LOAD_STREET_JOB_TYPES,
  streetJobTypes,
});

const failLoadStreetJobTypes = () => ({
  type: FAIL_LOAD_STREET_JOB_TYPES,
});

const starLoadAvailableRoutesForStreetJobsReassignment = () => ({
  type: START_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT,
});

const completeLoadAvailableRoutesForSnowPlowStreetJobsReassignment = (
  routesForStreetJobsReassignment: RoutesForStreetJobsReassignment,
) => ({
  type: COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_SNOW_PLOW_STREET_JOBS_REASSIGNMENT,
  routesForStreetJobsReassignment,
});

const completeLoadAvailableRoutesForStreetSweeperStreetJobsReassignment = (
  routesForStreetJobsReassignment: RoutesForStreetJobsReassignment,
) => ({
  type: COMPLETE_LOAD_AVAILABLE_ROUTES_FOR_STREET_SWEEPER_STREET_JOBS_REASSIGNMENT,
  routesForStreetJobsReassignment,
});

const failLoadAvailableRoutesForStreetJobsReassignment = () => ({
  type: FAIL_LOAD_AVAILABLE_ROUTES_FOR_STREET_JOBS_REASSIGNMENT,
});

const startLoadStreetJobOptions = () => ({
  type: START_LOAD_STREET_JOB_OPTIONS,
});

const completeLoadStreetJobOptions = (streetJobOptions: any) => ({
  type: COMPLETE_LOAD_STREET_JOB_OPTIONS,
  streetJobOptions,
});

const failLoadStreetJobOptions = () => ({
  type: FAIL_LOAD_STREET_JOB_OPTIONS,
});

export const selectStreetJobs = (selectedStreetJobs: StreetJobs[]) => ({
  type: SELECT_STREET_JOBS,
  selectedStreetJobs,
});

export const clearSelectedStreetJobs = () => ({
  type: SELECT_STREET_JOBS,
  jobs: [],
});

const startSaveStreetJob = () => ({
  type: START_SAVE_STREET_JOB,
});

const completeSaveStreetJob = (savedStreetJob: any) => ({
  type: COMPLETE_SAVE_STREET_JOB,
  savedStreetJob,
});

const failSaveStreetJob = () => ({
  type: FAIL_SAVE_STREET_JOB,
});

export const loadStreetsImportUploadedFilesStatus = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadStreetsImportUploadedFilesStatusPromise = doLoadStreetsImportUploadedFilesStatus();

  loadStreetsImportUploadedFilesStatusPromise
    .then((uploadedFiles: StreetsImportFile[]) => {
      dispatch(completeLoad(orderBy(uploadedFiles, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadStreetsImportUploadedFilesStatusPromise;
};

export const uploadStreetsImportFile = (fileData: File, vehicleTypeId: number) => async (dispatch: Dispatch) => {
  dispatch(startUpload());

  const uploadStreetsImportFilePromise = doUploadStreetsImportFile(fileData, vehicleTypeId, (percent: number) => {
    dispatch({ type: UPDATE_UPLOAD_PERCENT, percent });
  });

  uploadStreetsImportFilePromise
    .then((uploadedFiles: StreetsImportFile[]) => {
      dispatch(completeUpload(orderBy(uploadedFiles, 'date', 'desc')));
    })
    .catch(() => {
      dispatch(failUpload());
    });
  return uploadStreetsImportFilePromise;
};

export const loadStreetJobs =
  (vendorId: number, streetJobsSearchFormData: StreetJobsSearchFormData) => async (dispatch: Dispatch) => {
    dispatch(startLoadStreetJobs());
    const loadStreetJobsPromise = doLoadStreetJobs(vendorId, streetJobsSearchFormData);

    loadStreetJobsPromise
      .then(streetJobs => dispatch(completeLoadStreetJobs(streetJobs.streetJobs)))
      .catch(() => dispatch(failLoadStreetJobs()));

    return loadStreetJobsPromise;
  };

export const loadStreetJob = (vendorId: number, id: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadStreetJob());
  const loadStreetJobPromise = doLoadStreetJob(vendorId, id);

  loadStreetJobPromise
    .then(streetJob => dispatch(completeLoadStreetJob(streetJob)))
    .catch(() => dispatch(failLoadStreetJob()));

  return loadStreetJobPromise;
};

export const deleteStreetJob = (vendorId: number, streetJobId: number) => async (dispatch: Dispatch) => {
  dispatch(startDeleteStreetJob());
  const deleteStreetJobPromise = doDeleteStreetJob(vendorId, streetJobId);

  deleteStreetJobPromise.then(() => dispatch(completeDeleteStreetJob())).catch(() => dispatch(failDeleteStreetJob()));

  return deleteStreetJobPromise;
};

export const deleteStreetJobs = (vendorId: number, streetJobIds: number[]) => async (dispatch: Dispatch) => {
  dispatch(startDeleteStreetJobs());
  const deleteStreetJobsPromise = doDeleteStreetJobs(vendorId, streetJobIds);

  deleteStreetJobsPromise
    .then(() => dispatch(completeDeleteStreetJobs()))
    .catch(() => dispatch(failDeleteStreetJobs()));

  return deleteStreetJobsPromise;
};

export const transferStreetJobs =
  (vendorId: number, streetJobIds: number[], routeCompositedId: string) => async (dispatch: Dispatch) => {
    dispatch(startTransferStreetJobs());
    const transferSreetJobsPromise = doTransferStreetJobs(vendorId, streetJobIds, routeCompositedId);

    transferSreetJobsPromise
      .then(() => dispatch(completeTransferStreetJobs()))
      .catch(() => dispatch(failTransferStreetJobs()));

    return transferSreetJobsPromise;
  };

export const loadStreetJobTypes = (vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadStreetJobTypes());
  const streetJobTypesPromise = doLoadStreetJobTypes(vendorId);

  streetJobTypesPromise
    .then(streetJobTypes => dispatch(completeLoadStreetJobTypes(streetJobTypes.streetJobTypes)))
    .catch(() => dispatch(failLoadStreetJobTypes()));

  return streetJobTypesPromise;
};

export const loadAvailableRoutesForStreetJobsReassignment =
  (
    vendorId: number,
    vehicleTypeId: number,
    startDate: Date | string,
    endDate: Date | string,
    intervalType?: number,
    routeId?: number,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(starLoadAvailableRoutesForStreetJobsReassignment());
    const availableRoutesForStreetJobsReassignmentPromise = doLoadAvailableRoutesForStreetJobsReassignment(
      vendorId,
      vehicleTypeId,
      startDate,
      endDate,
      intervalType,
      routeId,
    );

    availableRoutesForStreetJobsReassignmentPromise
      .then(routesForStreetJobsReassignment =>
        dispatch(
          vehicleTypeId === SNOW_PLOW_ID
            ? completeLoadAvailableRoutesForSnowPlowStreetJobsReassignment(routesForStreetJobsReassignment)
            : completeLoadAvailableRoutesForStreetSweeperStreetJobsReassignment(routesForStreetJobsReassignment),
        ),
      )
      .catch(() => dispatch(failLoadAvailableRoutesForStreetJobsReassignment()));

    return availableRoutesForStreetJobsReassignmentPromise;
  };

export const loadStreetJobOptions = (vendorId: number) => async (dispatch: Dispatch) => {
  dispatch(startLoadStreetJobOptions());
  const streetJobOptionsPromise = doLoadStreetJobOptions(vendorId);

  streetJobOptionsPromise
    .then(options => dispatch(completeLoadStreetJobOptions(options)))
    .catch(() => dispatch(failLoadStreetJobOptions()));

  return streetJobOptionsPromise;
};

export const saveStreetJob =
  (vendorId: number, streetJob: StreetJobRequest, isEditMode: boolean) => async (dispatch: Dispatch) => {
    dispatch(startSaveStreetJob());
    const saveStreetJobPromise = doSaveStreetJob(vendorId, streetJob, isEditMode);

    saveStreetJobPromise
      .then(savedStreetJob => dispatch(completeSaveStreetJob(savedStreetJob)))
      .catch(() => dispatch(failSaveStreetJob()));

    return saveStreetJobPromise;
  };
