import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';
import {
  loadStreetJobTypeOptions as doLoadStreetJobTypeOptions,
  loadStreetJobTypes as doLoadStreetJobTypes,
  saveStreetJobType as doSaveStreetJobType,
  updateStreetJobType as doUpdateStreetJobType,
} from '../services/streetJobType';
import { StreetJobTypeOptions, StreetJobTypeList, StreetJobType } from '../interfaces/StreetJobTypes';

// Actions
const START_LOAD_OPTIONS = 'vendors/streetJobType/START_LOAD_OPTIONS';
const COMPLETE_LOAD_OPTIONS = 'vendors/streetJobType/COMPLETE_LOAD_OPTIONS';
const FAIL_LOAD_OPTIONS = 'vendors/streetJobType/FAIL_LOAD_OPTIONS';
const START_LOAD_LIST = 'vendors/streetJobType/START_LOAD_LIST';
const COMPLETE_LOAD_LIST = 'vendors/streetJobType/COMPLETE_LOAD_LIST';
const FAIL_LOAD_LIST = 'vendors/streetJobType/FAIL_LOAD_LIST';
const START_SAVE = 'vendors/streetJobType/START_SAVE';
const COMPLETE_SAVE = 'vendors/streetJobType/COMPLETE_SAVE';
const FAIL_SAVE = 'vendors/streetJobType/FAIL_SAVE';
const START_UPDATE = 'vendors/streetJobType/START_UPDATE';
const COMPLETE_UPDATE = 'vendors/streetJobType/COMPLETE_UPDATE';
const FAIL_UPDATE = 'vendors/streetJobType/FAIL_UPDATE';
const RESET = 'vendors/streetJobType/RESET';

// Initial state
const initialState = {
  isLoadingOptions: false,
  isLoadingList: false,
  isSaving: false,
  isUpdating: false,
  streetJobTypeOptions: {} as StreetJobTypeOptions,
  streetJobTypeList: {} as StreetJobTypeList,
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD_OPTIONS:
      return update(state, { $merge: { isLoadingOptions: true } });

    case COMPLETE_LOAD_OPTIONS:
      return update(state, { $merge: { isLoadingOptions: false, streetJobTypeOptions: action.streetJobTypeOptions } });

    case FAIL_LOAD_OPTIONS:
      return update(state, { $merge: { isLoadingOptions: false } });

    case START_LOAD_LIST:
      return update(state, { $merge: { isLoadingList: true } });

    case COMPLETE_LOAD_LIST:
      return update(state, { $merge: { isLoadingList: false, streetJobTypeList: action.streetJobTypeList } });

    case FAIL_LOAD_LIST:
      return update(state, { $merge: { isLoadingList: false } });

    case START_SAVE:
      return update(state, { $merge: { isSaving: true } });

    case COMPLETE_SAVE:
      return update(state, { $merge: { isSaving: false } });

    case FAIL_SAVE:
      return update(state, { $merge: { isSaving: false } });

    case START_UPDATE:
      return update(state, { $merge: { isUpdating: true } });

    case COMPLETE_UPDATE:
      return update(state, { $merge: { isUpdating: false } });

    case FAIL_UPDATE:
      return update(state, { $merge: { isUpdating: false } });

    case RESET:
      return initialState;

    default:
      return state;
  }
};

// Action creators
const startLoadOptions = () => ({ type: START_LOAD_OPTIONS });
const completeLoadOptions = (streetJobTypeOptions: StreetJobTypeOptions) => ({
  type: COMPLETE_LOAD_OPTIONS,
  streetJobTypeOptions,
});
const failLoadOptions = () => ({ type: FAIL_LOAD_OPTIONS });

const startLoadList = () => ({ type: START_LOAD_LIST });
const completeLoadList = (streetJobTypeList: StreetJobTypeList[]) => ({
  type: COMPLETE_LOAD_LIST,
  streetJobTypeList,
});
const failLoadList = () => ({ type: FAIL_LOAD_LIST });

const startSave = () => ({ type: START_SAVE });
const completeSave = () => ({ type: COMPLETE_SAVE });
const failSave = () => ({ type: FAIL_SAVE });

const startUpdate = () => ({ type: START_UPDATE });
const completeUpdate = () => ({ type: COMPLETE_UPDATE });
const failUpdate = () => ({ type: FAIL_UPDATE });

export const loadStreetJobTypeOptions = () => (dispatch: Dispatch) => {
  dispatch(startLoadOptions());
  return doLoadStreetJobTypeOptions()
    .then((streetJobTypeOptions: StreetJobTypeOptions) => dispatch(completeLoadOptions(streetJobTypeOptions)))
    .catch(() => dispatch(failLoadOptions()));
};

export const loadStreetJobTypes = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoadList());
  return doLoadStreetJobTypes(vendorId)
    .then((streetJobTypeList: StreetJobTypeList[]) => dispatch(completeLoadList(streetJobTypeList)))
    .catch(() => dispatch(failLoadList()));
};

export const saveStreetJobType = (vendorId: number, streetJobType: StreetJobTypeList) => (dispatch: Dispatch) => {
  dispatch(startSave());
  return doSaveStreetJobType(vendorId, streetJobType)
    .then(() => dispatch(completeSave()))
    .catch(error => {
      dispatch(failSave());
      return Promise.reject(error);
    });
};

export const updateStreetJobType = (vendorId: number, streetJobType: StreetJobType) => (dispatch: Dispatch) => {
  dispatch(startUpdate());
  return doUpdateStreetJobType(vendorId, streetJobType)
    .then(() => dispatch(completeUpdate()))
    .catch(() => dispatch(failUpdate()));
};

export const resetStreetJobType = () => ({ type: RESET });
